
<div >
  <p class="line-height-3 p-0 m-0 carica-progetto-p">
   Seleziona l'azienda
  </p>
  <p-dropdown [options]="tenants" [(ngModel)]="tenantSelezionato" optionLabel="name"></p-dropdown>


<div  class="margin-top-1 border-top-1 surface-border pt-3">
  <div class="upload-file-div">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text" styleClass="p-button-success" [disabled]="!tenantSelezionato"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" styleClass="p-button-warning"></p-button>
  </div>
</div>
</div>


export const environment = {
  production: true,
  //apiUrl: 'https://fibergis-test-be-7httn75zqa-no.a.run.app/api/protected',
  //TEST
   apiUrl: 'https://fibergis-test-be-7httn75zqa-no.a.run.app/api/protected',
  //apiUrl: 'http://localhost:8081/api/protected',
  storageDir:'fibergis-test',
  auth0ApiUrl:'https://dev-report-tim.eu.auth0.com/api/v2/users',
  csvBox:{
    secret_progetti:'CTDZBcwrrxD677bE7zPriE0qF3cB8E',
    secret_civici:'YWjQrJ2YBd8UDD4zios6viyaR8xIpv',
  },
  auth0TokenApi: {
    url:'https://dev-report-tim.eu.auth0.com/oauth/token',
    clientId:'EZ4H1V16aOGOVuYFE5GRm5PKVsGwJjdQ',
    clientSecret:'mTXIeznbnXwxyP7YWTnDot2v4hEXO4I5EbC74BfF0JcXSspAcH2Ol5HTHpHp8qrs',
    audience:'https://dev-report-tim.eu.auth0.com/api/v2/',
    audienceBE:'http://tim-report-be-dev.vertical-srl.it',
  },
  firebase: {
    apiKey: "AIzaSyD-7e2rFoB_-CdBEUn1x2TNLuZ3B5kdkbI",
    authDomain: "tim-report-8c2a0.firebaseapp.com",
    projectId: "tim-report-8c2a0",
    //storageBucket: "tim-report-8c2a0.appspot.com",
    storageBucket: "fibergis-test",
    messagingSenderId: "753082534746",
    appId: "1:753082534746:web:2a2e88631df0ce57bec163",
    measurementId: "G-T9BNV2YLM6"
  },
  auth: {
    domain:'dev-report-tim.eu.auth0.com',
    clientId:'cn0y2ZsIPGVjT1WumIayEbpNdsNHmSAa',
    redirectUri: window.location.origin,
    errorPath:"/error",
  },
  ftthTutorialPlaylist:"https://youtube.com/playlist?list=PLxN43OMn9miO3Jc1ANUyB3rJcLpSS1ij7",
  boxTutorialURL:"https://www.youtube.com/watch?v=Ot85DAjAmH0",
  progettiTutorialURL:"https://www.youtube.com/watch?v=wUxJYsXPzro",
  civiciTutorialURL:"https://www.youtube.com/watch?v=e2wISVvZIr0",
  anagraficheMessageDuration:86400000,
  progettiMessageDuration:86400000
};

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { InputNumberModule } from 'primeng/inputnumber';
import { initializeApp } from 'firebase/app';
import  'firebase/auth';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StyleClassModule } from 'primeng/styleclass';
import { ConfirmationService, MessageService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';

import { DialogCaricaBoxTim, DialogCaricaProgetti, DialogProfilo, NavBarComponent, DialogFaq, DialogHelp, DialogDoc, DialogCaricaCivici, DialogAvanzaVersioneApplicazione, DialogCrediti, DialogCaricaCreditiTenant } from './components/navbar/navbar.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MyMissingTranslationHandler} from './translation/MyMissingTranslationHandler';
import { environment } from 'src/environments/environment';
import { StatsComponent } from './components/stats/stats.component';
import { DialogAssegnaProgetti, DialogAssegnaTenant, DialogModificaRuoloUtente, UtentiComponent } from './pages/utenti/utenti.component';
import {MatIconModule} from '@angular/material/icon';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import {ListboxModule} from 'primeng/listbox';
import { DialogAssegnaProgetto, DialogConsegnaProgetto, DialogSettaDataConsegna, DialogAnnullaConsegnaProgetto, DialogGeneraEtichette, DialogGeneraProgetto, DialogGeneraReport, DialogGeneraSinottico, ProgettiComponent, DialogConsolidaProgetto, DialogEsportaShapeProgetto } from './pages/progetti/progetti.component';
import {TenantComponent, DialogCreaTenant} from './pages/tenants/tenants.component'
import { ChartModule } from 'primeng/chart';
import {FileUploadModule} from 'primeng/fileupload';
import {TooltipModule} from 'primeng/tooltip';
import {OrderListModule} from 'primeng/orderlist';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {SpeedDialModule} from 'primeng/speeddial';
import { SplitButtonModule } from 'primeng/splitbutton';
import {MenubarModule} from 'primeng/menubar';
import { CalendarModule } from 'primeng/calendar';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import {AccordionModule} from 'primeng/accordion';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { DialogAnnullaConsegnaProgettoEsecutivo, DialogAssegnaProgettoEsecutivo, DialogConsegnaProgettoEsecutivo, DialogConsolidaProgettoEsecutivo, DialogEsportaShapeProgettoEsecutivo, DialogGeneraEtichetteEsecutivo, DialogGeneraReportEsecutivo, DialogSettaDataConsegnaEsecutivo, ProgettiEsecutiviComponent } from './pages/progetti-esecutivi/progetti-esecutivi.component';
import { CardModule, } from 'primeng/card';

import { CSVBoxAngularModule } from "@csvbox/angular";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavBarComponent,
    StatsComponent,
    UtentiComponent,
    DialogModificaRuoloUtente,
    DialogCaricaProgetti,
    DialogCaricaBoxTim,
    DialogCaricaCivici,
    DialogProfilo,
    DialogGeneraProgetto,
    DialogAssegnaProgetti,
    DialogAssegnaProgetto,
    DialogConsegnaProgetto,
    DialogConsolidaProgetto,
    DialogAnnullaConsegnaProgetto,
    DialogGeneraReport,
    DialogSettaDataConsegna,
    DialogAssegnaTenant,
    DialogCreaTenant,
    DialogGeneraEtichette,
    DialogGeneraSinottico,
    DialogFaq,
    DialogHelp,
    DialogDoc,
    TenantComponent,
    ProgettiComponent,
    ProgettiEsecutiviComponent,
    DialogSettaDataConsegnaEsecutivo,
    DialogGeneraReportEsecutivo,
    DialogGeneraEtichetteEsecutivo,
    DialogConsegnaProgettoEsecutivo,
    DialogAnnullaConsegnaProgettoEsecutivo,
    DialogAssegnaProgettoEsecutivo,
    DialogConsolidaProgettoEsecutivo,
    DialogEsportaShapeProgetto,
    DialogEsportaShapeProgettoEsecutivo,
    DialogAvanzaVersioneApplicazione,
    DialogCrediti, DialogCaricaCreditiTenant
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ButtonModule,
    HttpClientModule,
    RippleModule,
    FormsModule,
    RadioButtonModule,
    InputSwitchModule,
    InputNumberModule,
    SidebarModule,
    TableModule,
    StyleClassModule,
    CalendarModule,
    ToastModule,
    MatIconModule,
    DropdownModule,
    ConfirmDialogModule,
    FileUploadModule,
    MessagesModule,
    DialogModule,
    DynamicDialogModule,
    ListboxModule,
    ChartModule,
    OrderListModule,
    TooltipModule,
    SpeedDialModule,
    ProgressSpinnerModule,
    SplitButtonModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    MenubarModule,
    AccordionModule,
    InputTextModule,
    InputTextareaModule,
    LeafletModule,
    CardModule,
    CSVBoxAngularModule,
    AuthModule.forRoot({
      ...environment.auth,
      httpInterceptor: {
       allowedList: [`http://localhost:8080/api/private`],
      },
    }),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: false
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
    }),
  ],
  providers: [
    { 
      provide: LocationStrategy, useClass: HashLocationStrategy 
    },
    MessageService, DialogService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http);
}
<div class="container mt-5" *ngIf="auth.user$ | async as user">
  <div
    class="row align-items-center profile-header mb-5 text-center text-md-left"
  >
    <div class="col-md-2">
      <img
        [src]="user.picture"
        class="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
      />
    </div>
    <p-card  class="col-md">
      <h2>{{ user.name }}</h2>
      <p style="font-weight: bold;">{{ azienda }}</p>

      <p-card header="Ruolo" [style]="{'width': '20%', 'margin-left': 'auto','margin-right': 'auto' }">
        <p [class]="'user-badge ruolo-' +ruolo" >{{ ruolo }}</p>
    </p-card> 
    </p-card >
  </div>

</div>
<!--
<p class="lead text-muted">Ruolo: {{ ruolo }}</p>
-->

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import {NGXLogger} from 'ngx-logger';
import { TokenAuth0 } from '../models/tokenAuth0.model';
import { ThisReceiver } from '@angular/compiler';
import { Progetto } from '../models/progetto.model';
import { W } from 'chart.js/dist/chunks/helpers.core';
import { Utente } from '../models/utente.model';
import { UtenteDTO } from '../models/utente.dto.model';
import { ProgettoDTO } from '../models/progetto.dto.model';
@Injectable({
  providedIn: 'root',
})
export class ReportService{
  apiUrl: string = `${environment.apiUrl}/reports`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}

  modelloFO(codiceProgetto:string = null): Observable<any> {
    this.apiUrl =environment.apiUrl+"/reports/modelloFo?progetto="+codiceProgetto;
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
   //   'Authorization': `Bearer ${token}`,
    };

    return this.http.get(this.apiUrl, {
      headers: headers,
      responseType: 'arraybuffer',
      observe: 'response'
    });
  }
  modelloFOEsecutivo(codiceProgetto:string = null): Observable<any> {
    this.apiUrl =environment.apiUrl+"/reports/modelloFoEsecutivo?progetto="+codiceProgetto;
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
   //   'Authorization': `Bearer ${token}`,
    };

    return this.http.get(this.apiUrl, {
      headers: headers,
      responseType: 'arraybuffer',
      observe: 'response'
    });
  }
  

  modelloRame(codiceProgetto:string = null): Observable<any> {
    this.apiUrl =environment.apiUrl+"/reports/modelloRame?progetto="+codiceProgetto;
    //const token = localStorage.getItem("BEtoken");
    const headers = {
   //   'Authorization': `Bearer ${token}`,
    };
    return this.http.get(this.apiUrl, {
      headers: headers,
      responseType: 'arraybuffer',
      observe: 'response'
    });
  }

  modelloRameEsecutivo(codiceProgetto:string = null): Observable<any> {
    this.apiUrl =environment.apiUrl+"/reports/modelloRameEsecutivo?progetto="+codiceProgetto;
    //const token = localStorage.getItem("BEtoken");
    const headers = {
   //   'Authorization': `Bearer ${token}`,
    };
    return this.http.get(this.apiUrl, {
      headers: headers,
      responseType: 'arraybuffer',
      observe: 'response'
    });
  }

  associazioneBoxQuartine(codiceProgetto:string = null): Observable<any> {
    this.apiUrl =environment.apiUrl+"/reports/associazioneBoxQuartine?progetto="+codiceProgetto;
   // const token = localStorage.getItem("BEtoken");
    const headers = {
   //   'Authorization': `Bearer ${token}`,
    };
    return this.http.get(this.apiUrl, {
      headers: headers,
      responseType: 'arraybuffer',
      observe: 'response'
    });
  }

  associazioneBoxQuartineEsecutivo(codiceProgetto:string = null): Observable<any> {
    this.apiUrl =environment.apiUrl+"/reports/associazioneBoxQuartineEsecutivo?progetto="+codiceProgetto;
   // const token = localStorage.getItem("BEtoken");
    const headers = {
   //   'Authorization': `Bearer ${token}`,
    };
    return this.http.get(this.apiUrl, {
      headers: headers,
      responseType: 'arraybuffer',
      observe: 'response'
    });
  }

  preventivo(codiceProgetto:string = null): Observable<any> {
    this.apiUrl =environment.apiUrl+"/reports/preventivo?progetto="+codiceProgetto;
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    return this.http.get(this.apiUrl, {
      headers: headers,
      responseType: 'arraybuffer',
      observe: 'response'
    });
  }

  preventivoEsecutivo(codiceProgetto:string = null): Observable<any> {
    this.apiUrl =environment.apiUrl+"/reports/preventivoEsecutivo?progetto="+codiceProgetto;
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    return this.http.get(this.apiUrl, {
      headers: headers,
      responseType: 'arraybuffer',
      observe: 'response'
    });
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError = <T>(operation = 'operation', result?: T) => {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      this.logger.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.info(`${operation} failed: ${error.message}`);

      /*this.errorHandler.dialogConfig = {...this.dialogConfig};
      this.errorHandler.handleError(error);*/



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  };
}

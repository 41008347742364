

    <iframe id="iframeCenter"
    src="https://supporto.maphosting.it/supporto-fibergis"
    title="Supporto"
    width="100%"
    height="98%" >

    </iframe>
  
    
   <!--  
    <div class="surface-section">
    <div class="text-900 font-medium mb-4">Per richiedere supporto compila il seguente form</div>
    <div class="p-fluid formgrid grid px-4 py-2 md:px-6 lg:px-8" >
        
 
        
            <div class="field col-12 lg:col-6  mb-4">
                <input pInputText id="firstname" [(ngModel)]="nome" type="text" class="py-3 px-2 text-lg"  placeholder="Nome">
            </div>
            <div class="field col-12 lg:col-6  mb-4">
                <input pInputText id="lastname" [(ngModel)]="cognome" type="text" class="py-3 px-2 text-lg"  placeholder="Cognome">
            </div>
            <div class="field col-12 mb-4">
                <input id="email" type="text" [(ngModel)]="email" pInputText class="py-3 px-2 text-lg" placeholder="Email">
            </div>
            <div class="field col-12 mb-4">
                <input id="telefono" type="text" [(ngModel)]="telefono"  pInputText class="py-3 px-2 text-lg" placeholder="Numero di Telefono">
            </div>
            <div class="field col-12 mb-4">
                <textarea id="message" [(ngModel)]="messaggio"  pInputTextarea rows="3" [autoResize]="true" class="py-3 px-2 text-lg"
                    placeholder="Messaggio"></textarea>
            </div>
            <div class="col-12 text-center">
                <button type="button" pButton pRipple label="Invia" icon="pi pi-envelope"
                    class="px-5 py-3 w-auto" [disabled]="email.length==0 || messaggio.length==0" (click)="inviaMailSupporto()"  ></button>
            </div>
            
        </div> 
        
</div>
-->
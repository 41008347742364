
<div >
  <div class="progress-spinner" *ngIf="isLoadingResults">
    <p-progressSpinner></p-progressSpinner>
</div>
  <p class="line-height-3 p-0 m-0 carica-progetto-p">
   Seleziona file da caricare o trascinalo nell'area sottostante
  </p>
  <p-fileUpload name="progetti"
  multiple="false"
  accept=".xlsx" maxFileSize="5000000" 
  chooseLabel="Cerca" uploadLabel="Carica" cancelLabel="Cancella"
  [auto]="true"
  [customUpload]="true"
  (uploadHandler)="onFileUpload($event)"
  [showUploadButton]="false"
  [showCancelButton]="false"
  [disabled]="isFileUploaded"
  (onRemove)="onFileRemove()"
  >
</p-fileUpload>

<!-- <ng-template let-file pTemplate="file">
   
     <div class="ui-fileupload-row">
            <div>{{file.name}}</div>
            <div>{{getSizeInMegaBytes(file) | number:'1.2-2'}} MB</div>
            <div>
              <button type="button" icon="pi pi-times" pButton (click)="remove($event, i)" [disabled]="uploading" [class]="removeStyleClass"></button>
            </div>
        </div> 
 
</ng-template> -->
<div class="surface-card shadow-2 border-round select-none">
  <ul class="list-none p-1 m-1">
      <li>
          <a pRipple class="flex align-items-center cursor-pointer p-3 hover:surface-100 border-round transition-colors transition-duration-150"
          (click)="openVideoUrl()">
              <i class="pi pi-video mr-2 text-700"></i>
              <span class="font-medium text-700">Guarda il video per imparare ad utilizzare questa procedura</span>
          </a>
      </li>
  </ul>
</div>
<div  class="flex upload-file-div-box">
  
    <button pButton pRipple icon="pi pi-check" (click)="conferma(true)"  label="Conferma" class="p-button-success w-6 mr-2" [disabled]="!isFileUploaded"></button>
    <button pButton pRipple icon="pi pi-times" (click)="conferma(false)" label="Annulla"  class="p-button-warning w-6 ml-2" ></button>
  </div>
 <!--  <div class="upload-file-div"></div> -->
</div>


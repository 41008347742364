import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { map } from 'rxjs';
import { StatsDTO } from 'src/app/models/stats.dto.model';
import { StatsHistoryDTO } from 'src/app/models/stats.history.dto.model';
import { TokenAuth0 } from 'src/app/models/tokenAuth0.model';
import { ProgettiEsecutiviService } from 'src/app/services/progetti-esecutivi.service';
import { ProgettiService } from 'src/app/services/progetti.service';
import { UtentiService } from 'src/app/services/utenti.service';

declare var google: any;

@Component({
  selector: 'stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css'],
})
export class StatsComponent implements OnInit {
  progetti: any;
  progettiNelTempo: any;
  progettiEsecutiviNelTempo:any;
  isLoadingResults = false;
  chartOptions: any;
  basicOptions: any;
  statsDTO: StatsDTO;
  numeroTotaleProgetti: number;
  numeroProgettiDaAssegnare: number;
  numeroProgettiAssegnati: number;
  numeroProgettiInCorso: number;
  numeroProgettiConsegnati: number;
  numeroProgettiInRework: number;

  numeroTotaleProgettiEsecutivi: number;
  numeroProgettiEsecutiviDaAssegnare: number;
  numeroProgettiEsecutiviAssegnati: number;
  numeroProgettiEsecutiviInCorso: number;
  numeroProgettiEsecutiviConsegnati: number;
  numeroProgettiEsecutiviInRework: number;


  monthLabels: string[] = [];
  monthValue: string[] = [];
  monthExecutivesValue: string[] = [];
  
  isAdmin: boolean = false;
  isCoordinatore: boolean = false;
  infoWindow: any;
  options: any;

  constructor(
    private config: PrimeNGConfig,
    public progettiService: ProgettiService,
    public progettiEsecutiviService: ProgettiEsecutiviService,

    private logger: NGXLogger,
    private utentiService: UtentiService,
    private router: Router,
  ) {}

  ngOnInit() {
    //this.logger.info('>> ngOnInit STATS');
    
    this.isAdmin = localStorage.getItem('ruolo') === 'AMMINISTRATORE';
    this.isCoordinatore = localStorage.getItem('ruolo') === 'COORDINATORE';
    this.loadStats();

  }

  openProject(destinazione:string){
    this.router.navigate(
      ['/progetti/'+destinazione ]
  ); 
  }

  openExecutiveProjects(destinazione:string){
    this.router.navigate(
      ['/progetti-esecutivi/'+destinazione ]
  ); 
  }

  loadStats() {
    this.isLoadingResults = true;
   // console.log('>> LOADING STATS!');
    if (localStorage.getItem('tenantId') === 'null') {
      this.utentiService.token().subscribe((res: TokenAuth0) => {
        const auth0Token = res;
        this.utentiService
          .utente(auth0Token, localStorage.getItem('user.sub'))
          .subscribe((utente) => {
            if (
              utente.user_metadata != 'undefined' ||
              utente.user_metadata != 'null'
            ) {
              localStorage.setItem('tenantId', utente.user_metadata.tenant_id);
              localStorage.setItem(
                'tenantName',
                utente.user_metadata.tenant_name
              );
              this.progettiService.stats().subscribe((res: any) => {
               
                this.numeroTotaleProgetti     = res.numeroTotaleProgetti;
                this.numeroProgettiAssegnati  = res.numeroProgettiAssegnati;
                this.numeroProgettiInCorso    = res.numeroProgettiInCorso;
                this.numeroProgettiConsegnati = res.numeroProgettiConsegnati;
                this.numeroProgettiInRework   = res.numeroTotaleProgettiReworked;
                this.numeroProgettiDaAssegnare = res.numeroTotaleProgettiNonAssegnati;
                let prevProjectNumber:number = 0;

                res.storicoProgetti.forEach((data: StatsHistoryDTO) => {
                  prevProjectNumber =
                    prevProjectNumber + data.numeroTotaleProgetti;
                  this.monthLabels.push(
                    this.getMonthName(
                      data.mese.substring(0, data.mese.indexOf('/'))
                    )
                  );
                  this.monthValue.push(prevProjectNumber.toFixed(0));
                });
                this.progetti = {
                  labels: ['Da Generare', 'In corso', 'Consegnati'],
                  datasets: [
                    {
                      data: [
                        res.numeroProgettiAssegnati,
                        res.numeroProgettiInCorso,
                        res.numeroProgettiConsegnati
                      ],
                      backgroundColor: ['#eab308', '#f97316', '#22c55e'],
                      hoverBackgroundColor: ['#eab308', '#f97316', '#22c55e'],
                    },
                  ],
                };
                
                this.isLoadingResults = false;
              });

              this.progettiEsecutiviService.stats().subscribe((res: any) => {
                
                this.numeroTotaleProgettiEsecutivi     = res.numeroTotaleProgetti;
                this.numeroProgettiEsecutiviAssegnati  = res.numeroProgettiAssegnati;
                this.numeroProgettiEsecutiviInCorso    = res.numeroProgettiInCorso;
                this.numeroProgettiEsecutiviConsegnati = res.numeroProgettiConsegnati;
                this.numeroProgettiEsecutiviInRework   = res.numeroTotaleProgettiReworked;
                this.numeroProgettiEsecutiviDaAssegnare = res.numeroTotaleProgetti - res.numeroProgettiAssegnati - res.numeroProgettiInCorso -  res.numeroProgettiConsegnati - res.numeroTotaleProgettiReworked;
                let prevProjectNumber:number = 0;

                res.storicoProgetti.forEach((data: StatsHistoryDTO) => {
                  prevProjectNumber = prevProjectNumber + data.numeroTotaleProgetti;
                  /* this.monthLabels.push(
                    this.getMonthName(
                      data.mese.substring(0, data.mese.indexOf('/'))
                    )
                  ); */
                  this.monthExecutivesValue.push(prevProjectNumber.toFixed(0));
                });
   /*              this.progetti = {
                  labels: ['Da Generare', 'In corso', 'Consegnati'],
                  datasets: [
                    {
                      data: [
                        res.numeroProgettiAssegnati,
                        res.numeroProgettiInCorso,
                        res.numeroProgettiConsegnati
                      ],
                      backgroundColor: ['#eab308', '#f97316', '#22c55e'],
                      hoverBackgroundColor: ['#eab308', '#f97316', '#22c55e'],
                    },
                  ],
                }; */
                /* this.progettiEsecutiviNelTempo = {
                  labels: this.monthLabels,
                  datasets: [
                    {
                      label: 'Totale Progetti Esecutivi',
                      data: this.monthValue,
                      fill: false,
                      borderColor: '#22c55e',
                      tension: 0.4,
                    },
                  ],
                }; */
                this.progettiNelTempo = {
                  labels: this.monthLabels,
                  datasets: [
                    {
                      label: 'Totale Progetti Preliminari',
                      data: this.monthValue,
                      fill: false,
                      borderColor: '#163d77',
                      tension: 0.4,
                    },
                    {
                      label: 'Totale Progetti Esecutivi',
                      data: this.monthExecutivesValue,
                      fill: false,
                      borderColor: '#04a642',
                      tension: 0.4,
                    },
                  ],
                };
                this.isLoadingResults = false;
              });

            }
          });
          
      });
    } else {
      this.progettiService.stats().subscribe((res: any) => {
        
        this.numeroTotaleProgetti     = res.numeroTotaleProgetti;
        this.numeroProgettiAssegnati  = res.numeroProgettiAssegnati;
        this.numeroProgettiInCorso    = res.numeroProgettiInCorso;
        this.numeroProgettiConsegnati = res.numeroProgettiConsegnati;
        this.numeroProgettiInRework   = res.numeroTotaleProgettiReworked;
        this.numeroProgettiDaAssegnare = res.numeroTotaleProgettiNonAssegnati;
        // - res.numeroProgettiAssegnati - res.numeroProgettiInCorso -  res.numeroProgettiConsegnati - res.numeroTotaleProgettiReworked;

        let prevProjectNumber:number = 0;
        res.storicoProgetti.forEach((data: StatsHistoryDTO) => {
          prevProjectNumber = prevProjectNumber + data.numeroTotaleProgetti;
          this.monthLabels.push(
            this.getMonthName(data.mese.substring(0, data.mese.indexOf('/')))
          );
          this.monthValue.push(prevProjectNumber.toFixed(0));
        });
        /* this.progetti = {
          labels: ['Da Generare', 'In corso', 'Consegnati'],
          datasets: [
            {
              data: [res.numeroProgettiAssegnati, res.numeroProgettiInCorso, res.numeroProgettiConsegnati],
              backgroundColor: ['#eab308', '#f97316', '#22c55e'],
              hoverBackgroundColor: ['#eab308', '#f97316', '#22c55e'],
            },
          ],
        };
        this.progettiNelTempo = {
          labels: this.monthLabels,
          datasets: [
            {
              label: 'Totale Progetti Preliminari',
              data: this.monthValue,
              fill: false,
              borderColor: '#22c55e',
              tension: 0.4,
            },
          ],
        }; */
        this.isLoadingResults = false;
      });

      /*
        qui
      */
        this.progettiEsecutiviService.stats().subscribe((res: any) => {


          this.numeroTotaleProgettiEsecutivi     = res.numeroTotaleProgetti;
          this.numeroProgettiEsecutiviAssegnati  = res.numeroProgettiAssegnati;
          this.numeroProgettiEsecutiviInCorso    = res.numeroProgettiInCorso;
          this.numeroProgettiEsecutiviConsegnati = res.numeroProgettiConsegnati;
          this.numeroProgettiEsecutiviInRework   = res.numeroTotaleProgettiReworked;
          this.numeroProgettiEsecutiviDaAssegnare = res.numeroTotaleProgetti - res.numeroProgettiAssegnati - res.numeroProgettiInCorso -  res.numeroProgettiConsegnati - res.numeroTotaleProgettiReworked;
          let prevProjectNumber:number = 0;

          res.storicoProgetti.forEach((data: StatsHistoryDTO) => {
            prevProjectNumber = prevProjectNumber + data.numeroTotaleProgetti;
           /*  this.monthLabels.push(
              this.getMonthName(data.mese.substring(0, data.mese.indexOf('/')))
            ); */
            this.monthValue.push(prevProjectNumber.toFixed(0));
          });
          /* this.progetti = {
            labels: ['Da Generare', 'In corso', 'Consegnati'],
            datasets: [
              {
                data: [res.numeroProgettiAssegnati, res.numeroProgettiInCorso, res.numeroProgettiConsegnati],
                backgroundColor: ['#eab308', '#f97316', '#22c55e'],
                hoverBackgroundColor: ['#eab308', '#f97316', '#22c55e'],
              },
            ],
          }; */
          this.progettiNelTempo = {
            labels: this.monthLabels,
            datasets: [
              {
                label: 'Totale Progetti Preliminari',
                data: this.monthValue,
                fill: false,
                borderColor: '#163d77',
                tension: 0.4,
              },
              {
                label: 'Totale Progetti Esecutivi',
                data: this.monthExecutivesValue,
                fill: false,
                borderColor: '#04a642',
                tension: 0.4,
              },
            ],
          };
        
          this.isLoadingResults = false;
        });
      
    }
  }

  getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('it-IT', { month: 'long' });
  }
}

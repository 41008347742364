import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import {NGXLogger} from 'ngx-logger';
import { TokenAuth0 } from '../models/tokenAuth0.model';
import { ThisReceiver } from '@angular/compiler';
import { Progetto } from '../models/progetto.model';
import { W } from 'chart.js/dist/chunks/helpers.core';
import { Utente } from '../models/utente.model';
import { UtenteDTO } from '../models/utente.dto.model';
import { ProgettoDTO } from '../models/progetto.dto.model';
import { StatsDTO } from '../models/stats.dto.model';
import { SettimanaConsegnaDTO } from '../models/progetto.settimanaConsegna.dto.models';
import { ProgettoReworked } from '../models/progettoReworked.models';
@Injectable({
  providedIn: 'root',
})
export class ProgettiService{
  apiUrl: string = `${environment.apiUrl}/progetti`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}

  progetti(filter:string): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti`;
    this.apiUrl +="?tenantId="+localStorage.getItem("tenantId");
    if(localStorage.getItem('ruolo')!='AMMINISTRATORE' && localStorage.getItem('ruolo')!='COORDINATORE'){
      this.apiUrl +="&userId="+localStorage.getItem('userId');
    }

    if(filter!=null){
      this.apiUrl +="&filter="+filter;
    }
   
    //const tokenX = localStorage.getItem("BEtoken");
    const headers = {
     // 'Authorization': `Bearer ${tokenX}`,
    };
    return this.http.get(this.apiUrl)/* .pipe(
      tap((res: any) => {
        this.logger.info("> CALLING GET PROGETTI CON FILTRO -> DATI ARRIVATI");
        return res;
      }),
      catchError(this.handleError<any[]>('getProgetti', []))
    ) */;
  }

  stats(): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti/stats`;
   // this.apiUrl +="?tenantId="+localStorage.getItem("tenantId");
   // console.log("---> progetti "+ localStorage.getItem('ruolo'));
    if(localStorage.getItem('ruolo')!='AMMINISTRATORE' && localStorage.getItem('ruolo')!='COORDINATORE'){
      this.apiUrl +="?userId="+localStorage.getItem('userId');
    }else {
      this.apiUrl +="?tenantId="+localStorage.getItem("tenantId");
    }

    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    return this.http.get(this.apiUrl).pipe(
      tap((res: StatsDTO) => {
        return res;
      }),
      catchError(this.handleError<any[]>('getStats', []))
    );
  }
  getReworks(projectId:string = null): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti/`+projectId+'/reworks';
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('getReworks', []))
    );
  }

  checkSinottico(projectId:string = null): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti/`+projectId+'/sinottico';
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('checkSinoticco', []))
    );
  }

  checkShape(projectId:string = null): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti/`+projectId+'/shape';
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('checkShape', []))
    );
  }

  consegnaProgetto(userId:string = null, tenantId:string, projectId:number, file:File = null): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti`;
    this.apiUrl +="/"+projectId+"/consegna";
    const progettoDTO:ProgettoDTO = new ProgettoDTO();
    progettoDTO.tenant = tenantId.toLowerCase();
    progettoDTO.user=userId;
    
    let formData = new FormData();
    if(file != null){
      formData.append('progetto', file);
    }else {
      this.apiUrl+="SenzaFile";
    }
    
    formData.append('tenant',tenantId.toLowerCase());
    //formData.append('user',userId);
    formData.append('user',localStorage.getItem('utente'));
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, formData, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('generaProgetto', []))
    );
  }

  annullaConsegnaProgetto(userId:string = null, tenantId:string, projectId:number): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti`;
    this.apiUrl +="/"+projectId+"/annullaConsegna";
    const progettoDTO:ProgettoDTO = new ProgettoDTO();
    progettoDTO.tenant = tenantId.toLowerCase();
    progettoDTO.user=userId;
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, progettoDTO, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('generaProgetto', []))
    );
  }

  generaEsecutivoProgetto(projectId:number): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti`;
    this.apiUrl +="/"+projectId+"/esecutivo";
    const utenteDTO:UtenteDTO = new UtenteDTO();
    utenteDTO.id    = localStorage.getItem('userId');
    utenteDTO.name  = localStorage.getItem('utente');

    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, utenteDTO, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('generaEsecutivoProgetto', []))
    );
  }

  reworkProgetto(userId:string = null, userName:string, projectId:number, note:string): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti`;
    this.apiUrl +="/"+projectId+"/rework";
    const progettoReworkedDTO:ProgettoReworked = new ProgettoReworked();
          progettoReworkedDTO.idProgettista   = userId;
          progettoReworkedDTO.nomeProgettista = userName;
          progettoReworkedDTO.note = note;
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, progettoReworkedDTO, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('reworkProgetto', []))
    );
  }

  resettaProgetto(projectId:number): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti`;
    this.apiUrl +="/"+projectId+"/reset";
    const progettoDTO:ProgettoDTO = new ProgettoDTO();
    progettoDTO.tenant = localStorage.getItem("tenantId");
    progettoDTO.user=localStorage.getItem('userId');
    
   // const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, progettoDTO, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('resettaProgetto', []))
    );
  }

  generaShape(projectId:number, code:string): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti`;
    if(code==="tim"){
      this.apiUrl +="/"+projectId+"/generaShapeTIM";
    }else if(code==="permessi"){
      this.apiUrl +="/"+projectId+"/generaShapePermessi";
    }
    
    const progettoDTO:ProgettoDTO = new ProgettoDTO();
    progettoDTO.tenant = localStorage.getItem("tenantId");
    progettoDTO.user=localStorage.getItem('userId');
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, progettoDTO, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('generaShape', []))
    );
  }

  generaBackup(projectId:number): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti`;
    this.apiUrl +="/"+projectId+"/backup";
    const progettoDTO:ProgettoDTO = new ProgettoDTO();
    progettoDTO.tenant = localStorage.getItem("tenantId");
    progettoDTO.user=localStorage.getItem('userId');
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, progettoDTO, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('generaBackup', []))
    );
  }

  aggiornaDataConsegna(settimana:number, idProgetto:number): Observable<any> {
    this.logger.info(">>> STO PER AGGIORNARE LA DATA DI SCADENZA DI UN PROGETTO");
    this.apiUrl =`${environment.apiUrl}/progetti/`+idProgetto;
    const settimanaConsegnaDTO:SettimanaConsegnaDTO = new SettimanaConsegnaDTO();
    settimanaConsegnaDTO.utenteModifica = localStorage.getItem("userId");
    settimanaConsegnaDTO.settimanaConsegna = settimana;

   // const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(this.apiUrl, settimanaConsegnaDTO, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('aggiornaDataConsegnaProgetto', []))
    );
  }

  eliminaProgetto(projectId:string): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti/`+projectId
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(this.apiUrl, httpOptions)/* .pipe(
      tap((res: any) => {
        this.logger.info('cancellazione Progetto avvenuta con successo');
        return res;
      }),
      catchError(this.handleError<any>('eliminaProgetto', ['ERRORE ELIMINAZIONE PROGETTO']))
    ) */;
  }

  consolidaProgetto(projectId:string, process:string=null): Observable<any> {
    this.apiUrl                   = `${environment.apiUrl}/progetti/`+projectId+'/consolida';
    if(process!=null){
      this.apiUrl +='?process='+process;
    }
    
    const progettoDTO:ProgettoDTO = new ProgettoDTO();
    progettoDTO.tenant            = localStorage.getItem("tenantId");
    progettoDTO.user              = localStorage.getItem('userId');
   
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, progettoDTO, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('consolidaProgetto', []))
    );
  }

  copiaCiviciProgetto(projectId:string): Observable<any> {
    this.apiUrl                   = `${environment.apiUrl}/progetti/`+projectId+'/consolida-civici';
    const progettoDTO:ProgettoDTO = new ProgettoDTO();
    progettoDTO.tenant            = localStorage.getItem("tenantId");
    progettoDTO.user              = localStorage.getItem('userId');
   
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, progettoDTO, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('ccopiaCiviciProgetto', []))
    );
  }

  generaProgetto(userId:string = null, tenantId:string, projectCode:string): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti`;
    this.apiUrl +="/"+projectCode;
    const progettoDTO:ProgettoDTO = new ProgettoDTO();
    progettoDTO.tenant = tenantId.toLowerCase();
    progettoDTO.user=userId;
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, progettoDTO, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('generaProgetto', []))
    );
  }

  caricaProgetti(file:File): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti/uploadProgetti`;
   
    let formData = new FormData();
    formData.append('progetti', file);
    formData.append('tenantId',localStorage.getItem("tenantId"));
   //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, formData, httpOptions).pipe(
      tap((res: any) => {
        //this.logger.info('caricaProgetti');
        
        return res;
      }),
      catchError(this.handleError<any>('caricaProgetti', []))
    );
  }

  caricaAnagraficaBox(file:File): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti/uploadAnagraficaBox`;
    this.logger.info(">>> STO PER CARICARE L'ANAGRAFICA TIM > FILE "+ file.name + " @ "+ this.apiUrl);
    
    let formData = new FormData();
    formData.append('anagraficaBox', file);
    formData.append('tenantName',localStorage.getItem("tenantName"));
    formData.append('tenantId',localStorage.getItem("tenantId"));
    //const token = localStorage.getItem("BEtoken");
    const headers = {
   //   'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, formData, httpOptions).pipe(
      tap((res: any) => {
        //this.logger.info('caricaProgetti');
        
        return res;
      }),
      catchError(this.handleError<any>('caricaAnagrficaBox', []))
    );
  }

  assegnaProgetti(file:File, progettista:string): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti/assegnaProgetti`;
   
    let formData = new FormData();
    formData.append('progetti', file);
    formData.append('progettista',progettista);
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, formData, httpOptions).pipe(
      tap((res: any) => {
        //this.logger.info('caricaProgetti');
        
        return res;
      }),
      catchError(this.handleError<any>('caricaProgetti', []))
    );
  }

  assegnaProgetto(progetto:string, progettista:UtenteDTO): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/progetti/${progetto}/assegna`;
   
   // const token = localStorage.getItem("BEtoken");
    const headers = {
   //   'Authorization': `Bearer ${token}`,
   //   'Content-Type': 'application/json',
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };

    this.logger.info('>> sto per assegnare progetto con url '+ this.apiUrl + " AD UTENTE "+ progettista);

    return this.http.post(this.apiUrl, progettista, httpOptions).pipe(
      tap((res: any) => {
        //this.logger.info('caricaProgetti');
        
        return res;
      }),
      catchError(this.handleError<any>('caricaProgetti', []))
    );
  }

  /*
  salvaProgetto(certificato:Certificato): Observable<any> {
    this.logger.info(">>> STO PER SALVERE UN NUOVO PROGETTO");
    const token = localStorage.getItem("token");
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };


    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post("http://localhost:8080/api/protected/certificato", certificato, httpOptions).pipe(
      tap((res: any) => {
        this.logger.info('salvaCertificato');
        return res;
      }),
      catchError(this.handleError<any>('salvaCertificato', []))
    );
  }

  

  

  */

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError = <T>(operation = 'operation', result?: T) => {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      this.logger.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.info(`${operation} failed: ${error.message}`);
      /*this.errorHandler.dialogConfig = {...this.dialogConfig};
      this.errorHandler.handleError(error);*/



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  };
}

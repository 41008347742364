
<div >
  <div class="field">
      <label class="block">Selezionare il tipo di release:</label>
      <p-dropdown [options]="releaseTypes" [(ngModel)]="selectedReleaseType" optionLabel="name"></p-dropdown>
  </div>
 
  <div class="buttons-div">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text" styleClass="p-button-success" >
    <!-- [disabled]="tenantName.length==0 || checkIfTenantNameIsAvailable() || checkIfHasWhiteSpace()" -->
    </p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" styleClass="p-button-warning"></p-button>
  </div>

</div>


import { Component, OnInit } from '@angular/core';
import { ChildActivationStart, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MenuItem,MessageService,PrimeNGConfig } from 'primeng/api';
import { AppConfig } from './app.config';
import { TranslateService } from '@ngx-translate/core';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage  } from "firebase/messaging";
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { MessagingService } from './services/messaging.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApplicationVersionsService } from './services/application-versions.service';
import { Subject } from 'rxjs';
import { environment } from '../environments/environment';
import 'firebase/auth';
import { getAuth } from 'firebase/auth';

export let reloadVersionNumber:boolean = false;
export let currentVersionNumber:string = '';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
   

    constructor(
        private router: Router, 
        private primengConfig: PrimeNGConfig, 
        private translateService: TranslateService,
        private messagingService:MessagingService,
        private applicationVersionService:ApplicationVersionsService,
        public dialogService: DialogService,
        private messageService: MessageService,
        public appConfig: AppConfig) {
            //this.notificationService.requestPermission();
           // messaging.
         }

    menuitems: MenuItem[] = [{label:'', routerLink:''}];

    visibleSidebar: boolean;

    visibleTopbarMenu: boolean;

    scale: number = 16;
    
    contatore:number= 0;    
    ref: DynamicDialogRef;
    applicationVersion:string='';


    scales: number[] = [12,13,14,15,16];
     firebaseConfig = {
        apiKey: "AIzaSyD-7e2rFoB_-CdBEUn1x2TNLuZ3B5kdkbI",
        authDomain: "tim-report-8c2a0.firebaseapp.com",
        projectId: "tim-report-8c2a0",
        storageBucket: "tim-report-8c2a0.appspot.com",
        messagingSenderId: "753082534746",
        appId: "1:753082534746:web:2a2e88631df0ce57bec163",
        measurementId: "G-T9BNV2YLM6",
        vapidKey: "BKWIhhOP3XXRt5ebxp3BmdJHZlaQSqqNgujzKa00JFdFCNhaNZI1AKANYigUBqFTNu1KRuKgRk1MIwl8RwomMek"
      }; 
      /*firebaseConfig = {
        type: "service_account",
        authDomain: "tim-report-8c2a0.firebaseapp.com",
        storageBucket: "tim-report-8c2a0.appspot.com",
        messagingSenderId: "753082534746",
        appId: "1:753082534746:web:2a2e88631df0ce57bec163",
        measurementId: "G-T9BNV2YLM6",
        vapidKey: "BKWIhhOP3XXRt5ebxp3BmdJHZlaQSqqNgujzKa00JFdFCNhaNZI1AKANYigUBqFTNu1KRuKgRk1MIwl8RwomMek",
        project_id: "tim-report-8c2a0",
        private_key_id: "2047ef38ecce94db130f83d237bb8cb5d039bbc0",
        private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCrV0pOdn4ui8Al\nUV87ONWLCJLdbDPlv2fB98sAiXrx6DnkjzecrF6au/c1kS5qlLfKadTsXHyLyf/5\njOmj8KG5o+NAC4FMpmZeBokZeqq6gkPJPi1O/csL+C/5NOLC88u141ZoWzmtoen1\nf37sJMSTwGEjD6j47qeBZ4+iGTgurfNO2k75VeMQOLNINn3JaJArkaFsG3xLCfHf\nKojXYXVd7kmcH/QkNWPZaphFMDFBnruI87YJB2ZUB4Y3g1lFys3sXSDf0S5ZHX80\nWOq5I83RPbnbR0G7WjOIp9wOFdeLwqkjfVkv9eR5UUBWsQMKBjS9Son7Rw2j/y/3\nWf7A3JUHAgMBAAECggEABU1SM1NfXDle3MjD1aVQlljRkqCCip19rHbqhT273Dh/\nch8sbWC4HLmsULAJa6rmdREJUbca84hwr1w47qXHm2MC6Txx59lOWaKCI5O0LMlT\nJ6sQ49zep6Qqd9+3FKlOqHY8ekY4ZrMX9kzXtJ7+pkPRvbACXpGkBVEbFL0LDAax\nBUVBQ9olP8fkBsT/SjFviq1jr3paYGGXauUGIMGmXZSLbTn7M88Tn11bxZgMcOge\nAV6LeIDbWTcflUu+MX4la7Yl/0+Xd6Gm265TVUPN7Qr+HrOMj4aWR4xoeyF4UEqg\n+gS7JAk7THkwLXic1y9lji1RXcfWZkgsrOFk8thH4QKBgQDXQhpcNOMraTNIbmf6\nZFpHjsSDPGIFjdNsLlMdSmPdymmySXZRnfBwL3bAbAzXpPN8QAd975zFuvlA2Tet\npFnn11koGLGiczalDUgZG+jGt8+UDVvMaJAH2wzpMfJHmYqI+oseFL07VC8kAilc\ne/SL+4bHF/q+1Ocu4jn8XnafBQKBgQDLxURs0ifZPVjBXPWjTr9LoLkgmgHwllZb\nuFwWf2KwakBMXppuy6mBPcYA4T1Zfcw0fmA/Sdk51dch+GJ08S1gCndioQ2F79vv\nMQPQp/Fo8vCGiYscEUGcg1tS2uH9zHugVKjem21B7IT+tWB6psfYhtR7TS5+goY1\n95PpnNCpmwKBgGwFiCTthXKrgEjA3v0Qjx2Jz6PsEWSoH4kSVjNEuYj7or8Njoq2\nr4ocx4MYtfkhle6sUxYnwIyZn6ujVgWVjGiD8QHmIn5bQvjhCWVQVCgk5c5uRbr2\nXhETJA+COQslShgjYtzdm3jCbkWrsAXxTm9Q8fnHVtWdEca9UEwNO4nhAoGBAKOl\nUUZzoDz+34CqvzrfyxFZilN8W4Jzufb/IDWRKBi1u92ZPD0uonqAubuLKFHujUi7\nFWC+Vg30hklpyOhRqDcxMJ+s58dg0ihOHNo9hcrbD/98BpQL571iYL0xu7yvs7kX\nRGdCO4RuBoDn+QuHVYe3AH3lhiljeZVttwQuRAVxAoGBAKYSe0i6gzDtz/tGdAQW\nW3uKu3ZXvD8tSU6q18htkKIECZxS/i1UfKs/tYqQ5ImmJ47TTqhQRO5eSFaWbY7M\nCO3LYxZ4M5VOHHNDzfy5KQaK97Hj1o5880Zo11Y/N5wPDUpfNMnkg3NJJ5n6nNuI\nUw5xkroGiHSap6azALC0iXgS\n-----END PRIVATE KEY-----\n",
        client_email: "firebase-adminsdk-xcjpp@tim-report-8c2a0.iam.gserviceaccount.com",
        client_id: "103991795880002070438",
        auth_uri: "https://accounts.google.com/o/oauth2/auth",
        token_uri: "https://oauth2.googleapis.com/token",
        auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
        client_x509_cert_url: "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-xcjpp%40tim-report-8c2a0.iam.gserviceaccount.com"
      
      };*/

    
    ngOnInit() {
      this.firebaseConfig.storageBucket=environment.firebase.storageBucket;

        this.primengConfig.ripple = true;
        this.translateNg();

         if(!this.isSuperAdmin()){
          //console.log("---NON SONO SUPER ADMIN, TUTTO OK");
          //this.getApplicationVersion();

          if(localStorage.getItem("currentAppVersion")!= 'undefined' || localStorage.getItem("currentAppVersion")!= null){
              currentVersionNumber = localStorage.getItem("currentAppVersion");
          }
          const app = initializeApp(this.firebaseConfig);
        
          const messaging = getMessaging(app);
          
          getToken(messaging, {vapidKey: "BKWIhhOP3XXRt5ebxp3BmdJHZlaQSqqNgujzKa00JFdFCNhaNZI1AKANYigUBqFTNu1KRuKgRk1MIwl8RwomMek"}).then(
              (currentToken) => {
                if (currentToken) {
                  //console.log("Hurraaa!!! we got the token.....");
                  
                  //console.log("TOKEN RECUPERATO: " + currentToken);
                  //chiama servizio di iscrizione al token!
                  if(localStorage.getItem("subscribedToAggiornamentiTopic")== 'undefined' || localStorage.getItem("subscribedToAggiornamentiTopic")== null || localStorage.getItem("subscribedToAggiornamentiTopic")=="false"){
                   this.messagingService.subscribeToTopic("aggiornamenti",currentToken).subscribe(result => {
                      //console.log("ISCRITTO CORRETTAMENTE AL TOPIC aggiornamenti" );
                  }); 
              }
                } else {
                  console.log('No registration token available. Request permission to generate one.');
                }
            }).catch((err) => {
               console.log('An error occurred while retrieving token. ', err);
           });
          this.listen(); 
         }else{
          console.log("--->>>> SONO SUPER ADMIN NON DOVREBBE ACCADERE NULLA");
         }
           
        
        this.router.events.
            pipe(filter(event => event instanceof ChildActivationStart))
            .subscribe(event => {
                if (event['snapshot'].data.name) {
                    let prevRoute = this.menuitems[this.menuitems.length - 1].routerLink;
                    this.menuitems.push({label: event['snapshot'].data.name, routerLink: prevRoute + '/' + event['snapshot'].url[0].path});
                }
            });

        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => this.menuitems = [{label:'', routerLink:''}]);

        /* localStorage.setItem('userId', null);
        localStorage.setItem('utente', null);
        localStorage.setItem('email', null);
        localStorage.setItem('ruolo', null);
        localStorage.setItem('auth0Token', null);
        localStorage.setItem('BEtoken', null);
        localStorage.setItem('tenantId', null);
        localStorage.setItem('tenantName', null);
        localStorage.setItem('user.sub', null); */
    }

      listen() {
        const messaging = getMessaging();
        
           // console.log("-> listen su APP con userId " + localStorage.getItem('userId'));
            onMessage(messaging, (payload) => {
              //console.log('MESSAGGIO RICEVUTO! ', payload);
              //this.message=payload;
              if(localStorage.getItem('userId') != null && localStorage.getItem('userId') !== "null" && localStorage.getItem('userId') !== ""){
             
                //console.log('arrivata notifica con userid '+ localStorage.getItem('userId'));
                  this.messageService.add({
                    key: 'notificaNuovaVersione',
                    life:environment.progettiMessageDuration,
                    severity: 'custom-1',
                    summary: payload.notification.title,
                    detail: payload.notification.body,
                    closable: false,
                    contentStyleClass: 'p-0'
                });
            }
        });
     
    
      }  

      isSuperAdmin() {
        //console.log("-> isSuperAdmin su APP "+ localStorage.getItem('ruolo'));
        return localStorage.getItem('ruolo') == "SUPERADMIN";
     }
     
      checkApplicationVersion(){
        console.log("checkApplicationVersion su APP");
        this.applicationVersionService.getCurrentVersion().subscribe(result => {
          this.applicationVersion   = result.version;
         
          if(localStorage.getItem("currentAppVersion")== 'undefined' || localStorage.getItem("currentAppVersion")== null){
            this.openChangeLogDialog();
            //localStorage.setItem('currentAppVersion', this.applicationVersion);
        }else{
          if(localStorage.getItem('currentAppVersion')!= this.applicationVersion){
            this.openChangeLogDialog();
          }
          
      }
        },
        error => {
          console.error('ERRORE get version' + error);
        })
    }
        
      aggiornaApp(){
        console.log("aggiornaApp su APP");
        this.checkApplicationVersion();
        window.location.reload();
      }
    
      chiudiDialog(){
        this.messageService.clear();
      }

    translateNg():void {
        this.translateService.setDefaultLang('it');
        this.translateService.get('primeng').subscribe(res => this.primengConfig.setTranslation(res));
  
      }
      
    changeTheme(event: Event, theme: string, dark: boolean) {
        let themeElement = document.getElementById('theme-link');
        themeElement.setAttribute('href', themeElement.getAttribute('href').replace(this.appConfig.theme, theme));
        this.appConfig.theme = theme;
        this.appConfig.dark = dark;
        event.preventDefault();
    }

    decrementScale() {
        this.scale--;
        this.applyScale();
    }

    incrementScale() {
        this.scale++;
        this.applyScale();
    }

    applyScale() {
        document.documentElement.style.fontSize = this.scale + 'px';
    }
    
    showTopbarMenu(event: Event) {
        this.visibleTopbarMenu = true;
        event.preventDefault();
    }

    openChangeLogDialog(){
        if(localStorage.getItem('userId')!='undefined' || localStorage.getItem('userId')!=null){
            if(this.contatore == 0){
              this.contatore = 1;
              this.ref = this.dialogService.open(DialogChangeLog, {
                data: {
              },
                header: 'Novità della versione '+this.applicationVersion,
                width: '95%',
                height: '90%',
                contentStyle: { "overflow": "true"},
                baseZIndex: 10000,
                closable:true,
                draggable:false,
                closeOnEscape:true,
            }); 
            localStorage.setItem('currentAppVersion', this.applicationVersion);
            //console.log("-> OPEN CHANGE LOG DIALOG "+ this.applicationVersion);
            //currentVersionNumber = this.applicationVersion;
            }
        }else{
          console.log("-> UTENTE NULLO NON APRO CHANGE LOG ");
        }
      }
    
      getApplicationVersion(){
          this.applicationVersionService.getCurrentVersion().subscribe(result => {
            this.applicationVersion     = result.version;
            currentVersionNumber        = result.version;
          },
          error => {
            console.error('ERRORE get version' + error);
          })
          
      }
}

@Component({
    templateUrl: './dialog-changelog.component.html',
    //styleUrls: ['./app.component.css']
  })
  export class DialogChangeLog implements OnInit {
    protected _onDestroy = new Subject<void>();
    constructor(
        public dialogRef: DynamicDialogRef,
         public config: DynamicDialogConfig,
  
        ) {
    }
  
    ngOnInit(): void {
    }
  
    conferma(conferma:boolean) {
        if(!conferma){
            this.dialogRef.close({success: false});
        }else {
         
            this.dialogRef.close({success: true});
   
        }
    }
  }
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { map, Observable, Subject, switchMap, tap } from 'rxjs';


import { AuthService } from '@auth0/auth0-angular';
import {Utente} from '../../models/utente.model';
//import {UtentiService} from 'src/app/services/utenti.service';
//import { TokenAuth0 } from 'src/app/models/tokenAuth0.model';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { UtentiService } from 'src/app/services/utenti.service';
import { TokenAuth0 } from 'src/app/models/tokenAuth0.model';
import {ToastModule} from 'primeng/toast';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Ruolo } from 'src/app/models/utente.ruolo.models';
import { IfStmt } from '@angular/compiler';
import { ProgettiService } from 'src/app/services/progetti.service';
import { AnagraficheService } from 'src/app/services/anagrafiche.service';

import { CaricamentoProgettiResult } from 'src/app/models/caricamentoProgetti.result.models';
import { Tenant } from 'src/app/models/tenant.models';


@Component({
  selector: 'app-utenti',
  templateUrl: './utenti.component.html',
  styleUrls: ['./utenti.component.css'],
  providers: [DialogService, ConfirmationService]
})
export class UtentiComponent implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  utenti: Utente[] = [];
  dialogConfig;
  selectedUtente: Utente[] = [];
  auth0Token:TokenAuth0;
  isAdmin:boolean=false;
  isSuperAdmin:boolean=false;
  msgs: Message[] = [];
  displayModificaRuolo= false;
  ref: DynamicDialogRef;
  risultatoCaricamentoProgetti: CaricamentoProgettiResult | null;

  constructor(
    private logger: NGXLogger,
    private utentiService: UtentiService,
    public auth: AuthService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public dialogService: DialogService
  ) {
      this.dialogConfig = {
        height: '600px',
        width: '600px',
        disableClose: true,
        data: {}
      };
     }

  ngOnInit(): void {
    this.isAdmin      = localStorage.getItem('ruolo') == "AMMINISTRATORE";
    this.isSuperAdmin = localStorage.getItem('ruolo') == "SUPERADMIN";
    this.loadData();
    
  }

  ngAfterViewInit() {
  }

  loadData() {
    //console.log("LOADING UTENTI");
    this.utentiService.token().subscribe((res:TokenAuth0) => {
      this.auth0Token = res;
      //this.logger.info(this.auth0Token.access_token);
      //this.logger.info(">>> CHE RUOLO HO >>> " +localStorage.getItem('ruolo'));
      if(this.auth0Token) {
        this.utentiService.utenti(this.auth0Token).pipe(
          map(data => {
            //this.logger.info(">  UTENTI PRESENTI A DB: "+ data.total);
            //this.logger.info(data.users);
           
            let cleanUtentiList : Utente[] = [];

            //data = JSON.parse(data);
            data.users.forEach(d => {
              //if(d.user_metadata!=null)  {
                if(localStorage.getItem('ruolo')=="SUPERADMIN") {//se sono superadmin vedo tutto,
                 
                  if(d.app_metadata != null){
                    d.ruolo = d.app_metadata.roles;
                  }else {
                    d.ruolo = "OPERATORE";
                  }
                  cleanUtentiList.push(d);
                
                }else { // altrimenti solo utenti del tenant
                  //this.logger.info('>  TENANT: '+ d.user_metadata.tenant_id + ' Vs ' + localStorage.getItem('tenantId'));
                  if(d.name != "supporto@vertical-srl.it") {
                    
                  
                    if(d.user_metadata.tenant_id == localStorage.getItem('tenantId')) {
                      //this.logger.info('>>> CHE UTENTE SONO ? ' + d.name);
                      if(d.app_metadata != null){
                        d.ruolo = d.app_metadata.roles;
                      }else {
                        d.ruolo = "OPERATORE";
                      }

                      if(d.app_metadata.roles != "SUPERADMIN" ){
                        cleanUtentiList.push(d);
                      } 
                    }
                  }
                }
              //}
              });

              return cleanUtentiList;
          })

        ).subscribe((res:any) => {

          this.utenti = res;
          this.isLoadingResults = false;
        });
      }
  } );
  
  }


  openAssegnaTenant(utente:Utente){
    console.log("ASSEGNO TENANT ALl'utente > "+ utente.name);

    //this.displayModificaRuolo = true;
      this.ref = this.dialogService.open(DialogAssegnaTenant, {
        data: {
          utente: utente,
          token: this.auth0Token
      },
        header: 'Assegna Azienda all\'Utente ' + utente.name,
        width: '40%',
        height: '40%',
        contentStyle: {"max-height": "500px"},
        baseZIndex: 10000,
        closable:true,
        draggable:false,
    });
    this.ref.onClose.subscribe(changeRoleResult => {
      console.log("AFTER CLOSED > "+ changeRoleResult.success);
      if(changeRoleResult.success){
        this.messageService.add({key: 'tc',severity:'success', summary: '', detail: 'Utente modificato con successo!'});
        this.loadData();
      }
    });
  }

  openAssegnaProgettiDialog(utente:Utente):void{
    this.ref = this.dialogService.open(DialogAssegnaProgetti, {
      data: {
        utente: utente,
        token: this.auth0Token
    },
      header: 'Assegna progetti all\'utente '+ utente.name,
      width: '70%',
      height: '50%',
      contentStyle: {"max-height": "500px", "overflow": "auto"},
      baseZIndex: 10000,
      closable:true,
      draggable:false,
  });

   this.ref.onClose.subscribe(assegnaResult=> {
    console.log("AFTER CLOSED > ");
    console.log(assegnaResult.risultato);
    this.risultatoCaricamentoProgetti = assegnaResult.risultato;
        
    if(assegnaResult.success) {
      this.messageService.add({key: 'tc',severity:'success', summary: '', detail: 'Dei '+ this.risultatoCaricamentoProgetti.numeroProgettiPresentiNelFile + ' progetti presenti nel file, ne sono stati correttamente assegnati '+ this.risultatoCaricamentoProgetti.numeroProgettiCaricati + ' all\'utente '+ utente.name});
   
    }
    });  
  }


  openChangeRoleDialog(utente:Utente):void{
    console.log("CAMBIO I RUOLI dell'utente > "+ utente.name);
    //this.displayModificaRuolo = true;
      this.ref = this.dialogService.open(DialogModificaRuoloUtente, {
        data: {
          utente: utente,
          token: this.auth0Token
      },
        header: 'Modifica Ruolo Utente',
        width: '40%',
        contentStyle: {"max-height": "500px", "overflow": "auto"},
        baseZIndex: 10000,
        closable:true,
        draggable:false,
    });
    this.ref.onClose.subscribe(changeRoleResult => {
      console.log("AFTER CLOSED > "+ changeRoleResult.success);
      if(changeRoleResult.success){
        this.messageService.add({key: 'tc',severity:'success', summary: '', detail: 'Utente modificato con successo!'});
        this.loadData();
      }
    });
 
  }

  openLinkToTenantDialog(utente:Utente):void{
    
  }

  openDeleteUserDialog(utente:Utente):void{
   
    this.confirmationService.confirm({
      message: `Confermi l\'eliminazione dell\'utente ${utente.user_id}?`,
      header: 'Eliminazione Utente',
      icon: 'pi pi-info-circle',
      acceptLabel:'CONFERMA',
      acceptIcon:'pi pi-times',
      acceptButtonStyleClass:'p-button-success',
      rejectLabel:'ANNULLA',
      rejectIcon:'pi pi-check',
      rejectButtonStyleClass:'p-button-warning',
      accept: () => {
        this.utentiService.eliminaUtente(utente.user_id, this.auth0Token)
        .subscribe( {
            next: (v) => { 
            },
            error: (e) => {
              this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di eliminazione utente'});
          },
            complete: () => { 
              this.messageService.add({severity:'success', summary:'', detail:'Utente eliminato'});
              this.loadData();
            } 
        });
          
      },
      reject: () => {
          //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
         // console.log("ANNULLO");
      }
    });
  }
}

@Component({
  templateUrl: './dialog-assegna-progetti.component.html',
  styleUrls: ['./utenti.component.css']
})
export class DialogAssegnaProgetti implements OnInit {

  protected _onDestroy = new Subject<void>();
  selectedFile:File;
  isFileUploaded:boolean = false;
 
  constructor(
      private logger: NGXLogger,
     // private utentiService: UtentiService,
      public dialogRef: DynamicDialogRef,
      public auth: AuthService,
      public config: DynamicDialogConfig,
      private messageService: MessageService,
      private progettiService: ProgettiService) {
  }

  ngOnInit(): void {
  }

  onFileUpload($event){
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }

  conferma(conferma:boolean) {
    this.logger.info('');
    const utente = this.config.data.utente.user_id.substring(this.config.data.utente.user_id.indexOf('|')+1, this.config.data.utente.user_id.length);
    if(!conferma){
        this.dialogRef.close({success: false});
    }else {
      this.logger.info('>> sto per assegnare progetti dal file '+ this.selectedFile.name);
         this.progettiService
          .assegnaProgetti(this.selectedFile,utente )
          .subscribe((result) => {
           this.dialogRef.close({success: true, risultato: result});
            
      }); 
  }

  }
  
}

@Component({
  templateUrl: './dialog-assegna-tenant.component.html',
  styleUrls: ['./utenti.component.css']
})
export class DialogAssegnaTenant implements OnInit {

  protected _onDestroy = new Subject<void>();
  public tenants: Tenant[];
  public tenantSelezionato : Tenant;
  
 
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public auth: AuthService,
      public config: DynamicDialogConfig,
      private anagraficheService: AnagraficheService,
      private utentiService:UtentiService,
      private messageService: MessageService
      ) {
    
  }

  ngOnInit(): void {
      this.anagraficheService.tenants().subscribe((tenants) =>
    {
      this.tenants = tenants;
      
    }
    ); 
  }


  conferma(conferma:boolean) {
    this.logger.info('');
    const utente = this.config.data.utente.user_id.substring(this.config.data.utente.user_id.indexOf('|')+1, this.config.data.utente.user_id.length);
    if(!conferma){
        this.dialogRef.close({success: false});
    }else {
      this.logger.info('>> sto per assegnare utente al tenant ' );
      this.utentiService.cambiaUserMetadata(
        this.config.data.utente.user_id, 
        this.tenantSelezionato.id,
        this.tenantSelezionato.name,  
        this.config.data.token).subscribe(
        {
          next: (v) => { 
          },
          error: (e) => {
            this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di assegnazione tenant all\' utente'});
            this.dialogRef.close({success: false});
          },
          complete: () => { 
            this.messageService.add({severity:'success', summary:'', detail:'Utente correttamente assegnato al tenant '+ this.tenantSelezionato.name});
            this.dialogRef.close({success: true});
          }
        }
      );

  }

  }
  
}


@Component({
  templateUrl: './dialog-utente.component.html',
  styleUrls: ['./utenti.component.css']
})
export class DialogModificaRuoloUtente implements OnInit {

  public ruoli: Ruolo[];
  public ruoloSelezionato : string;
  public ruoloSelezionatoString : string;

  protected _onDestroy = new Subject<void>();

  constructor(
      private logger: NGXLogger,
      private utentiService: UtentiService,
      public dialogRef: DynamicDialogRef,
      public auth: AuthService,
      public config: DynamicDialogConfig,
      private messageService: MessageService) {
      this.ruoli = [
        {nome:'AMMINISTRATORE', descrizione:'Amministratore',id:'rol_kq6u4UwLlHkXlgNo'},
        {nome:'COORDINATORE', descrizione:'Coordinatore Aziendale è in grado di operare su tutti i progetti del team',id:'rol_8U6Tg8PxV16TQev7'},
        {nome:'OPERATORE', descrizione:'Utente base',id:'rol_Fos2iaWmrNjdIBEp'},
      ]
  }



  ngOnInit(): void {
  }

  selectRuolo(selezionato){
   
    
    this.ruoli.filter(ruolo => ruolo.id == this.ruoloSelezionato).map(ruolo =>  this.ruoloSelezionatoString = ruolo.nome);
   
  }

  conferma(conferma:boolean) {
    if (conferma) {
      this.utentiService.cambiaAppMetadata(
        this.config.data.utente.user_id, 
        this.ruoloSelezionatoString,  
        this.config.data.token).subscribe( {
          next: (v) => { 
          },
          error: (e) => {
            this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di aggiornamento ruolo utente'});
            this.dialogRef.close({success: false});
          },
          complete: () => { 
            
            this.utentiService.cambiaRuolo(this.config.data.utente.user_id, this.ruoloSelezionato,this.config.data.token ).subscribe(
            {
              next: (v) => { 
              },
              error: (e) => {
                this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di aggiornamento ruolo utente'});
                this.dialogRef.close({success: false});
              },
              complete: () => { 
                this.messageService.add({severity:'success', summary:'', detail:'Ruolo dell\'utente aggiornato correttamente'});
                this.dialogRef.close({success: true});
              }
            }
          )

        } 
      }
      );
      
      
    } else {
      this.dialogRef.close({success: false});
    }

  }
  
}


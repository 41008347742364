import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import {NGXLogger} from 'ngx-logger';
@Injectable({
  providedIn: 'root',
})
export class AnagraficheService{
  apiUrl: string = `${environment.apiUrl}/protected/certificato`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}

    caricaProgetti(file:File): Observable<any> {
      this.apiUrl =`${environment.apiUrl}/anagrafiche/uploadProgetti`;
     
      let formData = new FormData();
      formData.append('progetti', file);
      formData.append('tenantId',localStorage.getItem("tenantId"));
      formData.append('tenantName',localStorage.getItem("tenantName"));
     // const token = localStorage.getItem("BEtoken");
      const headers = {
      //  'Authorization': `Bearer ${token}`,
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(this.apiUrl, formData, httpOptions)/* .pipe(
        tap((res: any) => {
          //this.logger.info('caricaProgetti');
          
          return res;
        }),
        catchError(this.handleError<any>('caricaProgetti', []))
      ) */;
    }
  
    caricaAnagraficaBox(file:File): Observable<any> {
      this.apiUrl =`${environment.apiUrl}/anagrafiche/uploadAnagraficaBox`;
      let formData = new FormData();
      formData.append('anagraficaBox', file);
      formData.append('tenantName',localStorage.getItem("tenantName"));
      formData.append('tenantId',localStorage.getItem("tenantId"));
      //const token = localStorage.getItem("BEtoken");
      const headers = {
      //  'Authorization': `Bearer ${token}`,
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(this.apiUrl, formData, httpOptions);/* .pipe(
        tap((res: any) => {
          this.logger.info('> DENTRO SERVIZIO caricaAnagraficaBox');
          
          return res;
        }),
        catchError(this.handleError<any>('caricaAnagrficaBox', []))
      ); */
    }

    caricaCivici(file:File): Observable<any> {
      this.apiUrl =`${environment.apiUrl}/anagrafiche/uploadCivici`;
      let formData = new FormData();
      formData.append('civici', file);
      formData.append('tenantName',localStorage.getItem("tenantName"));
      formData.append('tenantId',localStorage.getItem("tenantId"));
      //const token = localStorage.getItem("BEtoken");
      const headers = {
      //  'Authorization': `Bearer ${token}`,
      };
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      return this.http.post(this.apiUrl, formData, httpOptions)/* .pipe(
        tap((res: any) => {
          //this.logger.info('caricaProgetti');
          
          return res;
        }),
        catchError(this.handleError<any>('caricaCivici', []))
      ) */;
    }

    tenants(): Observable<any> {
      this.apiUrl =`${environment.apiUrl}/tenants`;
      
      const headers = {
      //  'Authorization': `Bearer ${localStorage.getItem("BEtoken")}`,
      };
      return this.http.get(this.apiUrl).pipe(
        tap((res: any) => {
          return res;
        }),
        catchError(this.handleError<any[]>('getTenants', []))
      );
    }
  

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError = <T>(operation = 'operation', result?: T) => {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      this.logger.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.info(`${operation} failed: ${error.message}`);

      /*this.errorHandler.dialogConfig = {...this.dialogConfig};
      this.errorHandler.handleError(error);*/



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  };
}


<div >
  <div class="field">
      <label for="tenantName" class="block">Nome nuovo tenant</label>
      <input id="tenantName" type="tenantName" aria-describedby="tenantName-help" [(ngModel)]="tenantName" pInputText />
      <small *ngIf="checkIfTenantNameIsAvailable()" id="tenantName-help" class="p-error block">Nome già in uso</small>
      <small *ngIf="checkIfHasWhiteSpace()" id="tenantName-white-space" class="p-error block">Nel nome del tenant non sono ammessi spazi vuoti</small>
  </div>
 
  <div class="buttons-div">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text" styleClass="p-button-success" [disabled]="tenantName.length==0 || checkIfTenantNameIsAvailable() || checkIfHasWhiteSpace()"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" styleClass="p-button-warning"></p-button>
  </div>

</div>


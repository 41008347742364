import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { map, Observable, Subject, switchMap, tap } from 'rxjs';


import { AuthService } from '@auth0/auth0-angular';
import {Utente} from '../../models/utente.model';
//import {UtentiService} from 'src/app/services/utenti.service';
//import { TokenAuth0 } from 'src/app/models/tokenAuth0.model';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { UtentiService } from 'src/app/services/utenti.service';
import { TokenAuth0 } from 'src/app/models/tokenAuth0.model';
import {ToastModule} from 'primeng/toast';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Ruolo } from 'src/app/models/utente.ruolo.models';
import { IfStmt } from '@angular/compiler';
import { ProgettiService } from 'src/app/services/progetti.service';
import { AnagraficheService } from 'src/app/services/anagrafiche.service';

import { CaricamentoProgettiResult } from 'src/app/models/caricamentoProgetti.result.models';
import { Tenant } from 'src/app/models/tenant.models';
import { TenantsService } from 'src/app/services/tenants.service';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-utenti',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.css'],
  providers: [DialogService, ConfirmationService]
})
export class TenantComponent implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  tenants: Tenant[] = [];
  dialogConfig;
  selectedTenant: Tenant;
  auth0Token:TokenAuth0;
  public isSuperAdmin:boolean=false;
  msgs: Message[] = [];
  displayModificaRuolo= false;
  ref: DynamicDialogRef;
  risultatoCaricamentoProgetti: CaricamentoProgettiResult | null;

  constructor(
    private logger: NGXLogger,
    private tenantsService: TenantsService,
    public auth: AuthService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public dialogService: DialogService
  ) {
      this.dialogConfig = {
        height: '600px',
        width: '600px',
        disableClose: true,
        data: {}
      };
     }

  ngOnInit(): void {
    this.isSuperAdmin = localStorage.getItem('ruolo') == "SUPERADMIN";
    this.loadData();
    
  }

  ngAfterViewInit() {
  }

  checkIfTenantNameAlreadyExists(tenantName:string):boolean{
    return true;
  }

  loadData() {
    this.isLoadingResults = true;
    this.tenantsService.get().subscribe(res => {
      this.tenants = res;
      this.isLoadingResults = false;
    });
   
  
  }

  openCreaTenantDialog(){

      this.ref = this.dialogService.open(DialogCreaTenant, {
        data: {
         tenants: this.tenants,
      },
        header: 'Crea Tenant ',
        width: '40%',
        height: '40%',
        contentStyle: {"max-height": "500px"},
        baseZIndex: 10000,
        closable:true,
        draggable:false,
    });
    this.ref.onClose.subscribe(confirmNewTenant => {
      if(confirmNewTenant.success){
        this.isLoadingResults = true;
        this.tenantsService.crea(confirmNewTenant.tenantName).subscribe(res => {
          this.messageService.add({key: 'tc',severity:'success', summary: '', detail: 'Tenant ' +confirmNewTenant.tenantName.toUpperCase() + ' creato con successo!'});
          this.loadData();
        });
        
      }
    }); 
  }

  openDeleteTenantDialog(tenant:Tenant):void{}

  openModificaTenantDialog(tenant:Tenant):void{
   
    /* this.confirmationService.confirm({
      message: `Confermi l\'eliminazione dell\'utente ${utente.user_id}?`,
      header: 'Eliminazione Utente',
      icon: 'pi pi-info-circle',
      acceptLabel:'CONFERMA',
      acceptIcon:'pi pi-times',
      acceptButtonStyleClass:'p-button-success',
      rejectLabel:'ANNULLA',
      rejectIcon:'pi pi-check',
      rejectButtonStyleClass:'p-button-warning',
      accept: () => {
        this.tenantsService.eliminaUtente(utente.user_id, this.auth0Token)
        .subscribe( {
            next: (v) => { 
            },
            error: (e) => {
              this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di eliminazione utente'});
          },
            complete: () => { 
              this.messageService.add({severity:'success', summary:'', detail:'Utente eliminato'});
              this.loadData();
            } 
        });
          
      },
      reject: () => {
          //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
         // console.log("ANNULLO");
      }
    });*/
  } 
}

@Component({
  templateUrl: './dialog-crea-tenant.component.html',
  styleUrls: ['./tenants.component.css']
})
export class DialogCreaTenant implements OnInit {

  protected _onDestroy = new Subject<void>();
  public tenants: Tenant[];
  public tenantSelezionato : Tenant;
  public tenantName:String="";
 
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public auth: AuthService,
      public config: DynamicDialogConfig,
      private anagraficheService: AnagraficheService,
      private utentiService:UtentiService,
      private messageService: MessageService
      ) {
    
  }

  ngOnInit(): void {
    this.tenants = this.config.data.tenants;
      /* this.anagraficheService.tenants().subscribe((tenants) =>
    {
      this.tenants = tenants;
      
    }
    );  */

  }

  checkIfHasWhiteSpace():boolean {
    return this.tenantName.indexOf(' ') >= 0;
  }

  checkIfTenantNameIsAvailable():boolean{
    if(this.tenantName.length == 0){
      return false;
    }else {
      let nameAlreadyAtDB:boolean = false;
      this.tenants.forEach(tenant => {
        if(this.tenantName.toLowerCase() == tenant.name.toLowerCase()){
          nameAlreadyAtDB = true;
        }
      })
      return nameAlreadyAtDB;
    }
    
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.dialogRef.close({ success: true, tenantName:this.tenantName });
    }
  }
  
}





<div class="surface-ground p-4 shadow-2 border-round"> <!---->
  <div class="mb-3 flex flex-column md:flex-row md:align-items-center md:justify-content-between">
    <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded p-button-outlined"
      style="background-color: rgb(175, 209, 175); color: white;" (click)="loadData()"></button>

    <p class="text-xl font-bold text-1500" style="color: #163d77; text-align:center; display: block;">PROGETTI PRELIMINARI
      {{filterLabel}}
    </p>

    <div class="flex">
      <button *ngIf="filter!='all'" pButton pRipple type="button" icon="pi pi-filter-slash"
        class="p-button-rounded mr-1 ml-1 p-button-outlined" style="background-color: rgb(73, 162, 173); color: white;"
        (click)="loadDataRemovingFilter()"></button>
    
    </div>
  </div>

  <div fxFlex="95%" class="table-behind">

    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}">
    </p-confirmDialog>

    <p-table styleClass="p-datatable-sm" [value]="progetti" dataKey="id" [(selection)]="selectedProgetto"
      [selectionPageOnly]="true" [rowHover]="true" [rows]="20" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[20,25,50]" [loading]="isLoadingResults" responsiveLayout="scroll" [paginator]="true"
      currentPageReportTemplate="{first} - {last} di {totalRecords}" [filterDelay]="0">

      <ng-template pTemplate="header">
        <tr>
          <th *ngIf="filter=='rework' || filter=='all' || filterLabel=='CONSEGNATI'"> </th>
          <th pSortableColumn="codice" style="font-size: 15px;">
            <div class="flex justify-content-between align-items-center">
              Codice
              <p-sortIcon field="codice"></p-sortIcon>
              <p-columnFilter type="text" field="codice" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="centrale" style="font-size: 15px;">
            <div class="flex justify-content-between align-items-center">
              Centrale
              <p-sortIcon field="centrale"></p-sortIcon>
              <p-columnFilter type="text" field="centrale" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="citta" style="font-size: 15px;">
            <div class="flex justify-content-between align-items-center">
              Città
              <p-sortIcon field="citta"></p-sortIcon>
              <p-columnFilter type="text" field="citta" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="cliente" style="font-size: 15px;">
            <div class="flex justify-content-between align-items-center">
              Cliente
              <p-sortIcon field="cliente"></p-sortIcon>
              <p-columnFilter type="text" field="cliente" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="dataModifica" style="font-size: 15px;" *ngIf="filterLabel!='CONSEGNATI'; else elseUltimaModificaHeaderBlock">
            <div class="flex justify-content-between align-items-center">
              Ultima modifica
              <p-sortIcon field="dataModifica"></p-sortIcon>
              <p-columnFilter type="date" field="dataModifica" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <ng-template #elseUltimaModificaHeaderBlock>
            <th pSortableColumn="dataConsegna" style="font-size: 15px;" >
              <div class="flex justify-content-between align-items-center">
                Data Consegna
                <p-sortIcon field="dataConsegna"></p-sortIcon>
                <p-columnFilter type="date" field="dataConsegna" display="menu" class="ml-auto"></p-columnFilter>
              </div>
            </th>
          </ng-template>
          <th pSortableColumn="settimanaConsegna" style="font-size: 15px;" *ngIf="filterLabel!='CONSEGNATI'; else elseConsegnaHeaderBlock">
            <div class="flex justify-content-between align-items-center">
              Consegna
              <p-sortIcon field="settimanaConsegna"></p-sortIcon>
              <p-columnFilter type="numeric" field="settimanaConsegna" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <ng-template #elseConsegnaHeaderBlock>
            <th pSortableColumn="progettistaConsegna" style="font-size: 15px;" >
              <div class="flex justify-content-between align-items-center">
                Consegnato da
                <p-sortIcon field="progettistaConsegna"></p-sortIcon>
                <p-columnFilter type="text" field="progettistaConsegna" display="menu" class="ml-auto"></p-columnFilter>
              </div>
            </th>
          </ng-template>
          <th pSortableColumn="statoProgetto.descrizione" style="font-size: 15px;" *ngIf="filterLabel!='CONSEGNATI'">
            <div class="flex justify-content-between align-items-center">
              Stato
              <p-sortIcon field="statoProgetto.descrizione"></p-sortIcon>
              <p-columnFilter *ngIf="filter=='all'" type="text" field="statoProgetto.descrizione" display="menu"
                class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th *ngIf="isAdmin || isCoordinatore" pSortableColumn="progettista">
            <div class="flex justify-content-between align-items-center">
              Progettista
              <p-sortIcon field="progettista"></p-sortIcon>
              <p-columnFilter type="text" field="progettista" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th>
            <div class="flex justify-content-center align-items-center">
              Azioni
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row let-expanded="expanded">
        <tr>
          <td *ngIf="(filterLabel==' IN REWORK' && checkIfProjectIsReworking(row.statoProgetto.codice)) || filterLabel=='CONSEGNATI' || filter=='all' ">
            <button *ngIf="row.hasBeenReworked" type="button" pButton pRipple 
            (click)="loadReworks(row, expanded)"
            [pRowToggler]="row" 
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              [disabled]="(filterLabel=='CONSEGNATI' || filter=='all') && !row.hasBeenReworked"
              ></button>
               <!-- [pRowToggler]="row" -->
          </td>
          <td> <span class="text-lowercase">{{row.codice}}</span> </td>
          <td> <span class="text-lowercase">{{row.centrale}}</span> </td>
          <td> <span class="text-lowercase">{{row.citta}}</span> </td>
          <td> <span class="text-lowercase">{{row.cliente}}</span> </td>
          <td > 
            <span *ngIf="filterLabel!='CONSEGNATI'; else elseUltimaModificaContentBlock" style="font-size: 15px;">{{row.dataModifica | date:'dd/MM/yyyy HH:mm'}}</span> 
          </td>
          <ng-template #elseUltimaModificaContentBlock>
            <span style="font-size: 15px;">{{row.dataConsegna | date:'dd/MM/yyyy HH:mm'}}</span> 
          </ng-template>
          <td> 
            <span *ngIf="filterLabel!='CONSEGNATI'; else elseConsegnaProgettoContentBlock" class="flex justify-content-center align-items-center" [class]="'consegna-progetto-badge consegnaScaduta-'+row.consegnaScaduta">{{row.settimanaConsegna }}</span>
            <ng-template #elseConsegnaProgettoContentBlock>
              <span style="font-size: 15px;">{{row.progettistaConsegna}}</span> 
            </ng-template>
          </td>
          <td *ngIf="filterLabel!='CONSEGNATI'"> <span [class]="'stato-progetto-badge id-'+row.statoProgetto.id">{{row.statoProgetto.codice}}</span> </td>

          <td *ngIf="isAdmin || isCoordinatore"> <span>{{row.nomeProgettista}}</span></td>
          <td class="flex justify-content-center align-items-center">

            <button *ngIf="(isAdmin || isCoordinatore) && !checkIfProjectIsDelivered(row.statoProgetto.codice)" pButton
              type="button" icon="pi pi-user-plus" tooltipPosition="top" pTooltip="Assegna a progettista"
              class="p-button-rounded p-button-text" (click)="openAssegnaProgettoDialog(row)"
              [disabled]="checkIfProjectIsDelivered(row.statoProgetto.codice) ">
            </button>

            <button
              *ngIf="!checkIfProjectIsDelivered(row.statoProgetto.codice) && checkIfProjectIsGenerated(row.statoProgetto.codice) || checkIfProjectIsReworking(row.statoProgetto.codice)"
              pButton pRipple icon="pi pi-cloud-download" class="p-button-rounded p-button-text"
              (click)="openScaricaProgetto(row)" tooltipPosition="top" pTooltip="Scarica progetto"></button>
<!--  -->
            <button
              *ngIf="!checkIfProjectIsGenerated(row.statoProgetto.codice) 
              && !checkIfProjectIsDelivered(row.statoProgetto.codice) 
              && !checkIfProjectIsReworking(row.statoProgetto.codice) 
              && checkIfProjectIsAssigned(row.statoProgetto.codice)"
              pButton pRipple icon="pi pi-plus" class="p-button-rounded p-button-text" (click)="openCreaProgetto(row)"
              tooltipPosition="top" pTooltip="Genera progetto"
              [disabled]="!canGeneratePreliminari"></button>

            <button
              *ngIf="!checkIfProjectIsDelivered(row.statoProgetto.codice) && checkIfProjectIsGenerated(row.statoProgetto.codice) || checkIfProjectIsReworking(row.statoProgetto.codice)"
              pButton pRipple icon="pi pi-calculator" class="p-button-rounded p-button-text"
              (click)="openConsolidaProgetto(row)" tooltipPosition="top" pTooltip="Consolida progetto"></button>


            <button
              *ngIf="!checkIfProjectIsDelivered(row.statoProgetto.codice) && !checkIfProjectIsReworking(row.statoProgetto.codice)"
              pButton type="button" icon="pi pi-calendar" tooltipPosition="top" pTooltip="Scadenza"
              class="p-button-rounded p-button-text" (click)="openSetDeliveryDateProgettoDialog(row)"
              [disabled]="checkIfProjectIsDelivered(row.statoProgetto.codice) ">
            </button>


            <button
              *ngIf="!checkIfProjectIsDelivered(row.statoProgetto.codice) && checkIfProjectIsGenerated(row.statoProgetto.codice) || checkIfProjectIsReworking(row.statoProgetto.codice)"
              pButton type="button" icon="pi pi-file-word" tooltipPosition="top" pTooltip="Report"
              class="p-button-rounded p-button-text" (click)="openProduciReportDialog(row)"
              [disabled]="!checkIfProjectIsGenerated(row.statoProgetto.codice) && !checkIfProjectIsReworking(row.statoProgetto.codice)">
            </button>

            <button
              *ngIf="!checkIfProjectIsDelivered(row.statoProgetto.codice) && checkIfProjectIsGenerated(row.statoProgetto.codice) || checkIfProjectIsReworking(row.statoProgetto.codice)"
              pButton pRipple type="button" icon="pi pi-book" class="p-button-rounded p-button-text"
              (click)="openSinotticoDialog(row)" tooltipPosition="top" pTooltip="Sinottico"
              [disabled]="!checkIfProjectIsGenerated(row.statoProgetto.codice) && !checkIfProjectIsReworking(row.statoProgetto.codice)">
            </button>

            <button
              *ngIf="!checkIfProjectIsDelivered(row.statoProgetto.codice) && checkIfProjectIsGenerated(row.statoProgetto.codice) || checkIfProjectIsReworking(row.statoProgetto.codice)"
              pButton pRipple type="button" icon="pi pi-bookmark" class="p-button-rounded p-button-text"
              (click)="openProduciEtichetteDialog(row)" tooltipPosition="top" pTooltip="Etichette"
              [disabled]="!checkIfProjectIsGenerated(row.statoProgetto.codice) && !checkIfProjectIsReworking(row.statoProgetto.codice)">
            </button>

            <button
              *ngIf="!checkIfProjectIsDelivered(row.statoProgetto.codice) && checkIfProjectIsGenerated(row.statoProgetto.codice) || checkIfProjectIsReworking(row.statoProgetto.codice)"
              pButton pRipple icon="pi pi-download" class="p-button-rounded p-button-text"
              (click)="openScaricaShape(row)" tooltipPosition="top" pTooltip="Scarica shape files"></button>

            <button
              *ngIf="checkIfProjectIsGenerated(row.statoProgetto.codice) && !checkIfProjectIsDelivered(row.statoProgetto.codice) || checkIfProjectIsReworking(row.statoProgetto.codice)"
              pButton pRipple type="button" icon="pi pi-check-circle" class="p-button-rounded p-button-text"
              (click)="openConsegnaProgettoDialog(row)" tooltipPosition="top" pTooltip="Consegna">
            </button>

            <button
              *ngIf="checkIfProjectIsGenerated(row.statoProgetto.codice) && checkIfProjectIsDelivered(row.statoProgetto.codice) && !checkIfProjectIsReworking(row.statoProgetto.codice)"
              pButton pRipple type="button" icon="pi pi-angle-down" class="p-button-rounded p-button-text"
              (click)="openScaricaBackupProgettoConsegnato(row)" tooltipPosition="top" pTooltip="Scarica Backup">
            </button>

            <button
              *ngIf="checkIfProjectIsGenerated(row.statoProgetto.codice) && checkIfProjectIsDelivered(row.statoProgetto.codice) && !checkIfProjectIsReworking(row.statoProgetto.codice)"
              pButton pRipple type="button" icon="pi pi-angle-double-down" class="p-button-rounded p-button-text"
              (click)="openScaricaProgettoConsegnato(row)" tooltipPosition="top" pTooltip="Scarica Consegna">
            </button>
            

            <!-- && !row.hasExecutive -->
            <button
            *ngIf="checkIfProjectIsGenerated(row.statoProgetto.codice) 
           
            && checkIfProjectIsDelivered(row.statoProgetto.codice) 
            && !checkIfProjectIsReworking(row.statoProgetto.codice)"
              pButton pRipple type="button" icon="pi pi-flag-fill" class="p-button-rounded p-button-text"
              (click)="openGeneraEsecutivoProgettoDialog(row)" 
              tooltipPosition="top" pTooltip="Genera Esecutivo"
              [disabled]="!canGenerateEsecutivi">
            </button>

            <!--isAdmin && -->
            <button
              *ngIf="checkIfProjectIsGenerated(row.statoProgetto.codice) && checkIfProjectIsDelivered(row.statoProgetto.codice) && !checkIfProjectIsReworking(row.statoProgetto.codice)"
              pButton pRipple type="button" icon="pi pi-directions-alt" class="p-button-rounded p-button-text"
              (click)="openAnnullaConsegnaProgettoDialog(row)" tooltipPosition="top" pTooltip="Rework">
            </button>

            <button *ngIf="row.ruolo!='AMMINISTRATORE' 
            && !checkIfProjectIsDelivered(row.statoProgetto.codice) 
            && checkIfProjectIsGenerated(row.statoProgetto.codice)
            && !checkIfProjectIsReworking(row.statoProgetto.codice)" pButton pRipple type="button" icon="pi pi-eraser"
              class="p-button-rounded p-button-text" (click)="openResetProgettoDialog(row)"
              style="color:rgb(214, 163, 24);" tooltipPosition="top" pTooltip="Resetta"
              [disabled]="!checkIfProjectIsGenerated(row.statoProgetto.codice) && !checkIfProjectIsReworking(row.statoProgetto.codice)">
            </button>


            <button
              *ngIf="row.ruolo!='AMMINISTRATORE' && !checkIfProjectIsDelivered(row.statoProgetto.codice) && !checkIfProjectIsGenerated(row.statoProgetto.codice) && !checkIfProjectIsReworking(row.statoProgetto.codice)"
              pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text"
              (click)="openCancellaProgettoDialog(row)" style="color:red;" tooltipPosition="top"
              pTooltip="Cancella Progetto">
            </button>


          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-row>

        <tr *ngIf="filterLabel=='CONSEGNATI' || filter=='all' && row.hasBeenReworked">
          <td colspan="11">
            <div class="p-3">
              <p-table [value]="progettiReworked" dataKey="versione" sortField="versione">
                <ng-template pTemplate="header">
        <tr>

          <!--  <th pSortableColumn="id">Id <p-sortIcon field="price"></p-sortIcon></th> -->
          <th pSortableColumn="versione">Versione <p-sortIcon field="versione"></p-sortIcon></th>
          <th pSortableColumn="dataCreazione">Data creazione <p-sortIcon field="dataCreazione"></p-sortIcon></th>
          <!-- <th pSortableColumn="utenteCreazione">Utente creazione <p-sortIcon field="utenteCreazione"></p-sortIcon></th> -->
          <th pSortableColumn="nomeProgettista">Progettista assegnatario<p-sortIcon
              field="nomeProgettista"></p-sortIcon></th>
          <th pSortableColumn="consegnato">Consegnato</th>
          <th pSortableColumn="note">Note <p-sortIcon field="note"></p-sortIcon></th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rework>
        <tr>
          <td>{{rework.versione}}</td>
          <td>{{rework.dataCreazione | date:'dd/MM/yyyy HH:mm'}}</td>
          <!-- <td>{{rework.utenteCreazione}}</td> -->
          <td>{{rework.nomeProgettista}}</td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <i class="pi pi-check" *ngIf="rework.delivered"></i>
            </div>
          </td>
          <td style="width: 30%;">{{rework.note }}</td>
          <!-- <td><span [class]="'order-badge order-' + order.status.toLowerCase()">{{order.status}}</span></td>
                        <td><p-button type="button" icon="pi pi-search"></p-button></td> -->
        </tr>
      </ng-template>

    </p-table>
  </div>
  </td>
  </tr>
  </ng-template>
  </p-table>

</div>

</div>
<p-toast position="top-center"></p-toast>
<div class="loading-indicator" *ngIf="loadingInProgress">
  <p-progressSpinner></p-progressSpinner>
</div>
<p-toast key="creditiEsauriti" [preventOpenDuplicates]="true" [style]="{width: '30rem'}">
  <ng-template let-message pTemplate="message">
    <div class="flex align-items-center justify-content-between flex-1 align-self-center mr-3">
        <span class="text-xl font-medium text-orange-900">{{message.detail}}</span>
        <!-- <a class="cursor-pointer font-medium text-orange-700">Reply</a> -->
    </div>
</ng-template>
  <!-- <ng-template let-message pTemplate="message">
      <div class="flex flex-column bg-gray-900">
          <div class="p-3">
              <div class="flex align-items-center">
                  <span class="flex align-items-center justify-content-center bg-blue-400 text-blue-900 mr-3 border-circle" style="width:32px;height:32px">
                      <i class="pi pi-info text-lg"></i>
                  </span>
                  <span class="font-medium text-2xl text-white">{{message.summary}}</span>
              </div>
              <p class="my-3 line-height-3 text-gray-300">{{message.detail}}</p>
          </div>
           <div style="height:6px" class="bg-gray-700">
              <div class="h-full bg-blue-500 animate-width animation-duration-3000"></div>
          </div>
      </div>
  </ng-template> -->
</p-toast>
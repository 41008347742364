<div>
   <!--  <p class="line-height-3 p-0 m-0"> Confermi l'annullamento della consegna del progetto {{config.data.progetto.codice}}? </p> -->
    <p class="line-height-3 p-1 m-1">Indica il progettista a cui assegnare il rework del progetto:</p>
    
    <p-dropdown class="line-height-3 p-1 m-1" [options]="utenti" [(ngModel)]="selectedUser" optionLabel="name"></p-dropdown>

    <p class="line-height-3 p-1 m-1">Note per il rework:</p>
    <textarea pInputTextarea class="p-1 m-1" [rows]="7" style="width: 100%;" [autoResize]="true" [(ngModel)]="reworkNotes"
    placeholder="Indica qui le note"></textarea>
</div>
<div class="margin-top-1 border-top-1 surface-border">
    <div class="buttons-div">
        <button pButton pRipple icon="pi pi-check" (click)="conferma(true)" label="Conferma" class="p-button-success w-5 mr-2" [disabled]="reworkNotes.length==0" ></button>
        <button pButton pRipple icon="pi pi-times" (click)="conferma(false)" label="Annulla" class="p-button-warning w-5 ml-2" ></button>
    </div>
</div>

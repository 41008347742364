import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import {NGXLogger} from 'ngx-logger';
import { Tenant } from '../models/tenant.models';


@Injectable({
  providedIn: 'root',
})
export class TenantsService{
  apiUrl: string = `${environment.apiUrl}/view`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}

  get(): Observable<any> {
    this.apiUrl =environment.apiUrl+"/tenants";
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };
    
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions);
  }

  getTenant(tenantId:string): Observable<any> {
    this.apiUrl =environment.apiUrl+"/tenants/"+tenantId;
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };
    
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions);
  }

  checkPreliminari(tenantId:string): Observable<any> {
    this.apiUrl =environment.apiUrl+"/tenants/"+tenantId+"/checkPreliminari";
    //const token = localStorage.getItem("BEtoken");
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };
    
    
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions);
  }

  checkEsecutivi(tenantId:string): Observable<any> {
    this.apiUrl =environment.apiUrl+"/tenants/"+tenantId+"/checkEsecutivi";
    //const token = localStorage.getItem("BEtoken");
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };
    
    
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions);
  }

  crea(tenantName:string): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/tenants`;
    
    const tenant:Tenant = new Tenant();
   
    tenant.name=tenantName.toUpperCase();
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, tenant, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('generaTenant', []))
    );
  }

  aggiornaCrediti(tenantId:number, creditiDaAggiungere:string): Observable<any> {
    this.apiUrl =`${environment.apiUrl}/tenants/`+tenantId+"/aggiornaCrediti";
    
    const tenant:Tenant = new Tenant();
   
    tenant.crediti=Number(creditiDaAggiungere);
    
    //const token = localStorage.getItem("BEtoken");
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, tenant, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('aggiornaCrediti', []))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError = <T>(operation = 'operation', result?: T) => {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      this.logger.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.info(`${operation} failed: ${error.message}`);

      /*this.errorHandler.dialogConfig = {...this.dialogConfig};
      this.errorHandler.handleError(error);*/



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  };
}

<div>
    <p class="line-height-3 p-0 m-0">
        Si sta per generare il sinottico per il progetto {{config.data.progetto.codice}}.
       </p>
    <p class="mt-1">Il processo potrebbe richiedere alcuni secondi. </p>
</div>
<div class="margin-top-1 border-top-1 surface-border pt-3">
    <div class="create-project-buttons-div">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma"  styleClass="p-button-success" ></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" styleClass="p-button-warning" ></p-button>
    </div>
</div>

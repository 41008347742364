import {Injectable} from '@angular/core';
import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MyMissingTranslationHandler implements MissingTranslationHandler {

  snackBarConfig;
  actionButtonLabel = 'x';
  setAutoHide = true;
  autoHide = 5000;

  constructor() {
  }

  handle(params: MissingTranslationHandlerParams): any {
    const messageError = `Manca la traduzione: ${params.key}`;
    return '[MANCA]: ' + params.key;
  }
}


<div >
  <p class="line-height-3 p-0 m-0 carica-progetto-p">
   Seleziona file da caricare o trascinalo nell'area sottostante
  </p>
  <p-fileUpload name="certificato"
  multiple="false"
  accept=".csv" maxFileSize="5000000" 
  chooseLabel="Cerca" uploadLabel="Carica" cancelLabel="Cancella"
  [auto]="true"
  [customUpload]="true"
  (uploadHandler)="onFileUpload($event)"
  [showUploadButton]="false"
  [showCancelButton]="false"
  >

</p-fileUpload>
<!-- <ng-template let-file pTemplate="file">
   
     <div class="ui-fileupload-row">
            <div>{{file.name}}</div>
            <div>{{getSizeInMegaBytes(file) | number:'1.2-2'}} MB</div>
            <div>
              <button type="button" icon="pi pi-times" pButton (click)="remove($event, i)" [disabled]="uploading" [class]="removeStyleClass"></button>
            </div>
        </div> 
 
</ng-template> -->
<div  class="margin-top-1 border-top-1 surface-border pt-3">
  <div class="upload-file-div">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text" styleClass="p-button-success" [disabled]="!isFileUploaded"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" styleClass="p-button-warning"></p-button>
  </div>
</div>
</div>


<div class="surface-section  ">
    <div class="p-6 shadow-2 flex flex-column md:flex-row align-items-center justify-content-between" style="border-radius: 1rem; background: linear-gradient(0deg, rgba(0, 123, 255, 0.5), rgba(0, 123, 255, 0.5)), linear-gradient(92.54deg, #1C80CF 47.88%, #FFFFFF 100.01%)">
        <div class="pr-6">
            <div class="text-white font-medium" style="font-size: 1.3rem;">Guarda i video tutorial sul nostro canale youtube</div>
        </div>
        <div class="mt-4 mr-auto md:mt-0 md:mr-0">
            <button pButton pRipple (click)="downloadDoc()" 
            class="font-bold px-5 py-3 p-button-warning p-button-rounded p-button-raised white-space-nowrap">
            <i class="pi pi-youtube" style="font-size: 2rem"></i>
        </button>
        </div>
    </div>
</div>
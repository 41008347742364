
<div >
   
      <p-calendar class="calendar-div" [inline]="true" [showWeek]="true" [(ngModel)]="dataConsegna" ></p-calendar>
      <div >   
  
    <div class="buttons-div">
      <button pButton pRipple icon="pi pi-check" (click)="conferma(true)"  label="Conferma" class="p-button-success w-5 mr-2" [disabled]="!dataConsegna"></button>
      <button pButton pRipple icon="pi pi-times" (click)="conferma(false)" label="Annulla"  class="p-button-warning w-5 ml-2"></button>
    </div>



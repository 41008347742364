<div class="container" *ngIf="auth.isLoading$ | async; else loaded">
    <app-loading></app-loading>
  </div>
  <ng-template #loaded>
    <app-home-content *ngIf="auth.user$ | async as user; else elseBlock">
      <stats *ngIf="!isSuperAdmin()"></stats>
    </app-home-content>
    <ng-template #elseBlock>
      <div class="split left">
        <img src="../../../assets/images/vertical/BG fibergis.png" style="width:100%"/>
      </div>
      <div class="split right">
        
        <div class="titolo surface-card p-4 shadow-2 border-round" style="width: 75%; margin-left: 10%;">
        
          <h1>BENVENUTO IN <b>FIBER GIS!</b></h1>
          <p>Registrati o autenticati per accedere</p>
          <button pButton pRipple type="button" label="ENTRA" class="p-button-info login" (click)="loginWithRedirect()"></button>
      
        </div>
        <div class="logoPowered">
          <img src="../../../assets/images/vertical/Logo vertical _ powered by.png" style="width:50%"/>
        
        </div>
    </div>
     <!--  <div class="titolo ">Autenticati per accedere all'applicazione</div>
      <button pButton pRipple type="button" label="LOGIN" class="p-button-info login" (click)="loginWithRedirect()"></button> -->
    </ng-template>
  </ng-template>
  
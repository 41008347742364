import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { map, Observable, Subject, switchMap, tap } from 'rxjs';

import { AuthService, User } from '@auth0/auth0-angular';
import { Utente } from '../../models/utente.model';
import {
  ConfirmationService,
  MenuItem,
  Message,
  MessageService,
} from 'primeng/api';
import { UtentiService } from 'src/app/services/utenti.service';
import { TokenAuth0 } from 'src/app/models/tokenAuth0.model';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ProgettiService } from 'src/app/services/progetti.service';
import { Progetto } from 'src/app/models/progetto.model';
import { UtenteDTO } from 'src/app/models/utente.dto.model';
import { DialogCaricaProgetti } from 'src/app/components/navbar/navbar.component';
import { CaricamentoProgettiResult } from 'src/app/models/caricamentoProgetti.result.models';
import { getStorage, ref, getBlob, deleteObject } from 'firebase/storage';
import { saveAs } from 'file-saver';
import { ReportService } from 'src/app/services/report.service';
import { SinotticoService } from 'src/app/services/sinottico.service';
import { EtichetteService } from 'src/app/services/etichette.service';
import { ProjectsReloadService } from '../../services/projects-reload.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from 'express';
import { ProgettiEsecutiviService } from 'src/app/services/progetti-esecutivi.service';
import { ProgettoEsecutivo } from 'src/app/models/progettoEsecutivo.model';
import { ProgettoEsecutivoReworked } from 'src/app/models/progettoEsecutivoReworked.models';
import { environment } from '../../../environments/environment';
import { ApplicationVersionsService } from 'src/app/services/application-versions.service';
import { DialogChangeLog } from 'src/app/app.component';
import { DialogEsportaShapeProgetto } from '../progetti/progetti.component';
interface Report {
  name: string;
  code: string;
}

interface Etichetta {
  name: string;
  code: string;
}

@Component({
  selector: 'progetti-esecutivi',
  templateUrl: './progetti-esecutivi.component.html',
  styleUrls: ['./progetti-esecutivi.component.css'],
  providers: [DialogService, ConfirmationService],
})
export class ProgettiEsecutiviComponent implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  dialogConfig;
  auth0Token: TokenAuth0;
  isAdmin: boolean = false;
  isCoordinatore: boolean = false;
  msgs: Message[] = [];
  displayModificaRuolo = false;
  ref: DynamicDialogRef;
  progetti: Progetto[] = [];
  selectedProgetto: Progetto;
  risultatoCaricamentoProgetti: CaricamentoProgettiResult | null;
  loadingInProgress: boolean = false;
  reportsList: MenuItem[];
  messageDuration: number = environment.progettiMessageDuration;
  message: string = '';
  filter: string = 'all';
  filterLabel: string = '';
  applicationVersion:string='';
  storageDir:string = environment.storageDir;

  constructor(
    private logger: NGXLogger,
    private utentiService: UtentiService,
    private _httpClient: HttpClient,
    public auth: AuthService,
    public progettiEsecutiviService: ProgettiEsecutiviService,
    public reportService: ReportService,
    public sinotticoService: SinotticoService,
    public etichetteService: EtichetteService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public projectsReloadService: ProjectsReloadService,
    private activatedRoute: ActivatedRoute,
    private applicationVersionService:ApplicationVersionsService,

  ) {
    this.dialogConfig = {
      height: '600px',
      width: '600px',
      disableClose: true,
      data: {},
    };
  }

  openChangeLogDialog(){
    localStorage.setItem('currentAppVersion', this.applicationVersion);
      this.ref = this.dialogService.open(DialogChangeLog, {
        data: {
      },
        header: 'Novità della versione '+this.applicationVersion,
        width: '95%',
        height: '90%',
        contentStyle: { "overflow": "true"},
        baseZIndex: 10000,
        closable:true,
        draggable:false,
        closeOnEscape:true,
    }); 
  }

  checkApplicationVersion(){
    this.applicationVersionService.getCurrentVersion().subscribe(result => {
      this.applicationVersion = result.version;
      if(localStorage.getItem("currentAppVersion")== 'undefined' || localStorage.getItem("currentAppVersion")== null){
        this.openChangeLogDialog();
    }else{
      if(localStorage.getItem('currentAppVersion')!= result.version){
        this.openChangeLogDialog();
      }
      
  }
    },
    error => {
      console.error('ERRORE get version' + error);
    })
}

  ngOnInit(): void {
   this.checkApplicationVersion();
    if (
      this.activatedRoute.snapshot.params['filter'] != null &&
      this.activatedRoute.snapshot.params['filter'] != 'all'
    ) {
      this.filter = this.activatedRoute.snapshot.params['filter'];
      if(this.activatedRoute.snapshot.params['filter']!="rework"){
        this.filterLabel =  this.filter.toUpperCase().slice(0, this.filter.length - 1) + 'I';
      }else {
        this.filterLabel = " IN REWORK";
        this.filter="rework";
      }

      }
     

   // this.logger.info('>>> valore filtro al caricamento: ' + this.filterLabel);

    this.loadReportsList();
    this.isAdmin        = localStorage.getItem('ruolo') === 'AMMINISTRATORE';
    this.isCoordinatore = localStorage.getItem('ruolo') === 'COORDINATORE';
    //localStorage.getItem('userId');
    this.loadData();
    this.projectsReloadService.reloadCurrentlyNecessary.subscribe(
      (isNecessary) => {
        if (isNecessary) {
          this.loadData();
        }
      }
    );
  }

  ngAfterViewInit() {}

  loadReportsList() {
    this.reportsList = [
      {
        icon: 'pi pi-pencil',
        command: () => {
          this.messageService.clear();
          this.messageService.add({
            key: 'tc',
            life: this.messageDuration,
            severity: 'info',
            summary: 'Add',
            detail: 'Data Added',
          });
        },
      },
    ];
  }

  loadDataRemovingFilter() {
    this.logger.info('--> loadDataRemovingFilter prima ' + this.filter);
    this.filter = 'all';
    this.filterLabel = '';
    this.loadData();
    this.logger.info('--> loadDataRemovingFilter dopo ' + this.filter);
  }

  loadData() {
   //this.logger.info("TENANT "+  localStorage.getItem("tenantId") +" & user "+ localStorage.getItem('userId'));
   //if(!localStorage.getItem("tenantId") && !localStorage.getItem('userId')){
    //this.logger.info("DOVREI CHIAMARE !");
    this.isLoadingResults = true;
   // this.logger.info('---- LOAD DATA w FILTER: ' + this.filter);
    this.progettiEsecutiviService
      .progetti(this.filter != 'all' ? this.filter : null)
      .pipe(
        map((data) => {
          this.logger.info('---- LOAD DATA RESULTs: ' + data);
          this.resultsLength = data.length;
          let cleanProgettiList: ProgettoEsecutivo[] = [];

          data.forEach((d) => {
            d.dataCaricamento = new Date(d.dataCaricamento);
            if (d.dataModifica !== null) {
              d.dataModifica = new Date(d.dataModifica);
            }
            var currentDate: any = new Date();
            var startDate: any = new Date(currentDate.getFullYear(), 0, 1);
            var days = Math.floor(
              (currentDate - startDate) / (24 * 60 * 60 * 1000)
            );
            var weekNumber = Math.ceil(days / 7);
            if (d.settimanaConsegna != null) {
              if (weekNumber > d.settimanaConsegna) {
                d.consegnaScaduta = 'true';
              } else if (d.settimanaConsegna - weekNumber <= 2) {
                d.consegnaScaduta = 'almost';
              } else {
                d.consegnaScaduta = 'false';
              }
            } else {
              d.consegnaScaduta = 'none';
            }
            if(d.progettiEsecutiviReworked){
            let reworks:any[]=[];
            reworks = d.progettiEsecutiviReworked;
            this.logger.info('----> PROGETTO ' + d.codice + ' ha storico reworked: '+ reworks.length);
            
             d.hasBeenReworked = false;
            if(reworks.length){
              d.hasBeenReworked = true;
            } 
            }
          
            cleanProgettiList.push(d);
          });

          return cleanProgettiList;
        })
      )
      .subscribe((res: any) => {
        this.progetti = res;

        this.isLoadingResults = false;
      });

      this.logger.info("---> FILTERLABEL:"+ this.filterLabel);
      this.logger.info("---> FILTER: "+ this.filter);
      
   // }else{
      //this.router.navigate(['/']);
   // }
  }

  checkIfProjectIsAssigned(stato: String): boolean {
    return stato == 'assegnato' || stato == 'non assegnato' ? true : false;
  }
  checkIfProjectIsGenerated(stato: String): boolean {
    return stato == 'generato' ||
      stato == 'in lavorazione' ||
      stato == 'sospeso' ||
      stato == 'terminato' ||
      stato == 'consegnato'
      ? true
      : false;
  }
  checkIfProjectIsDelivered(stato: String): boolean {
    if (stato == 'consegnato') {
      return true;
    } else {
      return false;
    }
  }

  checkIfProjectIsReworking(stato: String): boolean {
    if (stato == 'rework') {
      return true;
    } else {
      return false;
    }
  } 


  openAnnullaConsegnaProgettoDialog(progetto: ProgettoEsecutivo) {
    this.logger.info('---open Rework Progetto :' + progetto.codice);
     this.ref = this.dialogService.open(DialogAnnullaConsegnaProgettoEsecutivo, {
      data: {
        progetto: progetto,
        tenant: progetto.tenant.name,
      },
      header: 'Rework Progetto Esecutivo '+progetto.codice,
      width: '40%',
      height: '60%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });

    this.ref.onClose.subscribe((confirmationResult) => {
      if (confirmationResult.success) {
        this.loadingInProgress = true;
        this.logger.info(confirmationResult);
        this.progettiEsecutiviService
          .reworkProgetto(
            confirmationResult.user.user_id.substring(
              confirmationResult.user.user_id.indexOf('|') + 1,
              confirmationResult.user.user_id.length
            ),
            confirmationResult.user.name,
            progetto.id,
            confirmationResult.note
          )
          .subscribe((result) => {
            this.loadingInProgress = false;
            this.messageService.clear();
            this.messageService.add({
              key: 'tc',
              life: this.messageDuration,
              severity: 'success',
              summary: '',
              detail:
                'Il progetto ' +
                progetto.codice +
                ' è ora in rework!',
            });
            this.loadData();
          });  
      }
    }); 
  }

  openConsegnaProgettoDialog(progetto: ProgettoEsecutivo) {
    this.logger.info('---openConsegnaProgetto :' + progetto.codice);
     this.ref = this.dialogService.open(DialogConsegnaProgettoEsecutivo, {
      data: {
        progetto: progetto,
        tenant: progetto.tenant.name,
      },
      header: 'Consegna Progetto Esecutivo '+progetto.codice,
      width: '50%',
      height: '55%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });

    this.ref.onClose.subscribe((confirmationResult) => {
      if (confirmationResult.success) {
        console.log("FILE PASSATO > " +confirmationResult.file);
        this.loadingInProgress = true;
        this.progettiEsecutiviService
          .consegnaProgetto(
            progetto.idProgettista,
            progetto.tenant.name,
            progetto.id,
            confirmationResult.file
          )
          .subscribe((result) => {
            this.loadingInProgress = false;
            this.messageService.clear();
            this.messageService.add({
              key: 'tc',
              life: this.messageDuration,
              severity: 'success',
              summary: '',
              detail:
                'Progetto ' + progetto.codice + ' consegnato con successo!',
            });
            this.loadData();
          });
      }
    }); 
  }


  async openScaricaBackupProgettoConsegnato(progetto: ProgettoEsecutivo) {
    this.loadingInProgress = true;

    //CONTROLLA SE HA GIA' IL BACKUP!
    if(progetto.hasBackup){ // LO SCARICO DIRETTAMENTE
      const storage = getStorage();
      const pathReference = ref(
        storage,
       //'test/' +
        this.storageDir +"/"+
          progetto.tenant.name.toLowerCase() +
          '/backups/' +
          progetto.codice.toLowerCase() + "_esecutivo"+
          '.zip'
      );
      const blob = await getBlob(pathReference);
      saveAs(blob, progetto.codice+ "_esecutivo" + '.zip');
      this.loadingInProgress = false;
    }else { // LO GENERO E POI LO SCARICO
      this.progettiEsecutiviService
            .generaBackup(progetto.id)
            .subscribe(async (res) => {
              this.loadingInProgress = false;
              const storage = getStorage();
              const pathReference = ref(
                storage,
                //'test/' +
                this.storageDir +"/"+
                  progetto.tenant.name.toLowerCase() +
                  '/backups/' +
                  progetto.codice.toLowerCase() + "_esecutivo"+
                  '.zip'
              );
              const blob = await getBlob(pathReference);
              saveAs(blob, progetto.codice+ "_esecutivo" + '.zip');
              this.loadData();
            });
    }
   
  }

  async openScaricaProgettoConsegnato(progetto: ProgettoEsecutivo) {
    this.logger.info("---> Scarica Progetto ESECUTIVO Consegnato "+ progetto.codice );
    this.loadingInProgress = true;
    const storage = getStorage();
    const pathReference = ref(
      storage,
      //'test/' +
      this.storageDir +"/"+
        progetto.tenant.name.toLowerCase() +
        '/delivered/' +
        progetto.codice +
        '_esecutivo.zip'
    );
    const blob = await getBlob(pathReference)
    .catch(error => {
      this.logger.info(">>>> ERRORE SCARICAMENTO FILE");
      //this.logger.error(error);
     // saveAs(blob, progetto.codice + '.zip');
     this.messageService.add({
      key: 'tc',
      life: this.messageDuration,
      severity: 'warn',
      summary: '',
      detail:
        'File di progetto assente'});
      this.loadingInProgress = false;
    });
    saveAs(blob, progetto.codice + '_esecutivo.zip');
    this.loadingInProgress = false;
    
  }

  async openScaricaProgetto(progetto: ProgettoEsecutivo) {
    this.loadingInProgress = true;
    const storage = getStorage();
    const pathReference = ref(
      storage,
      //'test/' +
      this.storageDir +"/"+
        progetto.tenant.name.toLowerCase() +
        '/' +
        progetto.codice.toLowerCase()+"_esecutivo" +
        '.qgs'
    );
    const blob = await getBlob(pathReference);

    saveAs(blob, progetto.codice.toLowerCase()+"_esecutivo.qgs");
    this.loadingInProgress = false;
  }

  async openScaricaShape(progetto: ProgettoEsecutivo) {
    this.ref = this.dialogService.open(DialogEsportaShapeProgettoEsecutivo, {
      data: {
        progetto: progetto,
        tenant: progetto.tenant.name,
      },
      header: 'Esporta shape progetto esecutivo ' + progetto.codice,
      width: '40%',
      height: '55%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((esportaResult) => {
      console.log(esportaResult);
      if (esportaResult.success) {
        if (
          esportaResult.selectedExport.code === 'tim' ||
          esportaResult.selectedExport.code === 'permessi'
        ) {
          this.loadingInProgress = true;

          this.progettiEsecutiviService
            .checkShape(progetto.id.toString())
            .subscribe((couldProduceShape) => {
              if (couldProduceShape) {
                this.progettiEsecutiviService
                  .generaShape(progetto.id, esportaResult.selectedExport.code)
                  .subscribe(async (res) => {
                    this.loadingInProgress = false;
                    const storage = getStorage();
                    const pathReference = ref(
                      storage,this.storageDir +"/" + progetto.tenant.name.toLowerCase() + '/' + progetto.codice.toLowerCase()+ '_esecutivo.zip'
                    );
                    const blob = await getBlob(pathReference);
                    if(esportaResult.selectedExport.code === 'tim'){
                      saveAs(blob, progetto.codice.toLowerCase() + '_esecutivo_TIM.zip');
                    }else{
                      saveAs(blob, progetto.codice.toLowerCase() + '_esecutivo_permessi.zip');
                    }
                    
                  });
              } else {
                this.loadingInProgress = false;
                this.messageService.clear();
                this.messageService.add({
                  key: 'tc',
                  life: this.messageDuration,
                  severity: 'info',
                  summary: 'Errore',
                  detail:
                    'Impossibile generare gli shape files per il progetto ' +
                    progetto.codice,
                });
              }
            });
        }
      }
    });

  /*
   this.loadingInProgress = true;

    this.progettiEsecutiviService
      .checkShape(progetto.id.toString())
      .subscribe((couldProduceShape) => {
        if (couldProduceShape) {
          this.progettiEsecutiviService
            .generaShape(progetto.id)
            .subscribe(async (res) => {
              this.loadingInProgress = false;
              const storage = getStorage();

              const pathReference = ref(
                storage,this.storageDir +"/" + progetto.tenant.name.toLowerCase() + '/' + progetto.codice.toLowerCase()+ '_esecutivo.zip'
              );
              const blob = await getBlob(pathReference);
              saveAs(blob, progetto.codice + '_esecutivo.zip');
            });
        } else {
          this.loadingInProgress = false;
          this.messageService.clear();
          this.messageService.add({
            key: 'tc',
            life: this.messageDuration,
            severity: 'info',
            summary: 'Errore',
            detail:
              'Impossibile generare gli shape files per il progetto ' +
              progetto.codice,
          });
        }
      }); 
      */
  }

  openCaricaProgetti() {
    console.log('> openCaricaProgetti');
    /* this.ref = this.dialogService.open(DialogCaricaProgetti, {
      data: {
        //     utente: utente,
        //     token: this.auth0Token
      },
      header: 'Carica Progetti',
      width: '70%',
      height: '50%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        this.risultatoCaricamentoProgetti = uploadResult.risultato;

        this.messageService.clear();
        this.messageService.add({
          key: 'tc',
          life: this.messageDuration,
          severity: 'success',
          summary: '',
          detail:
            'Dei ' +
            this.risultatoCaricamentoProgetti.numeroProgettiPresentiNelFile +
            ' progetti presenti nel file, ne sono stati correttamente caricati ' +
            this.risultatoCaricamentoProgetti.numeroProgettiCaricati,
        });
        this.loadData();
      }
    }); */
  }

  

  openAssegnaProgettoDialog(progetto: ProgettoEsecutivo) {
     this.ref = this.dialogService.open(DialogAssegnaProgettoEsecutivo, {
      data: {
        progetto: progetto,
      },
      header: 'Assegna Progetto Esecutivo ' + progetto.codice,
      width: '70%',
      height: '80%',
      contentStyle: { 'max-height': '800px', overflow: 'auto' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((assegnazioneResult) => {
      this.loadData();
      this.messageService.clear();
      this.messageService.add({
        key: 'tc',
        life: this.messageDuration,
        severity: 'success',
        summary: '',
        detail:
          'Progetto ' +
          progetto.codice +
          " assegnato con successo all'utente " +
          assegnazioneResult.utente.name,
      });
    }); 
  }

  openProduciReportDialog(progetto: ProgettoEsecutivo) {
    console.log(progetto.tenant.name);
     this.ref = this.dialogService.open(DialogGeneraReportEsecutivo, {
      data: {
        progetto: progetto,
        tenant: progetto.tenant.name,
      },
      header: 'Genera Report per il progetto ' + progetto.codice,
      width: '40%',
      height: '55%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });

    this.ref.onClose.subscribe((reportCreationResult) => {
      if (reportCreationResult.success) {
        this.loadingInProgress = true;
        let numeroReportDaProdurre = reportCreationResult.selectedReports.length;
        if (reportCreationResult.selectedReports.length > 1) {
          this.logger.info('MOLTI REPORT!!!');
          let numeroReportGeneratiCorrettamente = 0;
          let numeroReportProcessati = 0;
          reportCreationResult.selectedReports.forEach((report) => {
            this.logger.info('> DEVEO PRODURRE ' + report.code + " PER ESECUTIVO");
            if (report.code == 'fo') {
              this.reportService
                .modelloFOEsecutivo(progetto.codice)
                .subscribe((response) => {
                  numeroReportProcessati = numeroReportProcessati + 1;
                  if (response.status === 204) {
                    this.messageService.add({
                      key: 'tc',
                      life: this.messageDuration,
                      severity: 'warn',
                      summary: '',
                      detail:
                        'Report FO per il progetto ' +
                        progetto.codice +
                        ' non generato per mancanza di dati',
                    });
                  } else {
                    const contentDisposition = response.headers.get(
                      'Content-Disposition'
                    );
                    let fileName = 'CodCentr_NArm_Modello AI FO.xlsx';

                    const blob = new Blob([response.body], {
                      type: 'application/vnd.ms-excel',
                    });
                    saveAs(blob, fileName);
                    numeroReportGeneratiCorrettamente =
                      numeroReportGeneratiCorrettamente + 1;
                    this.logger.info(
                      '>> REPORT FO GENERATO > IN TUTTO SONO ' +
                        numeroReportGeneratiCorrettamente
                    );
                  }
                  if (numeroReportProcessati == numeroReportDaProdurre) {
                    this.loadingInProgress = false;
                    this.messageService.add({
                      key: 'tc',
                      life: this.messageDuration,
                      severity: 'success',
                      summary: '',
                      detail:
                        numeroReportGeneratiCorrettamente +
                        ' dei ' +
                        numeroReportDaProdurre +
                        ' report richiesti sono stati generati con successo per il progetto ' +
                        progetto.codice,
                    });
                  }
                });
            } else if (report.code == 'rame') {
              this.reportService
                .modelloRameEsecutivo(progetto.codice)
                .subscribe((response) => {
                  numeroReportProcessati = numeroReportProcessati + 1;
                  if (response.status === 204) {
                    this.messageService.add({
                      key: 'tc',
                      life: this.messageDuration,
                      severity: 'warn',
                      summary: '',
                      detail:
                        'Report RAME per il progetto ' +
                        progetto.codice +
                        ' non generato per mancanza di dati',
                    });
                  } else {
                    let fileName = 'CodCentr_NArm_Modello AI Rame_Unica.xlsx';
                    const contentDisposition = response.headers.get(
                      'Content-Disposition'
                    );

                    const blob = new Blob([response.body], {
                      type: 'application/vnd.ms-excel',
                    });
                    saveAs(blob, fileName);
                    numeroReportGeneratiCorrettamente =
                      numeroReportGeneratiCorrettamente + 1;
                    this.logger.info(
                      '>> REPORT RAME GENERATO > IN TUTTO SONO ' +
                        numeroReportGeneratiCorrettamente
                    );
                  }
                  if (numeroReportProcessati == numeroReportDaProdurre) {
                    this.loadingInProgress = false;
                    this.messageService.add({
                      key: 'tc',
                      life: this.messageDuration,
                      severity: 'success',
                      summary: '',
                      detail:
                        numeroReportGeneratiCorrettamente +
                        ' dei ' +
                        numeroReportDaProdurre +
                        ' report richiesti sono stati generati con successo per il progetto ' +
                        progetto.codice,
                    });
                  }
                });
            } else if (report.code == 'associazioneBoxQuartine') {
              this.reportService
                .associazioneBoxQuartineEsecutivo(progetto.codice)
                .subscribe((response) => {
                  numeroReportProcessati = numeroReportProcessati + 1;
                  if (response.status === 204) {
                    this.messageService.add({
                      key: 'tc',
                      life: this.messageDuration,
                      severity: 'warn',
                      summary: '',
                      detail:
                        'Report BOX QUARTINE per il progetto ' +
                        progetto.codice +
                        ' non generato per mancanza di dati',
                    });
                  } else {
                    let fileName =
                      'CodCentr_NArm_Associazione Box_Quartine.xlsx';
                    const blob = new Blob([response.body], {
                      type: 'application/vnd.ms-excel',
                    });
                    saveAs(blob, fileName);
                    numeroReportGeneratiCorrettamente =
                      numeroReportGeneratiCorrettamente + 1;
                    this.logger.info(
                      '>> REPORT BOX  GENERATO > IN TUTTO SONO ' +
                        numeroReportGeneratiCorrettamente
                    );
                  }
                  if (numeroReportProcessati == numeroReportDaProdurre) {
                    this.loadingInProgress = false;
                    this.messageService.add({
                      key: 'tc',
                      life: this.messageDuration,
                      severity: 'success',
                      summary: '',
                      detail:
                        numeroReportGeneratiCorrettamente +
                        ' dei ' +
                        numeroReportDaProdurre +
                        ' report richiesti sono stati generati con successo per il progetto ' +
                        progetto.codice,
                    });
                  }
                });
            } else if (report.code == 'preventivo') {
              this.reportService
                .preventivoEsecutivo(progetto.codice)
                .subscribe((response) => {
                  numeroReportProcessati = numeroReportProcessati + 1;
                  if (response.status === 204) {
                    this.messageService.add({
                      key: 'tc',
                      life: this.messageDuration,
                      severity: 'warn',
                      summary: '',
                      detail:
                        'Report PREVENTIVO per il progetto ' +
                        progetto.codice +
                        ' non generato per mancanza di dati',
                    });
                  } else {
                    let fileName = 'CodCentr_NArm_Preventivo.xlsx';

                    const blob = new Blob([response.body], {
                      type: 'application/vnd.ms-excel',
                    });
                    saveAs(blob, fileName);
                    numeroReportGeneratiCorrettamente =
                      numeroReportGeneratiCorrettamente + 1;
                    this.logger.info(
                      '>> PREVENTIVO GENERATO > IN TUTTO SONO ' +
                        numeroReportGeneratiCorrettamente
                    );
                  }
                  if (numeroReportProcessati == numeroReportDaProdurre) {
                    this.loadingInProgress = false;
                    this.messageService.add({
                      key: 'tc',
                      life: this.messageDuration,
                      severity: 'success',
                      summary: '',
                      detail:
                        numeroReportGeneratiCorrettamente +
                        ' dei ' +
                        numeroReportDaProdurre +
                        ' report richiesti sono stati generati con successo per il progetto ' +
                        progetto.codice,
                    });
                  }
                });
            }
          });
        } else {
          let selectedReport = reportCreationResult.selectedReports[0];
          if (selectedReport.code == 'fo') {
            this.reportService
              .modelloFOEsecutivo(progetto.codice)
              .subscribe((response) => {
                this.loadingInProgress = false;
                this.logger.info('>> REPORT FO GENERATO');
                if (response.status === 204) {
                  this.messageService.clear();
                  this.messageService.add({
                    key: 'tc',
                    life: this.messageDuration,
                    severity: 'warn',
                    summary: '',
                    detail:
                      'Report FO per il progetto ' +
                      progetto.codice +
                      ' non generato per mancanza di dati',
                  });
                } else {
                  this.messageService.clear();
                  this.messageService.add({
                    key: 'tc',
                    life: this.messageDuration,
                    severity: 'success',
                    summary: '',
                    detail:
                      'Report FO generato con successo per il progetto ' +
                      progetto.codice,
                  });
                  const contentDisposition = response.headers.get(
                    'Content-Disposition'
                  );
                  let fileName = 'CodCentr_NArm_Modello AI FO.xlsx';

                  const blob = new Blob([response.body], {
                    type: 'application/vnd.ms-excel',
                  });
                  saveAs(blob, fileName);
                }
              });
          } else if (selectedReport.code == 'rame') {
            this.reportService
              .modelloRameEsecutivo(progetto.codice)
              .subscribe((response) => {
                this.loadingInProgress = false;
                this.logger.info('>> REPORT RAME GENERATO');
                this.logger.info(response);
                if (response.status === 204) {
                  this.messageService.clear();
                  this.messageService.add({
                    key: 'tc',
                    life: this.messageDuration,
                    severity: 'warn',
                    summary: '',
                    detail:
                      'Report RAME per il progetto ' +
                      progetto.codice +
                      ' non generato per mancanza di dati',
                  });
                } else {
                  this.messageService.clear();
                  this.messageService.add({
                    key: 'tc',
                    life: this.messageDuration,
                    severity: 'success',
                    summary: '',
                    detail:
                      'Report RAME generato con successo per il progetto ' +
                      progetto.codice,
                  });
                  let fileName = 'CodCentr_NArm_Modello AI Rame_Unica.xlsx';
                  const contentDisposition = response.headers.get(
                    'Content-Disposition'
                  );

                  const blob = new Blob([response.body], {
                    type: 'application/vnd.ms-excel',
                  });
                  saveAs(blob, fileName);
                }
              });
          } else if (selectedReport.code == 'associazioneBoxQuartine') {
            this.reportService
              .associazioneBoxQuartineEsecutivo(progetto.codice)
              .subscribe((response) => {
                this.logger.info('>> REPORT BOX GENERATO');
                this.logger.info(response);
                this.loadingInProgress = false;
                if (response.status === 204) {
                  this.messageService.clear();
                  this.messageService.add({
                    key: 'tc',
                    life: this.messageDuration,
                    severity: 'warn',
                    summary: '',
                    detail:
                      'Report BOX QUARTINE per il progetto ' +
                      progetto.codice +
                      ' non generato per mancanza di dati',
                  });
                } else {
                  this.messageService.clear();
                  this.messageService.add({
                    key: 'tc',
                    life: this.messageDuration,
                    severity: 'success',
                    summary: '',
                    detail:
                      'Report ASSOCIAZIONE BOX QUARTINE generato con successo per il progetto ' +
                      progetto.codice,
                  });
                  let fileName = 'CodCentr_NArm_Associazione Box_Quartine.xlsx';

                  const blob = new Blob([response.body], {
                    type: 'application/vnd.ms-excel',
                  });
                  saveAs(blob, fileName);
                }
              });
          } else if (selectedReport.code == 'preventivo') {
            this.reportService
              .preventivoEsecutivo(progetto.codice)
              .subscribe((response) => {
                this.loadingInProgress = false;
                if (response.status === 204) {
                  this.messageService.clear();
                  this.messageService.add({
                    key: 'tc',
                    life: this.messageDuration,
                    severity: 'warn',
                    summary: '',
                    detail:
                      'Report PREVENTIVO per il progetto ' +
                      progetto.codice +
                      ' non generato per mancanza di dati',
                  });

                  // this.messageService.add({key: 'tc',life: this.messageDuration,severity:'warning', summary: '', detail: 'PREVENTIVO per il progetto ' + progetto.codice +' generato SENZA RECORD'});
                  //this.loadData();
                } else {
                  this.messageService.clear();
                  this.messageService.add({
                    key: 'tc',
                    life: this.messageDuration,
                    severity: 'success',
                    summary: '',
                    detail:
                      'PREVENTIVO generato con successo per il progetto ' +
                      progetto.codice,
                  });

                  let fileName = 'CodCentr_NArm_Preventivo.xlsx';

                  const blob = new Blob([response.body], {
                    type: 'application/vnd.ms-excel',
                  });
                  saveAs(blob, fileName);
                }
              });
          }
        }
      }
    });  
  }

  openSinotticoDialog(progetto: ProgettoEsecutivo) {
     this.progettiEsecutiviService
      .checkSinottico(progetto.id.toString())
      .subscribe((couldProduceSinottico) => {
        if (couldProduceSinottico) {
          this.confirmationService.confirm({
            message: `Confermi la generazione del sinottico per il progetto esecutivo ${progetto.codice}?`,
            header: 'Generazione Sinottico',
            icon: 'pi pi-info-circle',
            acceptLabel: 'CONFERMA',
            acceptIcon: 'pi pi-times',
            acceptButtonStyleClass: 'p-button-success',
            rejectLabel: 'ANNULLA',
            rejectIcon: 'pi pi-check',
            rejectButtonStyleClass: 'p-button-warning',
            accept: () => {
              this.loadingInProgress = true;

              this.sinotticoService.generaEsecutivo(progetto.codice).subscribe(
                (response) => {
                  this.messageService.clear();
                  this.messageService.add({
                    key: 'tc',
                    life: this.messageDuration,
                    severity: 'success',
                    summary: '',
                    detail:
                      'Sinottico generato correttamente per il progetto esecutivo ' +
                      progetto.codice,
                  });
                  this.loadingInProgress = false;
                },
                (error) => {
                  this.messageService.clear();
                  this.messageService.add({
                    key: 'tc',
                    life: this.messageDuration,
                    severity: 'error',
                    summary: 'Errore',
                    detail: 'Errore in fase di generazione sinottico.\n'+error.error,
                    styleClass:'custom-toast-sinottico'
                  });
                  this.loadingInProgress = false;
                }
              );
            },
            reject: () => {
              //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
              console.log('ANNULLO');
            },
          });
        } else {
          this.messageService.clear();
          this.messageService.add({
            key: 'tc',
            life: this.messageDuration,
            severity: 'info',
            summary: 'Errore',
            detail:
              'Impossibile generare il sinottico per il progetto ' +
              progetto.codice,
          });
        }
      }); 
  }

  public progettiEsecutiviReworked:ProgettoEsecutivoReworked[]=[];
  loadReworks(progetto: Progetto, isExpanded:boolean){
    this.logger.info("---click su "+ progetto.codice + " > "+ isExpanded);
    this.progettiEsecutiviReworked = [];
    if(!isExpanded){
      this.loadingInProgress = true;
      this.progettiEsecutiviService.getReworks(progetto.id.toString()).subscribe(res => {
        this.progettiEsecutiviReworked = res;
        this.loadingInProgress = false;
      });
    }
  } 

  aggiornaApp(){
    //this.checkApplicationVersion();
    window.location.reload();
  }

  chiudiDialog(){
    this.messageService.clear();
  }

  openConsolidaProgetto(progetto: ProgettoEsecutivo) {
    this.ref = this.dialogService.open(DialogConsolidaProgettoEsecutivo, {
      data: {
        progetto: progetto,
        tenant: progetto.tenant.name,
      },
      header: 'Consolida dati del progetto esecutivo ' + progetto.codice,
      width: '40%',
      height: '55%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((consolidaResult) => {
      //console.log(reportCreationResult);
      if (consolidaResult.success) {
        console.log("CALLING CONSOLIDA " + consolidaResult.selectedConsolidamento.code);
        if(consolidaResult.selectedConsolidamento.code=='copia-civici'){
          this.loadingInProgress = true;
          this.progettiEsecutiviService.copiaCiviciProgetto(progetto.id.toString())
            .subscribe(
              (response) => {
                this.loadingInProgress = false;
                this.messageService.clear();
                this.messageService.add({
                  key: 'tc',
                  life: this.messageDuration,
                  severity: 'success',
                  summary: '',
                  detail: 'Civici del progetto esecutivo ' + progetto.codice.toUpperCase() + ' aggiornati con successo!',
                });
                this.loadData();
              },
              (error) => {
                this.messageService.clear();
                this.messageService.add({
                  key: 'tc',
                  life: this.messageDuration,
                  severity: 'error',
                  summary: 'Errore',
                  detail: 'Errore in fase di aggiornamento dei civici del progetto',
                });
                this.loadingInProgress = false;
              }
            );
        }else if(consolidaResult.selectedConsolidamento.code=='ROE' || consolidaResult.selectedConsolidamento.code=='CAVO_OTTICO' || consolidaResult.selectedConsolidamento.code=='RACCORDI' || consolidaResult.selectedConsolidamento.code=='POPOLA-ROE'){
          this.loadingInProgress = true;
          this.progettiEsecutiviService.consolidaProgetto(progetto.id.toString(), consolidaResult.selectedConsolidamento.code)
            .subscribe(
              (response) => {
                this.loadingInProgress = false;
                this.messageService.clear();
                this.messageService.add({
                  key: 'tc',
                  life: this.messageDuration,
                  severity: 'success',
                  summary: '',
                  detail: 'Progetto esecutivo ' + progetto.codice.toUpperCase() + ' consolidato!',
                });
                this.loadData();
              },
              (error) => {
                this.messageService.clear();
                this.messageService.add({
                  key: 'tc',
                  life: this.messageDuration,
                  severity: 'error',
                  summary: 'Errore',
                  detail: 'Errore in fase di consolidamento del progetto',
                });
                this.loadingInProgress = false;
              }
            );
        }else if (consolidaResult.selectedConsolidamento.code == 'NUMERA-ROE'){
          this.loadingInProgress = true;
          this.sinotticoService
            .numeraRoe(
              progetto.codice.toLowerCase()+"_esecutivo"
            )
            .subscribe(
              (response) => {
                this.loadingInProgress = false;
                this.messageService.clear();
                this.messageService.add({
                  key: 'tc',
                  life: this.messageDuration,
                  severity: 'success',
                  summary: '',
                  detail:
                    'Progetto esecutivo ' +
                    progetto.codice.toUpperCase() +
                    ' consolidato!',
                });
                this.loadData();
              },
              (error) => {
                this.messageService.clear();
                this.messageService.add({
                  key: 'tc',
                  life: this.messageDuration,
                  severity: 'error',
                  summary: 'Errore',
                  detail: 'Errore in fase di consolidamento del progetto',
                });
                this.loadingInProgress = false;
              }
            );
        }
        
      }
    });
  }

  openSetDeliveryDateProgettoDialog(progetto: ProgettoEsecutivo) {
     this.ref = this.dialogService.open(DialogSettaDataConsegnaEsecutivo, {
      data: {
        progetto: progetto,
        tenant: progetto.tenant.name,
      },
      header: 'Seleziona la data di consegna del progetto esecutivo ' + progetto.codice,
      width: '44%',
      height: '75%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((deliveryDateSelectionConfirmation) => {
      if (deliveryDateSelectionConfirmation.success) {
        var selectedDate: any = deliveryDateSelectionConfirmation.dataConsegna;

        var startDate: any = new Date(selectedDate.getFullYear(), 0, 1);
        var days = Math.floor(
          (selectedDate - startDate) / (24 * 60 * 60 * 1000)
        );
        var weekNumber = Math.ceil(days / 7);

        console.log('Week number of ' + selectedDate + ' is :   ' + weekNumber);
        this.loadingInProgress = true;

        this.progettiEsecutiviService
          .aggiornaDataConsegna(weekNumber, progetto.id)
          .subscribe((res) => {
            this.messageService.clear();
            this.messageService.add({
              key: 'tc',
              life: this.messageDuration,
              severity: 'success',
              summary: '',
              detail:
                'Settimana consegna del progetto ' +
                progetto.codice +
                ' aggiornata correttamente',
            });

            this.logger.info('>> AGGIORNAMENTO DATA SCADENZA OK');
            this.loadData();
            this.loadingInProgress = false;
          });
      }
    }); 
  }

  openProduciEtichetteDialog(progetto: ProgettoEsecutivo) {
     this.ref = this.dialogService.open(DialogGeneraEtichetteEsecutivo, {
      data: {
        progetto: progetto,
        tenant: progetto.tenant.name,
      },
      header: 'Genera Etichetta per il progetto esecutivo ' + progetto.codice,
      width: '40%',
      height: '50%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });

    this.ref.onClose.subscribe((reportCreationResult) => {
      //console.log(reportCreationResult);
      if (reportCreationResult.success) {
        this.loadingInProgress = true;
        this.etichetteService
          .generaEsecutivo(reportCreationResult.selectedEtichetta.code, progetto.codice)
          .subscribe(
            (response) => {
              this.messageService.clear();
              this.messageService.add({
                key: 'tc',
                life: this.messageDuration,
                severity: 'success',
                summary: '',
                detail:
                  'Etichette generate correttamente per il progetto ' +
                  progetto.codice,
              });
              this.loadingInProgress = false;
            },
            (error) => {
              this.messageService.clear();
              this.messageService.add({
                key: 'tc',
                life: this.messageDuration,
                severity: 'error',
                summary: 'Errore',
                detail: 'Errore in fase di generazione etichette! '+error.error,
              });
              this.loadingInProgress = false;
            }
          );
      }
    }); 
  }

  openCancellaProgettoDialog(progetto: ProgettoEsecutivo) {
    /* this.confirmationService.confirm({
      message: `Confermi l'eliminazione del progetto ${progetto.codice}?`,
      header: 'Eliminazione Progetto',
      icon: 'pi pi-info-circle',
      acceptLabel: 'CONFERMA',
      acceptIcon: 'pi pi-times',
      acceptButtonStyleClass: 'p-button-success',
      rejectLabel: 'ANNULLA',
      rejectIcon: 'pi pi-check',
      rejectButtonStyleClass: 'p-button-warning',
      accept: () => {
        this.loadingInProgress = true;

        this.progettiService.eliminaProgetto(progetto.id.toString()).subscribe(
          (response) => {
            this.loadingInProgress = false;
            this.messageService.clear();
            this.messageService.add({
              key: 'tc',
              life: this.messageDuration,
              severity: 'success',
              summary: '',
              detail: 'Progetto ' + progetto.codice + ' eliminato',
            });
            this.loadData();
          },
          (error) => {
            this.messageService.clear();
            this.messageService.add({
              key: 'tc',
              life: this.messageDuration,
              severity: 'error',
              summary: 'Errore',
              detail: 'Errore in fase di eliminazione progetto',
            });
            this.loadingInProgress = false;
          }
        );
      },
      reject: () => {
        console.log('ANNULLO');
      },
    }); */
  }

  

  openResetProgettoDialog(progetto: Progetto) {
     this.confirmationService.confirm({
      message: `Confermi l'azzeramento del progetto ${progetto.codice}?`,
      header: 'Reset Progetto Esecutivo',
      icon: 'pi pi-info-circle',
      acceptLabel: 'CONFERMA',
      acceptIcon: 'pi pi-times',
      acceptButtonStyleClass: 'p-button-success',
      rejectLabel: 'ANNULLA',
      rejectIcon: 'pi pi-check',
      rejectButtonStyleClass: 'p-button-warning',
      accept: () => {
        this.loadingInProgress = true;

        this.progettiEsecutiviService.resettaProgetto(progetto.id).subscribe(
          (response) => {
            this.messageService.clear();
            this.messageService.add({
              key: 'tc',
              life: this.messageDuration,
              severity: 'success',
              summary: '',
              detail: 'Progetto ' + progetto.codice + ' resettato',
            });
            this.loadData();
            this.loadingInProgress = false;
          },
          (error) => {
            this.messageService.clear();
            this.messageService.add({
              key: 'tc',
              life: this.messageDuration,
              severity: 'error',
              summary: 'Errore',
              detail: 'Errore in fase di reset del progetto esecutivo',
            });
            this.loadingInProgress = false;
          }
        );
      },
      reject: () => {
        console.log('ANNULLO');
      },
    });
  } 
}



@Component({
  templateUrl: './dialog-settaDataConsegna-progettoEsecutivo.component.html',
  styleUrls: ['./progetti-esecutivi.component.css'],
})
export class DialogSettaDataConsegnaEsecutivo implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected progetto: Progetto;

  dataConsegna: Date;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.logger.info(this.config.data.progetto);
    this.progetto = this.config.data.progetto;
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.dialogRef.close({
        success: true,

        dataConsegna: this.dataConsegna,
      });
    }
  }
}

@Component({
  templateUrl: './dialog-genera-report-esecutivo.component.html',
  styleUrls: ['./progetti-esecutivi.component.css'],
})
export class DialogGeneraReportEsecutivo implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected progetto: Progetto;
  protected reports: Report[];
  protected selectedReport: Report;
  protected selectedReports: Report[] = [];
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig
  ) {
    this.reports = [
      { name: 'AI FO', code: 'fo' },
      { name: 'RAME', code: 'rame' },
      { name: 'PREVENTIVO', code: 'preventivo' },
      { name: 'ASSOCIAZIONE BOX QUARTINE', code: 'associazioneBoxQuartine' },
    ];
  }

  ngOnInit(): void {
    this.logger.info(this.config.data.progetto);
    this.progetto = this.config.data.progetto;
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.dialogRef.close({
        success: true,
        //selectedReport: this.selectedReport,
        selectedReports: this.selectedReports,
      });
    }
  }
}

@Component({
  templateUrl: './dialog-genera-etichette-esecutivo.component.html',
  styleUrls: ['./progetti-esecutivi.component.css'],
})
export class DialogGeneraEtichetteEsecutivo implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected progetto: Progetto;
  protected etichette: Etichetta[];
  protected selectedEtichetta: Etichetta;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig,
  ) {
    this.etichette = [
      { name: 'Tim 2023 - V2', code: 'aubay' },
      { name: 'Tim 2023 - V1', code: 'tim_2023' },
    ];
  }

  ngOnInit(): void {
    this.logger.info(this.config.data.progetto);
    this.progetto = this.config.data.progetto;
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.dialogRef.close({
        success: true,
        selectedEtichetta: this.selectedEtichetta,
      });
    }
  }
}

@Component({
  templateUrl: './dialog-consegna-progetto-esecutivo.component.html',
  styleUrls: ['./progetti-esecutivi.component.css'],
})
export class DialogConsegnaProgettoEsecutivo implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected progetto: Progetto;
  selectedFile: File;
  isFileUploaded: boolean = false;
  constructor(
    private logger: NGXLogger,
    // private utentiService: UtentiService,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private progettiService: ProgettiService
  ) {}

  ngOnInit(): void {
    this.progetto = this.config.data.progetto;
  }

  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }

  conferma(conferma: boolean) {
    //TODO: QUI SICURO LO SPINNER!!!
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.dialogRef.close({ success: true, file: this.selectedFile });
    }
  }
}

@Component({
  templateUrl: './dialog-annulla-consegna-progetto-esecutivo.component.html',
  styleUrls: ['./progetti-esecutivi.component.css'],
})
export class DialogAnnullaConsegnaProgettoEsecutivo implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected progetto: Progetto;
  protected utenti: Utente[] = [];
  protected auth0Token: TokenAuth0;
  protected isUserSelected: boolean = false;
  protected selectedUser: User;
  protected reworkNotes:string='';

  constructor(
    private logger: NGXLogger,
    private utentiService: UtentiService,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig,

  ) {}

  ngOnInit(): void {
    this.progetto = this.config.data.progetto;
    this.loadUtenti();
  }

  loadUtenti() {
     this.utentiService.token().subscribe((res: TokenAuth0) => {
      this.auth0Token = res;
      //this.logger.info(this.auth0Token.access_token);
      if (this.auth0Token) {
        this.utentiService
          .utenti(this.auth0Token)
          .pipe(
            map((data) => {
              let cleanUtentiList: Utente[] = [];
              data.users.forEach((d) => {
                if(d.user_metadata!=null){
                  if (
                    d.user_metadata.tenant_id == localStorage.getItem('tenantId')
                  ) {
                    if (d.app_metadata.roles != 'SUPERADMIN'
                    //RICHIESTA FABIO ICARDI DEL 21 FEBBRAIO: Poter assegnare rework anche agli amministratori
                    //&& d.app_metadata.roles != 'AMMINISTRATORE' 
                    ) {
                      //console.log(d);
                      if (d.app_metadata != null) {
                        d.ruolo = d.app_metadata.roles;
                      } else {
                        d.ruolo = 'OPERATORE';
                      }
                      cleanUtentiList.push(d);
                    }
                  }
                }
              });

              return cleanUtentiList;
            })
          )
          .subscribe((res: any) => {
            this.utenti = res;
          });
      }
    });
  }

  conferma(conferma: boolean) {
    //TODO: QUI SICURO LO SPINNER!!!
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.dialogRef.close({ success: true, user:this.selectedUser, note:this.reworkNotes });
    }
  }
}


@Component({
  templateUrl: './dialog-assegna-progetto-esecutivo.component.html',
  styleUrls: ['./progetti-esecutivi.component.css'],
})
export class DialogAssegnaProgettoEsecutivo implements OnInit {
  protected _onDestroy = new Subject<void>();
  utenti: Utente[] = [];
  auth0Token: TokenAuth0;
  isUserSelected: boolean = false;
  selectedUsers: User[] = [];

  constructor(
    private logger: NGXLogger,
    private utentiService: UtentiService,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private progettiEsecutiviService: ProgettiEsecutiviService
  ) {}

  ngOnInit(): void {
    //carico lista utenti!
    this.loadUtenti();
  }

  loadUtenti() {
    this.utentiService.token().subscribe((res: TokenAuth0) => {
      this.auth0Token = res;
      //this.logger.info(this.auth0Token.access_token);
      if (this.auth0Token) {
        this.utentiService
          .utenti(this.auth0Token)
          .pipe(
            map((data) => {
              let cleanUtentiList: Utente[] = [];
              data.users.forEach((d) => {
                if(d.user_metadata!=null){
                  if (
                    d.user_metadata.tenant_id == localStorage.getItem('tenantId')
                  ) {
                    if (d.app_metadata.roles != 'SUPERADMIN') {
                      console.log(d);
                      if (d.app_metadata != null) {
                        d.ruolo = d.app_metadata.roles;
                      } else {
                        d.ruolo = 'OPERATORE';
                      }
                      cleanUtentiList.push(d);
                    }
                  }
                }
              });

              return cleanUtentiList;
            })
          )
          .subscribe((res: any) => {
            this.utenti = res;
          });
      }
    });
  }

  conferma(conferma: boolean) {
    const progetto = this.config.data.progetto;
    const userId = this.selectedUsers[0].user_id.substring(
      this.selectedUsers[0].user_id.indexOf('|') + 1,
      this.selectedUsers[0].user_id.length
    );
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.logger.info(
        '>> sto per assegnare il progetto esecutivo ' +
          progetto.id +
          " all'utente " +
          userId
      );
      const utenteDTO: UtenteDTO = new UtenteDTO();
      utenteDTO.id = userId;
      utenteDTO.name = this.selectedUsers[0].name;
      this.progettiEsecutiviService
        .assegnaProgetto(progetto.id, utenteDTO)
        .subscribe((result) => {
          this.dialogRef.close({
            success: true,
            risultato: result,
            utente: this.selectedUsers[0],
          });
        });
    }
  }
}
interface Consolidamento {
  name: string;
  code: string;
}
@Component({
  templateUrl: './dialog-consolida-progetto-esecutivo.component.html',
  styleUrls: ['./progetti-esecutivi.component.css'],
})
export class DialogConsolidaProgettoEsecutivo implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected progetto: Progetto;
  protected consolidamenti: Consolidamento[];
  protected selectedConsolidamento: Consolidamento;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private progettiService: ProgettiService
  ) {
    this.consolidamenti = [
      { name: 'COPIA INDIRIZZI IN ROE', code: 'ROE' },
      { name: 'CALCOLA LUNGHEZZE CAVO OTTICO', code: 'CAVO_OTTICO' },
      { name: 'AGGIORNA DIRETTRICI', code: 'RACCORDI' },
      { name: 'AGGIORNA CIVICI', code: 'copia-civici' },
      { name: 'AGGIORNA CIVICI', code: 'copia-civici' },
      { name: 'POPOLA ROE DA ANAGRAFICA TIM', code: 'POPOLA-ROE' },
      { name: 'CALCOLA NUMERAZIONE ROE (versione in fase di test)', code: 'NUMERA-ROE' }
    ];
  }

  ngOnInit(): void {
    this.logger.info(this.config.data.progetto);
    this.progetto = this.config.data.progetto;
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.dialogRef.close({
        success: true,
        selectedConsolidamento: this.selectedConsolidamento,
      });
    }
  }
}

interface ExportShape {
  name: string;
  code: string;
}
@Component({
  templateUrl: './dialog-esporta-shape-progetto-esecutivo.component.html',
  styleUrls: ['./progetti-esecutivi.component.css'],
})
export class DialogEsportaShapeProgettoEsecutivo implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected progetto: Progetto;
  protected exports: ExportShape[];
  protected selectedExport: ExportShape;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private progettiService: ProgettiService
  ) {
    this.exports = [
      { name: 'SHAPE PER TIM', code: 'tim' },
      { name: 'SHAPE PERMESSI', code: 'permessi' },
    ];
  }

  ngOnInit(): void {
    this.logger.info(this.config.data.progetto);
    this.progetto = this.config.data.progetto;
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.dialogRef.close({
        success: true,
        selectedExport: this.selectedExport,
      });
    }
  }
}

<!-- <div class="surface-ground px-4 py-5 md:px-6 lg:px-8" *ngIf="!isLoadingResults">
    <p-progressSpinner *ngIf="isLoadingResults"></p-progressSpinner>
   -->
    <p-card [style]="{'width': '97%', 'margin': '1%'}" header="Progetti Preliminari" >
      
    <div class="grid grafico-torta-div" >
        <div class="col-12 lg:col-3 p-2" *ngIf="isAdmin">
            <div class="p-3 text-center bg-blue-500" style="border-radius: 12px" >
                <button pButton pRipple type="button" icon="pi pi-user-plus" class="inline-flex justify-content-center align-items-center bg-blue-600 border-circle mb-3" style="width:49px; height: 49px"
                 (click)="openProject('non assegnato')"></button>
           
                <div class="text-2xl font-medium text-white mb-2">{{numeroProgettiDaAssegnare}}</div>
                <span class="text-blue-100 font-medium">Progetti da assegnare</span>
            </div>
        </div>
        <div class="col-12 lg:col-3 p-2" >
            <div class="p-3 text-center bg-yellow-500" style="border-radius: 12px">
                <button pButton pRipple type="button" icon="pi pi-plus" 
                class="inline-flex justify-content-center align-items-center bg-yellow-600 border-circle mb-3" 
                style="width:49px; height: 49px"
                 (click)="openProject('assegnato')"></button>
           
                <div class="text-2xl font-medium text-white mb-2">{{numeroProgettiAssegnati}}</div>
                <span class="text-blue-100 font-medium">Progetti da generare</span>
            </div>
        </div>
        <div class="col-12 lg:col-3 p-2">
            <div class="p-3 text-center bg-orange-500" style="border-radius: 12px">
                <button pButton pRipple type="button" icon="pi pi-file-edit" 
                class="inline-flex justify-content-center align-items-center border-circle mb-3 bg-orange-600" 
                style="width:49px; height: 49px"
                (click)="openProject('generato')"></button>
                <div class="text-2xl font-medium text-white mb-2">{{numeroProgettiInCorso}}</div>
                <span class="text-blue-100 font-medium">Progetti in corso</span>
            </div>
        </div>
     
    
        <div class="col-12 lg:col-3 p-2">
            <div class="p-3 text-center bg-green-500" style="border-radius: 12px">
                <button pButton pRipple type="button" icon="pi pi-check-circle" 
                class="inline-flex justify-content-center align-items-center bg-green-600 border-circle mb-3" 
                style="width:49px; height: 49px"
                    (click)="openProject('consegnato')"></button>
    
                <div class="text-2xl font-medium text-white mb-2">{{numeroProgettiConsegnati}}</div>
                <span class="text-blue-100 font-medium">Progetti consegnati</span>
            </div>
        </div>
        <div class="col-12 lg:col-3 p-2">
            <div class="p-3 text-center" style="border-radius: 12px; background-color: #c63819;">
                <button pButton pRipple type="button" icon="pi pi-file-excel" 
                class="inline-flex justify-content-center align-items-center border-circle mb-3" 
                style="width:49px; height: 49px; background-color: #5a1505;"
                (click)="openProject('rework')"></button>
                <div class="text-2xl font-medium text-white mb-2">{{numeroProgettiInRework}}</div>
                <span class="text-blue-100 font-medium">Rework
                </span>
            </div>
        </div>
    </div>
</p-card>

<p-card [style]="{'width': '97%', 'margin': '1em'}" header="Progetti Esecutivi">
    <div class="grid grafico-torta-div" >
        <div class="col-12 lg:col-3 p-2">
            <div class="p-4 text-center bg-orange-500" style="border-radius: 12px">
                <button pButton pRipple type="button" icon="pi pi-file-edit" 
                class="inline-flex justify-content-center align-items-center border-circle mb-3 bg-orange-600" 
                style="width:49px; height: 49px"
                (click)="openExecutiveProjects('generato')"></button>
                <div class="text-2xl font-medium text-white mb-2">{{numeroProgettiEsecutiviAssegnati}}</div>
                <span class="text-blue-100 font-medium">Progetti in corso</span>
            </div>
        </div>
      <div class="col-12 lg:col-3 p-2">
            <div class="p-4 text-center bg-green-500" style="border-radius: 12px">
                <button pButton pRipple type="button" icon="pi pi-check-circle" 
                class="inline-flex justify-content-center align-items-center bg-green-600 border-circle mb-3" 
                style="width:49px; height: 49px"
                    (click)="openExecutiveProjects('consegnato')"></button>
    
                <div class="text-2xl font-medium text-white mb-2">{{numeroProgettiEsecutiviConsegnati}}</div>
                <span class="text-blue-100 font-medium">Progetti consegnati</span>
            </div>
        </div>
        <div class="col-12 lg:col-3 p-2">
            <div class="p-4 text-center" style="border-radius: 12px; background-color: #c63819;">
                <button pButton pRipple type="button" icon="pi pi-file-excel" 
                class="inline-flex justify-content-center align-items-center border-circle mb-3" 
                style="width:49px; height: 49px; background-color: #5a1505;"
                (click)="openExecutiveProjects('rework')"></button>
                <div class="text-2xl font-medium text-white mb-2">{{numeroProgettiEsecutiviInRework}}</div>
                <span class="text-blue-100 font-medium">Rework
                </span>
            </div>
        </div>
    </div>
</p-card>
 
  
    <div class="grafico-torta-div col-12 md:col-12 ">
        <!-- <p-chart type="doughnut" [data]="progetti" [options]="chartOptions" [style]="{'width': '25%'}"></p-chart> -->
        <p-chart type="line" [data]="progettiNelTempo" [options]="basicOptions" [style]="{'width': '50%'}"></p-chart>
    </div>
    
<!-- </div> -->
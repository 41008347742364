<iframe id="iframeCenter"
src="https://supporto.maphosting.it/fibergis-faq"
title="FAQ"
width="100%"
height="98%">

</iframe>

<!-- <div class="surface-section px-4 py-6 md:px-6">
<p-accordion styleClass="mb-6" >
 
      <p-accordionTab header="Etichette: risoluzione errori">
          <div class="line-height-3">
            Nel caso in cui le etichette non vengano generate correttamente o se viene restituito un errore, effettuare prima questi controlli: 
        </div>
        <ul>
            <li style="font-weight: 400"><span style="font-weight: 400">Controllare la corretta sovrapposizione tra i </span><b>Cavi</b><span style="font-weight: 400"> e le </span><b>Infrastrutture</b><span style="font-weight: 400">.</span></li>
            <li style="font-weight: 400"><span style="font-weight: 400">Controllare che i </span><b><i>cavi </i></b><span style="font-weight: 400">e le </span><b>Infrastrutture</b> <span style="font-weight: 400">non abbiamo dei </span><b><i>punti di intersezione</i></b><span style="font-weight: 400"> (nodi) nelle zone in cui si intersecano due linee distinte.&nbsp;</span></li>
            <li style="font-weight: 400"><span style="font-weight: 400">Utilizzare il validatore topologico per ricercare errori di snap e sovrapposizione, come spiegato nella guida </span><b>FTTH</b><span style="font-weight: 400">:&nbsp; </span><a href="https://storage.cloud.google.com/tim-report-8c2a0.appspot.com/docs/Guide-utili-per-Progettazione-FTTH-_-Guida-Upload-Progetti.pdf" target="_blank"><span style="font-weight: 400">GUIDA Utile per la Progettazione</span></a></li>
        </ul>
        <div class="line-height-3">
            <b>Nel caso in cui sia stato utilizzato l’editing offline:</b> tutti i layer devono essere sincronizzati.
        </div>
      </p-accordionTab>
      <p-accordionTab header="Sinottico: risoluzione errori">
        <div class="line-height-3">
            Nel caso in cui il sinottico non venga generato correttamente o se viene restituito un errore, effettuare prima questi controlli: 
        </div>
        <ul>
            <li style="font-weight: 400"><span style="font-weight: 400">I cavi devono essere disegnati seguendo una certa gerarchia e direzionalità: i cavi devono essere direzionati dall’armadio ottico verso il gruppo ottico, e così via per il successivo. Quindi il primo punto va disegnato sull’armadio ottico il secondo sul gruppo ottico (muffola, pte ecc…). Ovvero dall’armadio verso l’esterno.</span></li>
            <li style="font-weight: 400"><span style="font-weight: 400">Tutti i cavi devono essere necessariamente snappati sui layer puntuali. Il Tool “</span><b>calamita</b><span style="font-weight: 400">” su Qgis deve essere attivo e con la spunta sui layer puntuali a cui bisogna agganciarsi (roe, muffole, pozzetti, armadio ottico, infrastrutture).</span></li>
            <li style="font-weight: 400"><span style="font-weight: 400">Il sinottico prende in considerazione l’attributo dei “</span><b><i>Roe</i></b><span style="font-weight: 400">” del campo “</span><b><i>Utilizzo roe</i></b><span style="font-weight: 400">” (</span><b><i>transito o terminale</i></b><span style="font-weight: 400">), verificare che questo campo sia stato compilato correttamente per ogni roe.</span></li>
            <li style="font-weight: 400">Se non vengono generate le ricchezze cavo, verificare che nel layer “<b><i>ricchezza_cavo</i></b>” sia stato compilato correttamente il campo “<b><i>ID_cavo</i></b>” con l’etichetta del cavo corrispondente.</li>
            </ul>
    <div class="line-height-3">
        <b>*Nel caso in cui sia stato utilizzato l’editing offline:</b> tutti i layer devono essere sincronizzati.
    </div>
       </p-accordionTab> 
      <p-accordionTab header="Snap e Aggancio: risoluzione errori">
        <div class="line-height-3">TBD </div>
    </p-accordionTab> 

  </p-accordion>
 <div class="border-1 border-round surface-border p-4 surface-50 flex flex-column align-items-center">
      <div class="font-medium text-xl mt-5 mb-3">Ancora dubbi?</div>
      <button pButton pRipple label="Contattaci" icon="pi pi-send" class="p-button-help" (click)="sendContactMail()"></button>
  </div> 
</div> -->
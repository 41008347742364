<div>
 <!--    <p class="line-height-3 p-0 m-0">
        Scegliere il report:   </p> -->
        <p-listbox [options]="reports" [(ngModel)]="selectedReports" optionLabel="name" [multiple]="true" [checkbox]="true" [metaKeySelection]="false"></p-listbox>
</div>
<div class="margin-top-1 border-top-1 surface-border pt-3">
    <div class="create-project-buttons-div">
        <button pButton pRipple  icon="pi pi-check" (click)="conferma(true)"  label="Conferma" class="p-button-success w-4 mr-2"  [disabled]="selectedReports.length==0"></button>
        <button pButton pRipple  icon="pi pi-times" (click)="conferma(false)" label="Annulla"  class="p-button-warning w-4 ml-2" ></button>
    </div>
</div>

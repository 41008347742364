<div>
    <p class="line-height-3 p-0 m-0">
        Si sta per generare il progetto per l'area {{config.data.progetto.codice}}.
       </p>
    <p class="mt-1">Il processo potrebbe richiedere diversi secondi. </p>
</div>
<div class="margin-top-1 border-top-1 surface-border">
    <div class="buttons-div">
        <button pButton pRipple icon="pi pi-check" (click)="conferma(true)" label="Conferma"  class="p-button-success w-5 mr-2"  ></button>
        <button pButton pRipple icon="pi pi-times" (click)="conferma(false)" label="Annulla" class="p-button-warning w-5 ml-2" ></button>
    </div>
</div>

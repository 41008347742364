
<div >
  <p class="line-height-3 p-0 m-1 carica-progetto-p">
  Seleziona l'azienda
  </p>
  <p-dropdown [options]="tenants" [(ngModel)]="tenantSelezionato" optionLabel="name"></p-dropdown>
   <div class="field mt-2">
    <label for="nuoviCrediti" class="block">Crediti da aggiungere</label>
    <p-inputNumber inputId="nuoviCrediti" type="nuoviCrediti" aria-describedby="nuoviCrediti-help" [(ngModel)]="numeroCrediti" ></p-inputNumber>
</div>

<!-- <div  class="margin-top-1 border-top-2 surface-border pt-3"></div> -->
  <div class="carica-crediti-buttons-div ">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text" styleClass="p-button-success" [disabled]="!tenantSelezionato || numeroCrediti=='0'"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" styleClass="p-button-warning"></p-button>
  </div>

</div>



<div >
  <p-orderList 
  [value]="utenti" 
  [listStyle]="{'max-height':'30rem', 'width':'auto'}" 
  [(selection)]="selectedUsers"
  header="Progettisti" 
  filterBy="name" 
  filterPlaceholder="Cerca per mail" 
  dragdrop="false"
  [stripedRows]="true"
  controlsPosition="right"
  >
    <ng-template let-product pTemplate="item">
        <div class="product-item">
          <div class="image-container">
                <img src="{{product.picture}}" [alt]="product.name" class="product-image rounded-circle" />
          </div>
            <div class="product-list-detail">
                <h3 class="mb-2">{{product.nickname}}</h3>

            </div>
            <div class="product-list-action">
              <span [class]="'user-badge ruolo-' + product.ruolo.toUpperCase()"> {{product.ruolo}}</span>
            </div>
          
        </div>
    </ng-template>
</p-orderList>
  
    <div class="buttons-div">
      <button pButton pRipple icon="pi pi-check" (click)="conferma(true)"  label="Conferma" class="p-button-success w-5 mr-2" [disabled]="selectedUsers.length==0"></button>
      <button pButton pRipple icon="pi pi-times" (click)="conferma(false)" label="Annulla"  class="p-button-warning w-5 ml-2"></button>
    </div>

</div>

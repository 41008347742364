// csvbox-loader.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvboxLoaderService {
  private scriptLoaded: boolean = false;

  loadScript(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.scriptLoaded) {
        resolve();
        return;
      }

      const scriptElement = document.createElement('script');
      scriptElement.src = 'https://js.csvbox.io/script.js';
      scriptElement.onload = () => {
        this.scriptLoaded = true;
        resolve();
      };
      scriptElement.onerror = (error) => {
        reject(error);
      };

      document.body.appendChild(scriptElement);
    });
  }
}

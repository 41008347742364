import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { HomeComponent } from './components/home/home.component';
import { ProgettiEsecutiviComponent } from './pages/progetti-esecutivi/progetti-esecutivi.component';
import { ProgettiComponent } from './pages/progetti/progetti.component';
import { TenantComponent } from './pages/tenants/tenants.component';
import { UtentiComponent } from './pages/utenti/utenti.component';

const routes: Routes = [
    {path: '', component: HomeComponent, pathMatch: 'full'},
    {path: 'utenti',  component: UtentiComponent, canActivate: [AuthGuard],},
    {path: 'tenants',  component: TenantComponent, canActivate: [AuthGuard],},
    {path: 'progetti/:filter',  component: ProgettiComponent, canActivate: [AuthGuard],},
    {path: 'progetti-esecutivi/:filter',  component: ProgettiEsecutiviComponent, canActivate: [AuthGuard],},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    //enableTracing: false, // <-- debugging purposes only
    useHash:false,
    onSameUrlNavigation: 'reload',
    
   // preloadingStrategy: SelectivePreloadingStrategyService,
  })],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy} 
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

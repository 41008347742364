
<div >
  <p class="line-height-3 p-0 m-0">
   Seleziona il nuovo ruolo dell'utente {{config.data.utente.nickname}}
  </p>
   <p-listbox [options]="ruoli" 
   [listStyle]="{'max-height':'250px'}" [style]="{ 'width':'50%','margin-top':'20px','margin-bottom':'20px'}"
   [(ngModel)]="ruoloSelezionato" optionLabel="nome" optionValue="id" (onClick)="selectRuolo(ruoloSelezionato)"
   class="change-role-div"></p-listbox>

</div>
<div class="margin-top-1 border-top-1 surface-border pt-3">
  <div class="change-role-div">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text" styleClass="p-button-success" [disabled]="!ruoloSelezionato"  ></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" styleClass="p-button-warning"></p-button>
  </div>
</div>
export class Tenant {
  id: number;
  name: string;

  crediti: number;
  numeroProgettiPreliminariGenerati: number;
  numeroProgettiEsecutiviGenerati: number;

  pesoProgettiPreliminari: number;
  pesoProgettiEsecutivi: number;
  computoTotale: number;

}

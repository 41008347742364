import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import {NGXLogger} from 'ngx-logger';
import { TokenAuth0Request } from '../models/tokenAuth0.request.model';
import { TokenAuth0 } from '../models/tokenAuth0.model';


@Injectable({
  providedIn: 'root',
})
export class UtentiService{
  auth0ApiUrl: string = `${environment.auth0ApiUrl}`;
  auth0TokenApiUrl: string = `${environment.auth0TokenApi.url}`;
  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}


    tokenPerBE(): Observable<any> {
      const headers =
      {
        'content-type': 'application/json'
      };
      const tokenAuth0Request = new TokenAuth0Request();
            tokenAuth0Request.client_id = environment.auth0TokenApi.clientId;
            tokenAuth0Request.client_secret = environment.auth0TokenApi.clientSecret;
            tokenAuth0Request.audience = environment.auth0TokenApi.audienceBE;
            tokenAuth0Request.grant_type = "client_credentials";
            /* this.logger.info(">>> STO PER CHIAMARE TOKEN @ > "+ this.auth0TokenApiUrl);
            this.logger.info(">>> STO PER CHIAMARE TOKEN PASSANDO CLIENT ID> "+ tokenAuth0Request.client_id);
            this.logger.info(">>> STO PER CHIAMARE TOKEN PASSANDO CLIENT SECRET> "+ tokenAuth0Request.client_secret);
            this.logger.info(">>> STO PER CHIAMARE TOKEN PASSANDO audience > "+ environment.auth0TokenApi.audienceBE);
            this.logger.info(">>> STO PER CHIAMARE TOKEN PASSANDO grant_type > "+ tokenAuth0Request.grant_type);  */
      const httpOptions = {
        headers,
      };
        return this.http.post(this.auth0TokenApiUrl, tokenAuth0Request, httpOptions).pipe(
          tap((res: TokenAuth0) => {
            return res;
          }),
          catchError(this.handleError<any[]>('getCertificati', []))
        );
    }

  token(): Observable<any> {
    const headers =
    {
      'content-type': 'application/json'
    };
    const tokenAuth0Request = new TokenAuth0Request();
          tokenAuth0Request.client_id = environment.auth0TokenApi.clientId;
          tokenAuth0Request.client_secret = environment.auth0TokenApi.clientSecret;
          //tokenAuth0Request.audience = environment.auth0TokenApi.audienceBE;
          tokenAuth0Request.audience = environment.auth0TokenApi.audience;
          tokenAuth0Request.grant_type = "client_credentials";
          /* this.logger.info(">>> STO PER CHIAMARE TOKEN @ > "+ this.auth0TokenApiUrl);
          this.logger.info(">>> STO PER CHIAMARE TOKEN PASSANDO CLIENT ID> "+ tokenAuth0Request.client_id);
          this.logger.info(">>> STO PER CHIAMARE TOKEN PASSANDO CLIENT SECRET> "+ tokenAuth0Request.client_secret);
          this.logger.info(">>> STO PER CHIAMARE TOKEN PASSANDO audience > "+ environment.auth0TokenApi.audienceBE);
          this.logger.info(">>> STO PER CHIAMARE TOKEN PASSANDO grant_type > "+ tokenAuth0Request.grant_type);  */
    const httpOptions = {
      headers,
    };
      return this.http.post(this.auth0TokenApiUrl, tokenAuth0Request, httpOptions).pipe(
        tap((res: TokenAuth0) => {
          return res;
        }),
        catchError(this.handleError<any[]>('getCertificati', []))
      );
  }


  utenti(token:TokenAuth0): Observable<any> {
    const headers =
    {
      'content-type': 'application/json',
      'Authorization':'Bearer '+token.access_token
    };
    const httpOptions = {
      headers,
    };
    return this.http.get(this.auth0ApiUrl+"?search_engine=v3&include_totals=true", httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
      catchError(this.handleError<any[]>('getUtenti', []))
    );
  }

  utente(token:TokenAuth0, user:string): Observable<any> {
    
    const url = this.auth0ApiUrl+"/"+user;
    //this.logger.info(">>> STO PER CHIAMARE GET UTENTE > "+ url);
    const headers =
    {
      'content-type': 'application/json',
      'Authorization':'Bearer '+token.access_token
    };
    const httpOptions = {
      headers,
    };
    return this.http.get(url, httpOptions).pipe(
      tap((res: any) => {
       // this.logger.info('fetched utenti');
        return res;
      }),
      catchError(this.handleError<any[]>('utente', []))
    );
  }

  eliminaUtente(idUtente:string, token:TokenAuth0): Observable<any> {
    this.logger.info(">>> STO PER ELIMINARE UTENTE > "+ idUtente);
   // const token = localStorage.getItem("token");

    const headers = {
      //'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`,
    };

    const httpOptions = {
      headers,
    };

    return this.http.delete(this.auth0ApiUrl+"/"+idUtente, httpOptions).pipe(
      tap((res: any) => {
        this.logger.info('cancellaUtente');

      }),
      catchError(this.handleError<any>('cancellaUtente', []))
    );

  }

  cambiaAppMetadata(idUtente:string, ruolo:string, token:TokenAuth0): Observable<any> {
    this.logger.info(">>> STO PER MODIFICARE IL RUOLO DI UN UTENTE A LIVELLO DI APP METADATA");
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`,
    };
    const httpOptions = {
      headers,
    };
    const appMetadata = {app_metadata: {
      roles:ruolo
    }};
   
    return this.http.patch(this.auth0ApiUrl+"/"+idUtente, appMetadata, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
        catchError(this.handleError<any>('modificaAppMetadata', []))
      );
  }

  cambiaUserMetadata(idUtente:string, tenantId:number, tenantName:string, token:TokenAuth0): Observable<any> {
    this.logger.info(">>> STO PER MODIFICARE IL TENANT DI UN UTENTE A LIVELLO DI USER METADATA");
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`,
    };
    const httpOptions = {
      headers,
    };
    const userMetadata = {
      user_metadata: {
        tenant_id:tenantId.toString(),
        tenant_name:tenantName
    }};
   
    return this.http.patch(this.auth0ApiUrl+"/"+idUtente, userMetadata, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
        catchError(this.handleError<any>('modificaAppMetadata', []))
      );
  }
  cambiaRuolo(idUtente:string, idRuolo:string, token:TokenAuth0): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`,
    };
    const httpOptions = {
      headers,
    };
    const roles = { 
      roles:[ idRuolo ]
    };

    return this.http.post(this.auth0ApiUrl+"/"+idUtente+"/roles", roles, httpOptions).pipe(
      tap((res: any) => {
        return res;
      }),
        catchError(this.handleError<any>('modificaRuolo', []))
      );
  }


/*
  
  
  
  
  
  


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError = <T>(operation = 'operation', result?: T) => {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      this.logger.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.info(`${operation} failed: ${error.message}`);

      /*this.errorHandler.dialogConfig = {...this.dialogConfig};
      this.errorHandler.handleError(error);*/



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  };
}



<div class="min-h-screen flex flex-column surface-ground">
    <p-toast position="center" key="tc" class="ui-confirmdialog-message"></p-toast>
    <p-toast key="notificaNuovaVersione" [style]="{width: '30rem'}" [preventOpenDuplicates]="true">
        <ng-template let-message pTemplate="message">
            <div class="flex surface-overlay border-1 surface-border surface-overlay">
                <div class="flex flex-column p-3">
                    <div class="flex align-items-center">
                        <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style="width:32px;height:32px">
                            <i class="pi pi-info text-lg"></i>
                        </span>
                        <span class="font-medium text-2xl text-900">{{message.summary}}</span>
                    </div>
                    <p class="my-3 p-0 line-height-3 text-700">{{message.detail}}</p>
                </div>
                <div class="flex flex-column border-left-1 surface-border">
                    <div class="flex-auto flex align-items-center justify-content-center px-3">
                        <button pButton pRipple label="Aggiorna Ora" class="p-button-text font-medium" (click)="aggiornaApp()"></button>
                    </div>
                    <div class="flex-auto border-top-1 surface-border flex align-items-center justify-content-center px-3">
                        <button pButton pRipple label="Più tardi" class="p-button-text font-medium" (click)="chiudiDialog()"></button>
                    </div>
                </div>
            </div>
        </ng-template>
      </p-toast>
    <app-nav-bar>
        
    </app-nav-bar>
<!--     <div class=" bg-cyan-100 shadow-2 flex align-items-center justify-content-center relative lg:relative border-bottom-1 border-gray-800"
        style="min-height: 10px; max-height: 20px; ">
        <h4 class="text-gray-900" style="margin: 0%;">Attenzione! Versione di test dell'applicazione!</h4>
    </div> -->
    <router-outlet>
    </router-outlet>
    
</div>

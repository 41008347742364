import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import {NGXLogger} from 'ngx-logger';
import { MessagingDTO } from '../models/messaging.dto.model';

@Injectable({
  providedIn: 'root',
})
export class MessagingService{
  apiUrl: string = `${environment.apiUrl}/messaging`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}

  subscribeToTopic(topic:string, key:string): Observable<any> {
    //this.logger.info("> CALLING SUBSCRIBE TO TOPIC: "+ topic);
   // this.logger.info("> this.apiUrl PRIMA: "+ this.apiUrl);
    this.apiUrl =`${environment.apiUrl}/messaging/subscribe/`+topic;
    //this.logger.info("> this.apiUrl DOPO: "+ this.apiUrl);
    const messaggingBody:MessagingDTO = new MessagingDTO();
    messaggingBody.key=key;
    //const tokenX = localStorage.getItem("BEtoken");
    const headers = {
     // 'Authorization': `Bearer ${tokenX}`,
    };
    const httpOptions = {
        headers,
        reportProgress: true,
      };
    return this.http.post(this.apiUrl, messaggingBody, httpOptions).pipe(
        tap((res: any) => {
          return res;
        }),
        catchError(this.handleError<any[]>('subscribeToTopic', []))
      );
  }

  
  

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError = <T>(operation = 'messaging', result?: T) => {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      this.logger.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.info(`${operation} failed: ${error.message}`);
      /*this.errorHandler.dialogConfig = {...this.dialogConfig};
      this.errorHandler.handleError(error);*/



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  };
}

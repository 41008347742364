import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import {NGXLogger} from 'ngx-logger';
import { TokenAuth0 } from '../models/tokenAuth0.model';
import { ThisReceiver } from '@angular/compiler';
import { Progetto } from '../models/progetto.model';
import { W } from 'chart.js/dist/chunks/helpers.core';
import { Utente } from '../models/utente.model';
import { UtenteDTO } from '../models/utente.dto.model';
import { ProgettoDTO } from '../models/progetto.dto.model';
@Injectable({
  providedIn: 'root',
})
export class EtichetteService{
  apiUrl: string = `${environment.apiUrl}/reports`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}

    genera(cliente:string, codiceProgetto:string = null): Observable<any> {
      this.apiUrl =environment.apiUrl+"/view/etichette?progetto="+codiceProgetto;
      
     // const token = localStorage.getItem("BEtoken");
      const headers = {
     //   'Authorization': `Bearer ${token}`,
      };
      
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      const requestEtichette = {
        client:cliente
      };
      return this.http.post(this.apiUrl, requestEtichette, httpOptions);
    }

    generaEsecutivo(cliente:string, codiceProgetto:string = null): Observable<any> {
      this.apiUrl =environment.apiUrl+"/view/etichette-esecutivo?progetto="+codiceProgetto;
      
     // const token = localStorage.getItem("BEtoken");
      const headers = {
     //   'Authorization': `Bearer ${token}`,
      };
      
      const httpOptions = {
        headers,
        reportProgress: true,
      };
      const requestEtichette = {
        client:cliente
      };
      return this.http.post(this.apiUrl, requestEtichette, httpOptions);
    }
  
 
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError = <T>(operation = 'operation', result?: T) => {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      this.logger.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.info(`${operation} failed: ${error.message}`);

      /*this.errorHandler.dialogConfig = {...this.dialogConfig};
      this.errorHandler.handleError(error);*/



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  };
}

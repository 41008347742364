<div class="surface-card p-4 shadow-2 border-round">
  <div class="mb-3 flex flex-column md:flex-row md:align-items-center md:justify-content-between">
    <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(175, 209, 175); color: white;" (click)="loadData()"></button>
    <span class="text-xl font-medium text-2000" style="color: blue">Tenants</span>
    <div class="flex">  
      <button *ngIf="isSuperAdmin" pButton type="button" icon="pi pi-plus" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Nuovo Tenant"
      (click)="openCreaTenantDialog()">
    </button>
    </div>
    <!--  -->
  </div>
  <div fxFlex="95%">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"> </p-confirmDialog>
   
    <p-table styleClass="p-datatable-sm" [value]="tenants" dataKey="nickname" [(selection)]="selectedTenant"
      [selectionPageOnly]="true" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10,25,50]" [loading]="isLoadingResults" responsiveLayout="scroll" [paginator]="true"
      currentPageReportTemplate="{first} - {last} di {totalRecords}" [filterDelay]="0"
      [globalFilterFields]="['name']">

      <ng-template pTemplate="header">
        <tr>

          <th pSortableColumn="nickname">
            <div class="flex justify-content-between align-items-center">
              Nome
              <p-sortIcon field="nickname"></p-sortIcon>
              <p-columnFilter type="text" field="nickname" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="crediti">
            <div class="flex justify-content-between align-items-center">
              Crediti Acquistati
              <p-sortIcon field="crediti"></p-sortIcon>
              <p-columnFilter type="text" field="crediti" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="preliminari">
            <div class="flex justify-content-between align-items-center">
              # Preliminari
              <p-sortIcon field="preliminari"></p-sortIcon>
              <p-columnFilter type="text" field="preliminari" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="pesoProgettiPreliminari">
            <div class="flex justify-content-between align-items-center">
              Peso Preliminari
              <p-sortIcon field="pesoProgettiPreliminari"></p-sortIcon>
              <p-columnFilter type="text" field="pesoProgettiPreliminari" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="esecutivi">
            <div class="flex justify-content-between align-items-center">
              # Esecutivi
              <p-sortIcon field="esecutivi"></p-sortIcon>
              <p-columnFilter type="text" field="esecutivi" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="pesoProgettiEsecutivi">
            <div class="flex justify-content-between align-items-center">
              Peso Esecutivi
              <p-sortIcon field="pesoProgettiEsecutivi"></p-sortIcon>
              <p-columnFilter type="text" field="pesoProgettiEsecutivi" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="computo">
            <div class="flex justify-content-between align-items-center">
              # Totale Pesato
              <p-sortIcon field="computo"></p-sortIcon>
              <p-columnFilter type="text" field="computo" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <!-- <th>Azioni</th> -->
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>

         
          <td> <span class="text-lowercase">{{row.name}}</span> </td>
          <td> <span class="text-lowercase">{{row.crediti}}</span> </td>
          <td> <span class="text-lowercase">{{row.numeroProgettiPreliminariGenerati}}</span> </td>
          <td> <span class="text-lowercase">{{row.pesoProgettiPreliminari}}</span> </td>
          <td> <span class="text-lowercase">{{row.numeroProgettiEsecutiviGenerati}}</span> </td>
          <td> <span class="text-lowercase">{{row.pesoProgettiEsecutivi}}</span> </td>
          <td> <span class="text-lowercase">{{row.computoTotale}}</span> </td>

          <!-- 
           -->

        </tr>
      </ng-template>

    </p-table>

  </div>
</div>
<p-toast position="top-center"></p-toast>

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { AppConfig } from 'src/app/app.config';
import { Tenant } from 'src/app/models/tenant.models';
import { TokenAuth0 } from 'src/app/models/tokenAuth0.model';
import { UtentiService } from 'src/app/services/utenti.service';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage  } from "firebase/messaging";
import { MessagingService } from 'src/app/services/messaging.service';
import { MessageService } from 'primeng/api';
import { ApplicationVersionsService } from 'src/app/services/application-versions.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

export let applicationVersionFromHome :string='';
@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  ruolo: string = '';
  applicationVersion:string='';
  ref: DynamicDialogRef;
  contatore:number= 0;

  firebaseConfig = { 
    apiKey: "AIzaSyD-7e2rFoB_-CdBEUn1x2TNLuZ3B5kdkbI",
    authDomain: "tim-report-8c2a0.firebaseapp.com",
    projectId: "tim-report-8c2a0",
    storageBucket: "tim-report-8c2a0.appspot.com",
    messagingSenderId: "753082534746",
    appId: "1:753082534746:web:2a2e88631df0ce57bec163",
    measurementId: "G-T9BNV2YLM6",
    vapidKey: "BKWIhhOP3XXRt5ebxp3BmdJHZlaQSqqNgujzKa00JFdFCNhaNZI1AKANYigUBqFTNu1KRuKgRk1MIwl8RwomMek"
  };

  constructor(
    public appConfig: AppConfig,
    public auth: AuthService,
    private utentiService: UtentiService,
    private messagingService:MessagingService,
    private applicationVersionService:ApplicationVersionsService,
    private http: HttpClient,
    private messageService: MessageService,
    public dialogService: DialogService,
  ) {
    
  }

  viewBlocks(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  openChangeLogDialog(){
    if(this.contatore == 0){
      this.contatore = 1;
      this.ref = this.dialogService.open(DialogChangeLog, {
        data: {
      },
        header: 'Novità della versione '+this.applicationVersion,
        width: '95%',
        height: '90%',
        contentStyle: { "overflow": "true"},
        baseZIndex: 10000,
        closable:true,
        draggable:false,
        closeOnEscape:true,
    }); 
    localStorage.setItem('currentAppVersion', this.applicationVersion);
   
    }
  }


  isSuperAdmin() {
      return localStorage.getItem('ruolo') == "SUPERADMIN";
  }

  checkApplicationVersion(){
    //console.log("checkApplicationVersion su HOME");
      this.applicationVersionService.getCurrentVersion().subscribe(result => {
        this.applicationVersion   = result.version;
        applicationVersionFromHome= result.version;
        

        if(localStorage.getItem("currentAppVersion")== 'undefined' || localStorage.getItem("currentAppVersion")== null){
          this.openChangeLogDialog();
          //localStorage.setItem('currentAppVersion', this.applicationVersion);
      }else{
        if(localStorage.getItem('currentAppVersion')!= this.applicationVersion){
          this.openChangeLogDialog();
        }
        
    }
      },
      error => {
        console.error('ERRORE get version' + error);
      })

      
  }


  ngOnInit() {
   // console.log('ngOnInit @ HOME');
     //if(!localStorage.getItem('userId') && ){
      this.auth.user$.subscribe((user) => {
        console.log('>>>> CHIAMATO RECUPERO UTENTE ');
      
        if (user) {
        
          localStorage.setItem('userId', user.sub.substring(user.sub.indexOf('|')+1, user.sub.length));
          localStorage.setItem('utente', user.name);
          localStorage.setItem('email', user.email);
          localStorage.setItem('user.sub', user.sub);
          //this.ruolo = user['https://report-tim/roles'];
          localStorage.setItem('ruolo',user['https://report-tim/roles']);
          if(localStorage.getItem('creditiModaleMostrata')==null || localStorage.getItem('creditiModaleMostrata')==='null' || localStorage.getItem('creditiModaleMostrata')==='undefined' ){
            localStorage.setItem('creditiModaleMostrata','false');
          }
          if(localStorage.getItem('tenantId') == 'undefined' || localStorage.getItem('tenantId') == 'null'){
            this.utentiService.token().subscribe((res:TokenAuth0) => {
             const auth0Token = res;
            
              this.utentiService.utente(auth0Token,user.sub ).subscribe( utente =>{
               if(utente.user_metadata != 'undefined' ||  utente.user_metadata != 'null'){
                  localStorage.setItem('tenantId', utente.user_metadata.tenant_id );
                  localStorage.setItem('tenantName', utente.user_metadata.tenant_name );
               }
               
             });
               
           });
         }
          this.auth.getAccessTokenSilently().subscribe((res) => {
            console.log(' UTENTE DA ACCESS TOKEN SILENTY ');
            console.log(res);
            console.log('---------------');
            localStorage.setItem('auth0Token', res);
            /* this.ruolo = user['https://report-tim/roles'];
            localStorage.setItem('ruolo', this.ruolo); */
            //localStorage.setItem('BEtoken', "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ii0xa2VaMTZkQkcxc0ZqSHg0ZVR3RSJ9.eyJpc3MiOiJodHRwczovL2Rldi1yZXBvcnQtdGltLmV1LmF1dGgwLmNvbS8iLCJzdWIiOiJFWjRIMVYxNmFPR09WdVlGRTVHUm01UEtWc0d3SmpkUUBjbGllbnRzIiwiYXVkIjoiaHR0cDovL3RpbS1yZXBvcnQtYmUtZGV2LnZlcnRpY2FsLXNybC5pdCIsImlhdCI6MTY3MzU5MTQ2NywiZXhwIjoxNjczNjc3ODY3LCJhenAiOiJFWjRIMVYxNmFPR09WdVlGRTVHUm01UEtWc0d3SmpkUSIsImd0eSI6ImNsaWVudC1jcmVkZW50aWFscyIsInBlcm1pc3Npb25zIjpbXX0.x5DC9tHKE5gtqzx6GcSkS5gvghLbw_ZJW52r63PDq0N9jABZ5oFQ7Q_CHxbYE7EZdIyUWBMLGCGq_IbA6mEMX2x_FNWDOfiRLQaAxA7USvhlin1MbRGFk5V-4wSyES06dgJL3_dWtBAK977XlYJBxzTzVEh-s6oMTrIKLtyzXsaaNfwBRsXFZgE8a-By4PJwC_uIhogsxS54WhiGlEK2Glj4SZC8EUZssjdREQ3DS-55DN-7K4iGtetbrjZz7jtzwg_XiJKqgAwlLSPeh0GcwRJbs4AbH9W-YxqYjKbSF64S4WJ_3xyxf_TVq9L2FBkrKA9I1zinLXXqyuv4kxg6AQ");
            /*
            this.utentiService.tokenPerBE().subscribe((res:TokenAuth0) => {
              localStorage.setItem('BEtoken', res.access_token);
              //console.log(">>> TOKEN OK");
              //console.log(res.access_token);
            });
            */
          });
          if(!this.isSuperAdmin()){
            this.checkApplicationVersion();
            this.listen();
          }
          
         
      }/* else {
        console.log('>>>> UTENTE NULLO ');
      } */
  
    });
 // }else{
 //     console.log("b) NON NULLO!");
 //   } 

    //
  }

  listen() {
   // console.log("-> listen su HOME con userId " + localStorage.getItem('userId'));

    const messaging = getMessaging();
    
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      //this.message=payload;
      this.messageService.add({
        key: 'notificaNuovaVersione',
        life:environment.progettiMessageDuration,
        severity: 'custom-1',
        summary: payload.notification.title,
        detail: payload.notification.body,
        closable: false,
        contentStyleClass: 'p-0'
    });
    });
    

  }

  aggiornaApp(){
    this.checkApplicationVersion();
    window.location.reload();
  }

  chiudiDialog(){
    this.messageService.clear();
  }


  loginWithRedirect(){
    
    
    this.auth.loginWithRedirect().subscribe(
        res =>{
          console.log("### loginWithRedirect @ HOME");
        }
      );
}
}

@Component({
  templateUrl: './dialog-changelog.component.html',
  styleUrls: ['./home.component.css']
})
export class DialogChangeLog implements OnInit {
  protected _onDestroy = new Subject<void>();
  constructor(
      public dialogRef: DynamicDialogRef,
       public config: DynamicDialogConfig,

      ) {
  }

  ngOnInit(): void {
  }

  conferma(conferma:boolean) {
      if(!conferma){
          this.dialogRef.close({success: false});
      }else {
       
          this.dialogRef.close({success: true});
 
      }
  }
}

<div class="surface-ground px-4 py-5 md:px-6 lg:px-8">
    <div class="flex flex-column md:flex-row gap-4 md:align-items-center xl:px-6 pt-6">
        <div class="shadow-2 surface-card border-round p-4 flex-1 text-center mb-6 md:mb-0">
            <div class="mb-4">
                <img src="assets/images/vertical/favicon logo fibergis vertical.png" width="100" height="100" class="-mt-8">
                <span class="block text-800 mb-1 text-xl font-bold">Crediti acquistati</span>
                <p class="text-xl text-900 m-0 font-bold">{{numeroCreditiAcquistati}}</p>
            </div>
           
            <div class="flex justify-content-between align-items-center">
                <span class="text-xl font-medium text-90">Progetti generati</span>
                <span class="text-900 font-medium">{{numeroTotaleProgettiGenerati}}</span>
               <!--  <span class="text-900 font-medium">{{percentuale}}%</span> -->
            </div>
            <div class="surface-300 mt-2 mb-3 border-round" style="height: 7px">
                <div class="h-full border-round " 
                [style.width]="percentualeString"
                [style.background-color]="percentuale < 75?'green':percentuale < 90?'orange':'red'"
                
                ></div>
                <!--bg-blue-500
                    
                    style="width:{{percentuale}}%"-->
            </div>
            <div class="flex justify-content-center align-items-center">
                <!-- <span class="text-900">{{numeroTotaleProgettiGenerati}}/{{numeroCreditiAcquistati}}</span> -->
                <span class="text-900 text-xl font-bold">{{percentuale}}%</span>
                <!-- <span class="text-primary font-medium">2 weeks left</span> -->
            </div>
            <!-- <div class="flex justify-content-start align-items-center"> </div> -->
            <ul class="mt-4 list-none p-0 mx-0">
                <li class="flex align-items-center pb-3">
                    <span style="width:1rem;height:1rem" class="border-round bg-indigo-500 mr-3 flex-shrink-0"></span>
                    <span class="text-l font-medium text-90">Preliminari</span>
                    <span class="text-600 text-l font-medium ml-auto">{{numeroPreliminari}}</span>
                </li>
                <li class="flex align-items-center py-3">
                    <span style="width:1rem;height:1rem" class="border-round bg-cyan-500 mr-3 flex-shrink-0"></span>
                    <span class="text-l font-medium text-90">Esecutivi</span>
                    <span class="text-600 text-l font-medium ml-auto">{{numeroEsecutivi}}</span>
                </li>
            </ul>
            <!-- <div class="w-full lg:w-6 p-3"></div> -->
                 <p-button icon="pi pi-credit-card" (onClick)="openAcquistaCredito()" label="Acquista nuovo credito" class="p-button-text" styleClass="p-button-info" ></p-button> 
            
          
        </div>
        
        
       
    </div>
   
</div>
<div>
    <p class="line-height-3 p-0 m-0">
    Seleziona eventuale file da consegnare.
    </p>
       <p-fileUpload name="progetti"
       multiple="false"
       accept=".zip" maxFileSize="5000000" 
       chooseLabel="Cerca" uploadLabel="Carica" cancelLabel="Cancella"
       [auto]="true"
       [customUpload]="true"
       (uploadHandler)="onFileUpload($event)"
       [showUploadButton]="false"
       [showCancelButton]="false"
       [disabled]="isFileUploaded"
       (onRemove)="onFileRemove()"
       >
    </p-fileUpload>
</div>
<div class="margin-top-1 border-top-1 surface-border">
    <div class="buttons-div">
        <button pButton pRipple icon="pi pi-check" (click)="conferma(true)" label="Consegna"  class="p-button-success w-5 mr-2"  ></button>
        <button pButton pRipple icon="pi pi-times" (click)="conferma(false)" label="Annulla" class="p-button-warning w-5 ml-2" ></button>
    </div>
</div>

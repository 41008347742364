

<div class=" bg-gray-900 py-3 px-6 shadow-2 flex align-items-center justify-content-between relative lg:relative border-bottom-1 border-gray-800"
 style="min-height: 84px; position: static;">
 <!--z-index: 999999 !important;-->
    <!-- src="../../../assets/images/vertical/logo_vertical_200.svg"src="../../../assets/images/vertical/Logo_Verticaldigitalstudio.png" -->
    <div>
    <img src="../../../assets/images/vertical/logo fibergis vertical.png" alt="Image" height="40" class="mr-0 lg:mr-6" (click)="goToHome()">
    <p-button *ngIf="displayAppVersion" label="{{currentVersion}}" styleClass="p-button-secondary p-button-text" class="versionSpan" (click)="openChangelog()"></p-button>
<!-- style="font-weight: bold; display: table; margin: 0 auto; font-size: larger" -->
    <!-- <span *ngIf="displayAppVersion" class="font-bold versionSpan" (click)="openChangelog()" (mouseenter)="isMouseOver = true" (mouseleave)="isMouseOver = false">
        
        <i *ngIf="isMouseOver" class="pi pi-pointer"></i>
       
    </span>  -->
    <a pRipple class="cursor-pointer block lg:hidden text-gray-400" pStyleClass="@next" enterClass="hidden" leaveToClass="hidden" [hideOnOutsideClick]="true" >
        <i class="pi pi-bars text-4xl"></i>
    </a>
</div>
    <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex relative lg:static w-full bg-gray-900 left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800" 
    >
    <!--style="z-index: 9999 !important;"-->
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
            <li *ngIf="auth.user$ | async as user">
                <a pRipple 
                    class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                     [routerLink]="['/']" style="textDecoration:'none'" >
                    <i class="pi pi-home mr-2"></i>
                    <span>Home</span>
                </a>
            </li>
            <li *ngIf="!isSuperAdmin() && auth.user$ | async as user" >
                <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <i class="pi pi-bookmark mr-2"></i>
                    <span>Progetti Preliminari</span>
                    <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                </a>
                <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer lg:border-1 border-gray-800">
                            <li>
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150" 
                                (click)="openProjects('all')"
                                >
                                    <i class="pi pi-folder-open mr-2"></i>
                                    <span class="font-medium">Tutti</span>
                                </a>
                            </li>
                            <li *ngIf="isAdministrator()">
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150" 
                                (click)="openProjects('non assegnato')"
                                >
                                    <i class="pi pi-user-plus mr-2"></i>
                                    <span class="font-medium">Da assegnare</span>
                                </a>
                            </li>
                            <li>
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150" 
                                (click)="openProjects('assegnato')"
                                >
                                    <i class="pi pi-plus mr-2"></i>
                                    <span class="font-medium">Da generare</span>
                                </a>
                            </li>
                            <li>
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                                (click)="openProjects('generato')">
                                    <i class="pi pi-file-excel mr-2"></i>
                                    <span class="font-medium">In corso</span>
                                </a>
                            </li>
                            <li>
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                                (click)="openProjects('consegnato')">
                                    <i class="pi pi-check-circle mr-2"></i>
                                    <span class="font-medium">Consegnati</span>
                                </a>
                            </li>
                            <li>
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                                (click)="openProjects('rework')">
                                    <i class="pi pi-file-excel mr-2"></i>
                                    <span class="font-medium">In rework</span>
                                </a>
                            </li>
                  
                </ul>
            </li>
            <li *ngIf="!isSuperAdmin() && auth.user$ | async as user" >
                <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <i class="pi pi-bookmark-fill mr-2"></i>
                    <span>Progetti Esecutivi</span>
                    <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                </a>
                <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer lg:border-1 border-gray-800">
                            <li>
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150" 
                                (click)="openExecutiveProjects('all')"
                                >
                                    <i class="pi pi-folder-open mr-2"></i>
                                    <span class="font-medium">Tutti</span>
                                </a>
                            </li>
                             <li>
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                                (click)="openExecutiveProjects('generato')">
                                    <i class="pi pi-file-excel mr-2"></i>
                                    <span class="font-medium">In corso</span>
                                </a>
                            </li>
                            <li>
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                                (click)="openExecutiveProjects('consegnato')">
                                    <i class="pi pi-check-circle mr-2"></i>
                                    <span class="font-medium">Consegnati</span>
                                </a>
                            </li>
                            <li>
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                                (click)="openExecutiveProjects('rework')">
                                    <i class="pi pi-file-excel mr-2"></i>
                                    <span class="font-medium">In rework</span>
                                </a>
                            </li>
                  
                </ul>
            </li>
         
            <li *ngIf="isAdministrator() && auth.user$ | async as user">
                <a pRipple 
                class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                [routerLink]="['utenti']" style="textDecoration:'none'"    
                [hideOnOutsideClick]="true" 
                >
                    <i class="pi pi-users mr-2"></i>
                    <span>Utenti</span>
                </a>
                
            </li>
            <li *ngIf="isSuperAdmin() && auth.user$ | async as user">
                <a pRipple 
                class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                [routerLink]="['tenants']" style="textDecoration:'none'" [hideOnOutsideClick]="true" 
                >
                    <i class="pi pi-briefcase mr-2"></i>
                    <span>Tenants</span>
                </a>
                
            </li>

            
            <li *ngIf="!isSuperAdmin() && isAdministrator() && auth.user$ | async as user" >
                <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <i class="pi pi-copy mr-2"></i>
                    <span>Anagrafiche</span>
                    <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                </a>
                <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer lg:border-1 border-gray-800">
                    <li class="relative">
                        <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                            pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                            <i class="pi pi-book mr-2"></i>
                            <span class="font-medium">Progetti</span>
                            <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                        </a>
                        <ul class="list-none py-3 pl-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0 lg:border-1 border-gray-800">
                            <li>
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150" 
                                (click)="openCaricaProgetti()">
                                    <i class="pi pi-upload mr-2"></i>
                                    <span class="font-medium">Carica</span>
                                </a>
                            </li>
                            <li class="relative">
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                                    (click)="openScaricaTemplateProgetti()">
                                    <i class="pi pi-download mr-2"></i>
                                    <span class="font-medium">Scarica Template</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="relative">
                        <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                            pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                            <i class="pi pi-server mr-2"></i>
                            <span class="font-medium">Box</span>
                            <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                        </a>
                        <ul class="list-none py-3 pl-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0 lg:border-1 border-gray-800">
                            <li>
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150" 
                                (click)="openCaricaBoxTim()">
                                    <i class="pi pi-upload mr-2"></i>
                                    <span class="font-medium">Carica</span>
                                </a>
                            </li>
                            <li class="relative">
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                                    (click)="openScaricaTemplateBox()">
                                    <i class="pi pi-download mr-2"></i>
                                    <span class="font-medium">Scarica Template</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="relative">
                        <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                            pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                            <i class="pi pi-globe mr-2"></i>
                            <span class="font-medium">Civici WI</span>
                            <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                        </a>
                        <ul class="list-none py-3 pl-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0 lg:border-1 border-gray-800">
                            <li>
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150" 
                                (click)="openCaricaCivici()">
                                    <i class="pi pi-upload mr-2"></i>
                                    <span class="font-medium">Carica</span>
                                </a>
                            </li>
                            <li class="relative">
                                <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                                    (click)="openScaricaTemplateCivici()">
                                    <i class="pi pi-download mr-2"></i>
                                    <span class="font-medium">Scarica Template</span>
                                </a>
                            </li>
                           
                        </ul>
                    </li>
                </ul>
            </li>
          
        </ul>


        
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
            <li *ngIf="!isSuperAdmin() && isAdministrator() && auth.user$ | async as user">
                <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                (click)="showCreditSituation()" >
                    <i class="pi pi-euro text-base lg:text-2xl mr-2 lg:mr-0" pTooltip="Visualizza Crediti"></i>
                    <span class="block lg:hidden font-medium">Crediti</span>
                </a>
            </li>
           <!--  <li *ngIf="!isSuperAdmin() && auth.user$ | async as user">
                <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                (click)="showCreditSituation()" 
                >
                    <i class="pi pi-cart-plus text-base lg:text-2xl mr-2 lg:mr-0" pTooltip="Altri Servizi"></i>
                    <span class="block lg:hidden font-medium">Servizi</span>
                </a>
            </li> -->
            

            <li *ngIf="!isSuperAdmin() && auth.user$ | async as user">
                <a  pRipple (click)="openDocDialog()"
                class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                    <i class="pi pi-book text-base lg:text-2xl mr-2 lg:mr-0" tooltipPosition="bottom" pTooltip="Documentazione"></i>
                    <span class="block lg:hidden font-medium">Documentazione</span>
                </a>
            </li>
            <li *ngIf="!isSuperAdmin() && auth.user$ | async as user">
                <a pRipple (click)="openFAQDialog()"
                class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                    <i class="pi pi-info text-base lg:text-2xl mr-2 lg:mr-0" tooltipPosition="bottom" pTooltip="FAQ"></i>
                    <span class="block lg:hidden font-medium">Info</span>
                </a>
            </li>
            <li *ngIf="!isSuperAdmin() && auth.user$ | async as user">
                <a pRipple (click)="openHelpDialog()"
                class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150">
                    <i class="pi pi-question text-base lg:text-2xl mr-2 lg:mr-0" tooltipPosition="bottom" pTooltip="Supporto"></i>
                    <span class="block lg:hidden font-medium">Help</span>
                </a>
            </li>
            <li *ngIf="isSuperAdmin() && auth.user$ | async as user">
                <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                (click)="openCaricaCreditiDialog()" >
                    <i class="pi pi-dollar text-base lg:text-2xl mr-2 lg:mr-0" pTooltip="Carica Crediti"></i>
                    <span class="block lg:hidden font-medium">Carica Crediti</span>
                </a>
            </li>
            <li *ngIf="isSuperAdmin() && auth.user$ | async as user">
                <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                (click)="openAvanzaVersioneDialog()" >
                    <i class="pi pi-step-forward-alt text-base lg:text-2xl mr-2 lg:mr-0" pTooltip="Avanza Versione"></i>
                    <span class="block lg:hidden font-medium">Avanza Versione</span>
                </a>
            </li>
            <li class="border-top-1 border-gray-800 lg:border-top-none text-gray-400" *ngIf="(auth.isAuthenticated$ | async) === false">
                <a pRipple 
                class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                (click)="loginWithRedirect()"  >
                    <i class="pi pi-sign-in text-base lg:text-2xl mr-2 lg:mr-0"></i>
                    <span class="block lg:hidden font-medium">Login</span>    
                </a>
            </li>
           
            <li *ngIf="auth.user$ | async as user"
                    class="lg:relative" >
                <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                    pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <img [src]="user.picture" class="mr-3 lg:mr-0 rounded-circle" style="width: 28px; height: 28px; border-radius: 50%"/>
                    <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                </a>
                <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer lg:border-1 border-gray-800">
                    <li >
                        <a 
                        pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                        enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true"
                        (click)="openProfileModule()" >
                        
                            <i class="pi pi-user-edit mr-2"></i>
                            <span class="font-medium">Profilo</span>
                        </a>
                    </li>
                    
                    <li class="relative">
                        <a pRipple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150"
                            pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true"
                            (click)="logout()">
                            <i class="pi pi-sign-out mr-2"></i>
                            <span class="font-medium">Logout</span>
                           
                        </a>
                    </li>
                </ul>
            </li>
          
        </ul>
    </div>
</div>

<p-dialog header="TIM REPORT" [(visible)]="displayInfoModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <p class="m-0">L'applicazione FiberGIS è lo strumento nato per aiutarti nella gestione del ciclo di vita di un progetto FTTH.
        Per qualsiasi dubbio o segnalazione, contattaci via mail a supporto@vertical-srl.it </p>
        <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="displayInfoModal=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
</p-dialog>

<p-sidebar [(visible)]="showConfigPanel" position="right" [baseZIndex]="10000">
    <h1 style="font-weight:normal">Configurazione Ambiente FiberGIS</h1>
    <div class="margin-top-1 border-top-1 surface-border pt-3">
        <div class="config-buttons-div">   
            <p-button type="button" (click)="showConfigPanel = false" label="Salva" styleClass="p-button-success"></p-button>
            <p></p>
            <p-button type="button" (click)="showConfigPanel = false" label="Annulla" styleClass="p-button-secondary"></p-button>
        </div> 
    </div>
</p-sidebar>
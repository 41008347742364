import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { NGXLogger } from 'ngx-logger';
import { MenuItem, MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { CaricamentoProgettiResult } from 'src/app/models/caricamentoProgetti.result.models';
import { AnagraficheService } from 'src/app/services/anagrafiche.service';
import { getStorage, ref, getBlob, deleteObject } from 'firebase/storage';
import { saveAs } from 'file-saver';
import { ProjectsReloadService } from '../../services/projects-reload.service';
import { UtilService } from 'src/app/services/util.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CaricamentoCiviciResult } from 'src/app/models/caricamentoCivici.result.models';
import { environment } from '../../../environments/environment';
import { ApplicationVersionsService } from 'src/app/services/application-versions.service';
import { TenantsService } from 'src/app/services/tenants.service';
import { Tenant } from 'src/app/models/tenant.models';

import { CsvboxLoaderService } from 'src/app/services/csvbox-loader.service';
import { CSVBoxAngularModule} from '@csvbox/angular';

interface Release {
  name: string;
  code: string;
}

declare const CSVBoxImporter: any;

@Component({
  selector: 'app-nav-bar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavBarComponent implements OnInit {
  displayInfoModal: boolean = false;
  ref: DynamicDialogRef;
  showConfigPanel: boolean = false;
  risultatoCaricamentoProgetti: CaricamentoProgettiResult | null;
  risultatoCaricamentoCivici: CaricamentoCiviciResult | null;
  isLoadingResults = false;
  messageDuration: number = environment.anagraficheMessageDuration;

  menuItems: MenuItem[];

  currentVersion: string;
  displayAppVersion: boolean = false;

  hideAnagraficheMenu: boolean = false;
  hideProgettiEsecutiviMenu: boolean = false;
  hideProgettiMenu: boolean = false;

  csvbox_progetti_importer:any;
  csvbox_civici_importer:any;

  csvbox_progetti_licenseKey:string=environment.csvBox.secret_progetti;
  csvbox_civici_licenseKey:string=environment.csvBox.secret_civici;

  constructor(
    public auth: AuthService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public projectsReloadService: ProjectsReloadService,
    private logger: NGXLogger,
    @Inject(DOCUMENT) private doc: Document,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private applicationVersionService: ApplicationVersionsService,
    private csvboxLoaderService: CsvboxLoaderService,
  ) {}

  getApplicationVersion() {
    this.applicationVersionService.getCurrentVersion().subscribe(
      (result) => {
        this.currentVersion = result.version;
      },
      (error) => {
        console.error('ERRORE get version' + error);
      }
    );
  }
  ngOnInit() {
    if (
      localStorage.getItem('currentAppVersion') != 'undefined' ||
      localStorage.getItem('currentAppVersion') != null
    ) {
      this.displayAppVersion = true;
      // this.currentVersion = localStorage.getItem("currentAppVersion");
    }

    this.csvboxLoaderService.loadScript().then(() => {
      this.csvbox_progetti_importer = new CSVBoxImporter(this.csvbox_progetti_licenseKey, {
        // Configuration options
      }, (result: boolean, data: any) => {
        if (result) {
          this.logger.info("Sheet uploaded successfully");
          this.logger.info(data.row_success + " rows uploaded");
          this.messageService.add({severity:'success', summary:'', detail: data.row_success + ' progetti importati con successo!'});
         // this.loadClienti();
        } else {
          this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di import progetti'});
          // Custom code
        }
      });
      this.csvbox_progetti_importer.setUser({
        user_id: localStorage.getItem('userId',),
        tenant_id:localStorage.getItem("tenantId"),
        tenant_name:localStorage.getItem("tenantName")
      });
   
      this.csvbox_progetti_importer.language="it";

      this.logger.info("--->> SCRIPT PROGETTI CARICATO!");
    });

    this.csvboxLoaderService.loadScript().then(() => {

    this.csvbox_civici_importer = new CSVBoxImporter(this.csvbox_civici_licenseKey, {
      // Configuration options
    }, (result: boolean, data: any) => {
      if (result) {
        this.logger.info("Sheet uploaded successfully");
        this.logger.info(data.row_success + " rows uploaded");
        this.messageService.add({severity:'success', summary:'', detail: data.row_success + ' CIVICI importati con successo!'});
       // this.loadClienti();
      } else {
        this.messageService.add({severity:'info', summary:'Errore', detail:'Errore in fase di import CIVICI'});
        // Custom code
      }
    });
    this.csvbox_civici_importer.setUser({
      user_id: localStorage.getItem('userId',),
      tenant_id:localStorage.getItem("tenantId"),
      tenant_name:localStorage.getItem("tenantName")
    });
 
    this.csvbox_civici_importer.language="it";
    this.logger.info("--->> SCRIPT CIVICI CARICATO!");
    });

    this.getApplicationVersion();
  }
  openProgettiMenu(){
    if(this.hideProgettiMenu){
      
      this.hideProgettiMenu = false;     
      this.openProgettiMenu();
    }
    
  }
  closeProgettiMenu() {
    this.hideProgettiMenu = true;
  }
  openProgettiEsecutiviMenu(){
    this.hideProgettiEsecutiviMenu = false; 
  }
  closeProgettiEsecutiviMenu() {
    this.hideProgettiEsecutiviMenu = true;
  }
  openAnagraficheMenu(){
    this.hideAnagraficheMenu = false;     
  }
  closeAnagraficheMenu() {
    this.hideAnagraficheMenu = true;
  }

  showCreditSituation() {
    this.ref = this.dialogService.open(DialogCrediti, {
      data: {},
      //header: 'Crediti',
      width: '40%',
      height: '77%',
      contentStyle: { 'max-height': '1000px' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirmAvanzamentoVersione) => {});
  }
  openCaricaCreditiDialog(){
    this.ref = this.dialogService.open(DialogCaricaCreditiTenant, {
      data: {},
      header: 'Caricamento Crediti',
      width: '40%',
      height: '45%',
      contentStyle: { 'max-height': '500px' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
      this.ref.onClose.subscribe((confirmCaricamento) => {
      if (confirmCaricamento.success) {
         this.messageService.add({
          key: 'tc',
          life: this.messageDuration,
          severity: 'success',
          summary: 'Caricamento crediti',
          detail:
            'Sono stati correttamente aggiunti ' + confirmCaricamento.creditiAggiunti + " crediti al tenant "+confirmCaricamento.tenant
        });
       
      }
      
    });  
  }
  openChangelog(){
    this.ref = this.dialogService.open(DialogChangeLogHome, {
      data: {
    },
     // header: 'FIBERGIS Changelog',
      width: '60%',
      height: '52%',
      contentStyle: { "overflow": "true"},
      baseZIndex: 10000,
      closable:true,
      draggable:false,
      closeOnEscape:true,
  });
  }
  openAvanzaVersioneDialog() {
    this.ref = this.dialogService.open(DialogAvanzaVersioneApplicazione, {
      data: {},
      header: 'Avanzamento versione applicazione',
      width: '40%',
      height: '40%',
      contentStyle: { 'max-height': '500px' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((confirmAvanzamentoVersione) => {
      console.log(confirmAvanzamentoVersione);
      if (confirmAvanzamentoVersione && confirmAvanzamentoVersione.success) {
        let isMinor: boolean = true;
        if (confirmAvanzamentoVersione.releaseType.code != 'minor') {
          isMinor = false;
        }
        this.applicationVersionService
          .advanceVersion(isMinor)
          .subscribe((res) => {
            console.log('OK AVANZAMENTO DI VERSIONE!');
          });
      }
    });
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect().subscribe((res) => {});
  }
  goToHome() {
    this.router.navigate(['/']);
    //this.closeMenu();
  }
  openProjects(destinazione: string) {
    this.closeProgettiMenu();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/progetti/' + destinazione]));
  }

  openExecutiveProjects(destinazione: string) {
    this.closeProgettiEsecutiviMenu();
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() =>
        this.router.navigate(['/progetti-esecutivi/' + destinazione])
      );
      
  }

  openProfileModule() {
    this.ref = this.dialogService.open(DialogProfilo, {
      data: {
        //     utente: utente,
        //     token: this.auth0Token
      },
      header: 'Profilo utente',
      width: '50%',
      height: '50%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
  }

  openDocDialog() {
    console.log('--> openDocDialog');
    this.ref = this.dialogService.open(DialogDoc, {
      data: {},
      header: '',
      width: '70%',
      height: '35%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
      closeOnEscape: true,
    });
  }
  openFAQDialog() {
    console.log('--> openFAQDialog');
    this.ref = this.dialogService.open(DialogFaq, {
      data: {},
      header: 'FAQ',
      width: '95%',
      height: '90%',
      contentStyle: { overflow: 'true' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
      closeOnEscape: true,
    });
  }

  openHelpDialog() {
    console.log('--> openHelpDialog');
    this.ref = this.dialogService.open(DialogHelp, {
      data: {},
      header: 'Supporto',
      width: '95%',
      height: '90%',
      contentStyle: { overflow: 'true' },
      baseZIndex: 9999,
      closable: true,
      draggable: false,
      closeOnEscape: true,
    });
  }

  logout() {
    localStorage.setItem('userId', null);
    localStorage.setItem('utente', null);
    localStorage.setItem('email', null);
    localStorage.setItem('ruolo', null);
    localStorage.setItem('auth0Token', null);
    //localStorage.setItem('BEtoken', null);
    localStorage.setItem('tenantId', null);
    localStorage.setItem('tenantName', null);
    localStorage.setItem('user.sub', null);
    localStorage.setItem('token', null);
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.setItem('subscribedToAggiornamentiTopic', 'false');
    localStorage.setItem('fcm_token', null);
    localStorage.setItem('creditiModaleMostrata', null);
    // localStorage.removeItem('tenantId');

    this.auth.logout({ returnTo: this.doc.location.origin });
  }

  isAdministrator() {
    return (
      localStorage.getItem('ruolo') === 'AMMINISTRATORE' ||
      localStorage.getItem('ruolo') === 'SUPERADMIN'
    );
  }

  isSuperAdmin() {
    return localStorage.getItem('ruolo') == 'SUPERADMIN';
  }

  async openScaricaTemplateCivici() {
    console.log('> openScaricaTemplateCivici');
    this.closeAnagraficheMenu();
    //this.loadingInProgress = true;
    const storage = getStorage();
    const pathReference = ref(storage, '/templates/template_walkin.xlsx');
    const blob = await getBlob(pathReference);

    saveAs(blob, 'template_walkin.xlsx');
    //this.loadingInProgress = false;
  }
  async openScaricaTemplateBox() {
    this.closeAnagraficheMenu();
    console.log('> openScaricaTemplateBox');

    //this.loadingInProgress = true;
    const storage = getStorage();
    const pathReference = ref(storage, '/templates/template_box.xlsx');
    const blob = await getBlob(pathReference);

    saveAs(blob, 'template_box.xlsx');
    //this.loadingInProgress = false;
  }
  async openScaricaTemplateProgetti() {
    this.closeAnagraficheMenu();
    console.log('> openScaricaTemplateProgetti');
    const storage = getStorage();
    const pathReference = ref(storage, '/templates/template_progetti.xlsx');
    const blob = await getBlob(pathReference);

    saveAs(blob, 'template_progetti.xlsx');
  }

  openCaricaProgetti() {
    this.closeAnagraficheMenu();
    this.csvbox_progetti_importer.openModal();

    /* this.ref = this.dialogService.open(DialogCaricaProgetti, {
      data: {
        //     utente: utente,
        //     token: this.auth0Token
      },
      header: 'Carica Progetti',
      width: '70%',
      height: '60%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        console.log(uploadResult.risultato);
        this.risultatoCaricamentoProgetti = uploadResult.risultato;
        //this.loadData();
        if (uploadResult.risultato.listaErrori.length == 0) {
          //this.messageService.add({key: 'tc',severity:'success', summary: '', detail: 'Dei '+ this.risultatoCaricamentoProgetti.numeroProgettiPresentiNelFile + ' progetti presenti nel file, ne sono stati correttamente caricati '+ this.risultatoCaricamentoProgetti.numeroProgettiCaricati});
          this.messageService.add({
            key: 'tc',
            life: this.messageDuration,
            severity: 'success',
            summary: '',
            detail:
              'Tutti i progetti presenti nel file (' +
              this.risultatoCaricamentoProgetti.numeroProgettiPresentiNelFile +
              ') sono stati caricati',
          });
        } else {
          let listaErrori: string[] = uploadResult.risultato.listaErrori;
          let listaErroriToString: string = '';
          listaErrori.forEach((errore) => {
            listaErroriToString += errore + '\n';
          });
          this.messageService.add({
            key: 'tc',
            life: this.messageDuration,
            severity: 'warn',
            summary: 'ATTENZIONE!',
            detail:
              'Dei ' +
              this.risultatoCaricamentoProgetti.numeroProgettiPresentiNelFile +
              ' progetti presenti nel file, ne sono stati correttamente caricati ' +
              this.risultatoCaricamentoProgetti.numeroProgettiCaricati +
              '.\nQuesti gli errori:\n' +
              listaErroriToString,
            styleClass: 'custom-toast-anagrafiche-box',
          });
        }
        this.projectsReloadService.reloadIsNecessary(true);
      } else {
        this.messageService.add({
          key: 'tc',
          life: this.messageDuration,
          severity: 'warn',
          summary: 'ATTENZIONE!',
          detail:
            'Impossibile processare il file, verificare che il contenuto rispetti il template!',
          styleClass: 'custom-toast-anagrafiche-box',
        });
      }
    }); */
  }
  openCaricaCivici() {
    this.closeAnagraficheMenu();
    //this.csvbox_civici_importer.openModal();
     this.ref = this.dialogService.open(DialogCaricaCivici, {
      data: {
        //     utente: utente,
        //     token: this.auth0Token
      },
      header: 'Carica Civici',
      width: '70%',
      height: '60%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });

    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        console.log(uploadResult.risultato);
        this.risultatoCaricamentoCivici = uploadResult.risultato;
        //this.loadData();
        console.log("> errori? " + uploadResult.risultato.listaErrori.length);
        console.log("> caricati? " + uploadResult.risultato.numeroCiviciCaricati);
        console.log("> nel file? " + uploadResult.risultato.numeroCiviciPresentiNelFile);

        if (uploadResult.risultato.listaErrori.length == 0) {
          if(uploadResult.risultato.numeroCiviciCaricati == 0){
            this.messageService.add({
              key: 'tc',
              life: this.messageDuration,
              severity: 'warn',
              summary: 'ATTENZIONE!',
              detail:
                'Nessun civico dei ' + this.risultatoCaricamentoCivici.numeroCiviciPresentiNelFile + ' presenti nel file è stato caricato, verificarne il contenuto!',
              styleClass: 'custom-toast-anagrafiche-box',
            });
          }else {
          if(uploadResult.risultato.numeroCiviciCaricati != uploadResult.risultato.numeroCiviciPresentiNelFile){
            this.messageService.add({
              key: 'tc',
              life: this.messageDuration,
              severity: 'warn',
              summary: 'ATTENZIONE!',
              detail:
              'Dei ' +
              this.risultatoCaricamentoCivici.numeroCiviciPresentiNelFile +
              ' civici presenti nel file, ne sono stati correttamente caricati ' +
              this.risultatoCaricamentoCivici.numeroCiviciCaricati + ".",
            });
          }else {
            this.messageService.add({
              key: 'tc',
              life: this.messageDuration,
              severity: 'success',
              summary: '',
              detail:
                'Tutti i civici presenti nel file (' +
                this.risultatoCaricamentoCivici.numeroCiviciPresentiNelFile +
                ') sono stati caricati',
            });
          }
        }
        } else {
          let listaErrori: string[] = uploadResult.risultato.listaErrori;
          let listaErroriToString: string = '';
          listaErrori.forEach((errore) => {
            listaErroriToString += errore + '\n';
          });
          this.messageService.add({
            key: 'tc',
            life: this.messageDuration,
            severity: 'warn',
            summary: 'ATTENZIONE!',
            detail:
              'Dei ' +
              this.risultatoCaricamentoCivici.numeroCiviciPresentiNelFile +
              ' civici presenti nel file, ne sono stati correttamente caricati ' +
              this.risultatoCaricamentoCivici.numeroCiviciCaricati +
              '.\nQuesti gli errori:\n' +
              listaErroriToString,
            styleClass: 'custom-toast-anagrafiche-box',
          });
        }
      } else {
        this.messageService.add({
          key: 'tc',
          life: this.messageDuration,
          severity: 'warn',
          summary: 'ATTENZIONE!',
          detail:
            'Impossibile processare il file, verificare che il contenuto rispetti il template!',
          styleClass: 'custom-toast-anagrafiche-box',
        });
      }
    }); 
  }

  openCaricaBoxTim() {
    this.closeAnagraficheMenu();
    this.ref = this.dialogService.open(DialogCaricaBoxTim, {
      data: {
        //     utente: utente,
        //     token: this.auth0Token
      },
      header: 'Carica Box Tim',
      width: '70%',
      height: '52%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });

    this.ref.onClose.subscribe((uploadResult) => {
      if (uploadResult.success) {
        this.risultatoCaricamentoProgetti = uploadResult.risultato;
        if (uploadResult.risultato.listaErrori.length == 0) {
          this.messageService.add({
            key: 'tc',
            life: this.messageDuration,
            severity: 'success',
            summary: '',
            detail:
              'Tutti i box presenti nel file (' +
              this.risultatoCaricamentoProgetti.numeroProgettiPresentiNelFile +
              ') sono stati caricati',
          });
        } else {
          let listaErrori: string[] = uploadResult.risultato.listaErrori;
          let listaErroriToString: string = '';
          listaErrori.forEach((errore) => {
            listaErroriToString += errore + '\n';
          });
          this.messageService.add({
            key: 'tc',
            life: this.messageDuration,
            severity: 'warn',
            summary: 'ATTENZIONE!',
            detail:
              'Dei ' +
              this.risultatoCaricamentoProgetti.numeroProgettiPresentiNelFile +
              ' box presenti nel file, ne sono stati correttamente caricati ' +
              this.risultatoCaricamentoProgetti.numeroProgettiCaricati +
              '.\nQuesti gli errori:\n' +
              listaErroriToString,
            styleClass: 'custom-toast-anagrafiche-box',
          });
        }
      } else {
        this.messageService.add({
          key: 'tc',
          life: this.messageDuration,
          severity: 'warn',
          summary: 'ATTENZIONE!',
          detail:
            'Impossibile processare il file, verificare che il contenuto rispetti il template!',
          styleClass: 'custom-toast-anagrafiche-box',
        });
      }
    });
  }

  conferma(conferma: boolean) {}
}

@Component({
  templateUrl: './dialog-carica-progetti.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class DialogCaricaProgetti implements OnInit {
  protected _onDestroy = new Subject<void>();
  selectedFile: File = null;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig,
    private anagraficheService: AnagraficheService
  ) {}

  ngOnInit(): void {}

  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }
  openVideoUrl() {
    window.open(environment.progettiTutorialURL, '_blank');
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.isLoadingResults = true;
      this.anagraficheService.caricaProgetti(this.selectedFile).subscribe(
        (result) => {
          this.isLoadingResults = false;
          this.dialogRef.close({ success: true, risultato: result });
        },
        (error) => {
          this.isLoadingResults = false;
          this.dialogRef.close({ success: false, risultato: null });
        }
      );
    }
  }
}

@Component({
  templateUrl: './dialog-carica-box-tim.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class DialogCaricaBoxTim implements OnInit {
  protected _onDestroy = new Subject<void>();
  selectedFile: File;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig,
    private anagraficheService: AnagraficheService
  ) {}

  ngOnInit(): void {}
  openVideoUrl() {
    window.open(environment.boxTutorialURL, '_blank');
  }
  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.isLoadingResults = true;
      this.anagraficheService.caricaAnagraficaBox(this.selectedFile).subscribe(
        (result) => {
          this.isLoadingResults = false;
          this.dialogRef.close({ success: true, risultato: result });
        },
        (error) => {
          this.isLoadingResults = false;
          this.dialogRef.close({ success: false, risultato: null });
        }
      );
    }
  }
}

@Component({
  templateUrl: './dialog-carica-civici.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class DialogCaricaCivici implements OnInit {
  protected _onDestroy = new Subject<void>();
  selectedFile: File;
  isFileUploaded: boolean = false;
  isLoadingResults: boolean = false;
  protected numeroRighe: number = 0;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private anagraficheService: AnagraficheService
  ) {}
  openVideoUrl() {
    window.open(environment.civiciTutorialURL, '_blank');
  }
  ngOnInit(): void {}

  onFileUpload($event) {
    this.isFileUploaded = true;
    this.selectedFile = $event.files[0];
    this.selectedFile.text().then((res) => {
      this.numeroRighe = res.split('\n').length - 1;
      if (this.numeroRighe > 5000) {
        this.messageService.add({
          key: 'tc',
          life: 86400000,
          severity: 'warn',
          summary: 'ATTENZIONE!',
          detail:
            'Il file contiene ' +
            this.numeroRighe +
            ' righe! Il caricamento si limiterà alle prime 5000 righe.',
          styleClass: 'custom-toast-civici',
        });
      }
    });
  }
  onFileRemove() {
    this.isFileUploaded = false;
  }
  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.isLoadingResults = true;
      this.anagraficheService.caricaCivici(this.selectedFile).subscribe(
        (result) => {
          this.isLoadingResults = false;
          this.dialogRef.close({ success: true, risultato: result });
        },
        (error) => {
          this.isLoadingResults = false;
          this.dialogRef.close({ success: false, risultato: null });
        }
      );
    }
  }
}

@Component({
  templateUrl: './dialog-profile.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class DialogProfilo implements OnInit {
  protected _onDestroy = new Subject<void>();
  selectedFile: File;
  isFileUploaded: boolean = false;
  ruolo: string;
  azienda: string;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.ruolo = localStorage.getItem('ruolo');
    this.azienda = localStorage.getItem('tenantName');
  }

  conferma(conferma: boolean) {}
}

@Component({
  templateUrl: './dialog-faq.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class DialogFaq implements OnInit {
  protected _onDestroy = new Subject<void>();
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {}

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.dialogRef.close({ success: true });
    }
  }
  mailtoHeader = 'mailto:timreport@maphosting.it';
  subjectProp = 'subject=Richiesta informazioni';
  bodyProp = 'body=';
  amp = '&amp;';
  breakStr = '%0D%0A';
  footer = '';
  subject: string = 'Richiesta informazioni';

  sendContactMail() {
    let str = '';
  }
}

@Component({
  templateUrl: './dialog-help.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class DialogHelp implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected richiestaSupporto: string[] = [];
  protected nome: string = '';
  protected cognome: string = '';
  protected email: string = '';
  protected telefono: string;
  protected messaggio: string = '';
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private utilService: UtilService
  ) {}

  ngOnInit(): void {}

  inviaMailSupporto() {
    this.logger.info(
      '---> richiesta supporto ' +
        this.nome +
        ' ' +
        this.cognome +
        ' ' +
        this.email +
        ' ' +
        this.messaggio +
        ' ' +
        this.telefono
    );
    this.utilService
      .inviaMailSupporto(
        this.email,
        this.messaggio,
        this.nome,
        this.cognome,
        this.telefono
      )
      .subscribe((res) => {
        this.dialogRef.close({ success: true });
        if (res.success) {
          this.messageService.add({
            key: 'tc',
            life: 86400000,
            severity: 'success',
            summary: '',
            detail: 'Mail inviata con successo!',
          });
        } else {
          this.messageService.add({
            key: 'tc',
            life: 86400000,
            severity: 'info',
            summary: 'Errore',
            detail: 'Errore invio mail al supporto!',
          });
        }
      });
  }
}

@Component({
  templateUrl: './dialog-doc.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class DialogDoc implements OnInit {
  protected _onDestroy = new Subject<void>();

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {}

  downloadDoc(): void {
    window.open(environment.ftthTutorialPlaylist, '_blank');
    this.dialogRef.close({ success: true });
    //this.scaricaDoc();
  }

  async scaricaDoc() {
    console.log('> scaricaDoc');
    const storage = getStorage();
    const pathReference = ref(
      storage,
      '/docs/Guide-utili-per-Progettazione-FTTH-_-Guida-Upload-Progetti.pdf'
    );
    const blob = await getBlob(pathReference);

    saveAs(blob, 'FTTH-Guida.pdf');
    this.dialogRef.close({ success: true });
  }
}

@Component({
  templateUrl: './dialog-avanza-versione.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class DialogAvanzaVersioneApplicazione implements OnInit {
  protected _onDestroy = new Subject<void>();
  isMinor: boolean = false;
  protected releaseTypes: Release[];
  protected selectedReleaseType: Release;
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig
  ) {
    this.releaseTypes = [
      { name: 'MINOR', code: 'minor' },
      { name: 'PATCH', code: 'patch' },
    ];
  }

  ngOnInit(): void {
    this.isMinor = this.config.data.tenants;
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.dialogRef.close({
        success: true,
        releaseType: this.selectedReleaseType,
      });
    }
  }
}

@Component({
  templateUrl: './dialog-crediti.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class DialogCrediti implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected numeroCreditiAcquistati: number = 0;
  protected numeroTotaleProgettiGenerati: number = 0;
  protected numeroPreliminari: number = 0;
  protected numeroEsecutivi: number = 0;
  protected computoTotale: number = 0;
  protected percentuale: number = 0;
  protected percentualeString: string = '0%';

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig,
    private tenantService: TenantsService
  ) {}

  ngOnInit(): void {
    this.tenantService
      .getTenant(localStorage.getItem('tenantId'))
      .subscribe((tenant) => {
        this.logger.info(tenant);
        this.numeroCreditiAcquistati = tenant.crediti;
        this.numeroPreliminari = tenant.numeroProgettiPreliminariGenerati;
        this.numeroEsecutivi = tenant.numeroProgettiEsecutiviGenerati;
        this.numeroTotaleProgettiGenerati =
          tenant.numeroProgettiPreliminariGenerati +
          tenant.numeroProgettiEsecutiviGenerati;
        this.computoTotale = tenant.computoTotale;
        const percentage =
          (this.computoTotale / this.numeroCreditiAcquistati) * 100;
        this.percentuale = Math.floor(percentage);
        if (this.percentuale <= 100) {
          this.percentualeString = this.percentuale + '%';
        } else {
          this.percentualeString = '100%';
          this.percentuale = 100;
        }

        
      });
  }

  conferma(conferma: boolean) {
    /*   if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.dialogRef.close({ success: true});
    } */
  }

  openAcquistaCredito() {
    window.open('https://vertical-srl.it/negozio/Crediti-Aree-Armadio-p528419562', '_blank');
  }
}

@Component({
  templateUrl: './dialog-carica-crediti-tenant.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class DialogCaricaCreditiTenant implements OnInit {
  protected _onDestroy = new Subject<void>();
  public tenants: Tenant[];
  public tenantSelezionato: Tenant;
  public numeroCrediti:string="0";
  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public auth: AuthService,
    public config: DynamicDialogConfig,
    private anagraficheService: AnagraficheService,
    private tenantService:TenantsService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.anagraficheService.tenants().subscribe((tenants) => {
      this.tenants = tenants;
    });
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.tenantService.aggiornaCrediti(this.tenantSelezionato.id, this.numeroCrediti).subscribe(result =>{
       
        this.dialogRef.close({ success: true, creditiAggiunti:this.numeroCrediti, tenant:this.tenantSelezionato.name });
      });
    }
    /* const utente = this.config.data.utente.user_id.substring(
      this.config.data.utente.user_id.indexOf('|') + 1,
      this.config.data.utente.user_id.length
    );
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      this.logger.info('>> sto per assegnare utente al tenant ');
      this.utentiService
        .cambiaUserMetadata(
          this.config.data.utente.user_id,
          this.tenantSelezionato.id,
          this.tenantSelezionato.name,
          this.config.data.token
        )
        .subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: 'info',
              summary: 'Errore',
              detail: "Errore in fase di assegnazione tenant all' utente",
            });
            this.dialogRef.close({ success: false });
          },
          complete: () => {
            this.messageService.add({
              severity: 'success',
              summary: '',
              detail:
                'Utente correttamente assegnato al tenant ' +
                this.tenantSelezionato.name,
            });
            this.dialogRef.close({ success: true });
          },
        });
    } */
  }
}

@Component({
  templateUrl: './dialog-changelog.component.html',
  styleUrls: ['./navbar.component.css']
})
export class DialogChangeLogHome implements OnInit {
  protected _onDestroy = new Subject<void>();
  constructor(
      public dialogRef: DynamicDialogRef,
       public config: DynamicDialogConfig,

      ) {
  }

  ngOnInit(): void {
  }

  conferma(conferma:boolean) {
      if(!conferma){
          this.dialogRef.close({success: false});
      }else {
       
          this.dialogRef.close({success: true});
 
      }
  }
}

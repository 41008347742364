import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectsReloadService {
  private reloadNecessary = new BehaviorSubject(false);
  reloadCurrentlyNecessary = this.reloadNecessary.asObservable();

  constructor() { }

  reloadIsNecessary(reloadIsNecessary:boolean){
    this.reloadNecessary.next(reloadIsNecessary)

  }
}

<div class="surface-card p-4 shadow-2 border-round">
  <div class="mb-3 flex flex-column md:flex-row md:align-items-center md:justify-content-between">
    <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(175, 209, 175); color: white;" (click)="loadData()"></button>
    <span class="text-xl font-medium text-2000" style="color: blue">Utenti</span>
    <div class="flex">
    </div>
  </div>
  <div fxFlex="95%">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"> </p-confirmDialog>
   
    <p-table styleClass="p-datatable-sm" [value]="utenti" dataKey="nickname" [(selection)]="selectedUtente"
      [selectionPageOnly]="true" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10,25,50]" [loading]="isLoadingResults" responsiveLayout="scroll" [paginator]="true"
      currentPageReportTemplate="{first} - {last} di {totalRecords}" [filterDelay]="0"
      [globalFilterFields]="['nickname', 'email', 'last_login', 'ruolo']">

      <ng-template pTemplate="header">
        <tr>

          <th pSortableColumn="nickname">
            <div class="flex justify-content-between align-items-center">
              Utente
              <p-sortIcon field="nickname"></p-sortIcon>
              <p-columnFilter type="text" field="nickname" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="email">
            <div class="flex justify-content-between align-items-center">
              Email
              <p-sortIcon field="email"></p-sortIcon>
              <p-columnFilter type="text" field="email" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="last_login">
            <div class="flex justify-content-between align-items-center">
              Ultimo login
              <p-sortIcon field="last_login"></p-sortIcon>
              <p-columnFilter type="date" field="last_login" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th pSortableColumn="ruolo">
            <div class="flex justify-content-between align-items-center">
              Ruolo
              <p-sortIcon field="ruolo"></p-sortIcon>
              <p-columnFilter type="text" field="ruolo" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>
          <th *ngIf="isSuperAdmin"  pSortableColumn="tenant">
            <div class="flex justify-content-between align-items-center">
              Azienda
              <p-sortIcon field="tenant"></p-sortIcon>
              <p-columnFilter type="text" field="tenant" display="menu" class="ml-auto"></p-columnFilter>
            </div>
          </th>

          <th>Azioni</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>

          <td>
            <span class="p-column-title">Utente</span>
            <img [alt]="row.nickname" src="{{row.picture}}" width="32" class="user-table-avatar" />
            <span class="image-text">{{row.nickname}}</span>
          </td>
          <td> <span class="text-lowercase">{{row.email}}</span> </td>
          <td> <span>{{row.last_login | date:'dd/MM/yyyy HH:mm'}}</span> </td>
          <td> <span [class]="'user-badge ruolo-' + row.ruolo">{{row.ruolo}}</span> </td>
          <td *ngIf="isSuperAdmin "> <span class="text-lowercase">{{row.user_metadata?row.user_metadata.tenant_name:''}}</span> </td>
          <td>
            <button *ngIf="row.ruolo!='SUPERADMIN'" pButton type="button" icon="pi pi-server" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Assegna Progetti"
            (click)="openAssegnaProgettiDialog(row)">
          </button>
            <button *ngIf="row.ruolo!='SUPERADMIN' && isAdmin" pButton type="button" icon="pi pi-user-edit" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Cambia Ruolo"
              (click)="openChangeRoleDialog(row)">
            </button>
            <button *ngIf="isSuperAdmin" pButton pRipple type="button" icon="pi pi-link"
            class="p-button-rounded p-button-text" (click)="openLinkToTenantDialog(row)" style="color:rgb(23, 93, 173);" tooltipPosition="top" pTooltip="Associa a Tenant"
            (click)="openAssegnaTenant(row)">
          </button>
            <button *ngIf="row.ruolo!='AMMINISTRATORE' && row.ruolo!='SUPERADMIN'" pButton pRipple type="button" icon="pi pi-trash"
              class="p-button-rounded p-button-text" (click)="openDeleteUserDialog(row)" style="color:red;" tooltipPosition="top" pTooltip="Elimina Utente">
            </button>
          </td>
        </tr>
      </ng-template>

    </p-table>

  </div>
</div>
<p-toast position="top-center"></p-toast>
